import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Icon/Icon';
import { ContentBox } from 'Components/Veileder/Components/ContentBox';
import {
  goBack,
  Option,
  setCurrent,
  setLastAnswer,
  Step,
  useVeilederContext
} from 'Components/Veileder/context';
import { useCmsById } from 'store/CmsQuery';
import css from './Question.module.scss';

type ActionProps = {
  isIntroStep: boolean;
  onNextClicked: () => void;
  onPrevious: () => void;
};

const Actions = ({ isIntroStep, onNextClicked, onPrevious }: ActionProps) => {
  const { t } = useTranslation('veileder');

  return (
    <nav className={css.Actions}>
      {isIntroStep ? (
        <>
          <button className={css.next} onClick={onNextClicked}>
            <span>{t('nav_start')}</span>
            <Icon symbol={'chevron-down'} tabIndex={-1} />
          </button>
        </>
      ) : (
        <>
          <button className={css.back} onClick={onPrevious}>
            <Icon symbol={'chevron-up'} tabIndex={-1} />
          </button>
          <button className={css.next} onClick={onNextClicked}>
            <span>{t('nav_next')}</span>
            <Icon symbol={'chevron-down'} tabIndex={-1} />
          </button>
        </>
      )}
    </nav>
  );
};

type IntroProps = {
  question: string;
  currentStep: Step;
  isIntroStep: boolean;
  onOptionSelected: (option: Option) => void;
  children: JSX.Element;
};

const Introduction = ({
  question,
  currentStep,
  isIntroStep,
  onOptionSelected,
  children
}: IntroProps) => {
  const { t } = useTranslation('veileder');
  const questionText = () =>
    !isIntroStep ? <h1>{question}</h1> : <p>{t('intro_text')}</p>;
  const [currentAnswer, setCurrentAnswer] = useState<Option | null>(null);

  useEffect(() => {
    setCurrentAnswer(null);
  }, [currentStep]);

  return (
    <section className={css.Introduction}>
      <div className={css.subject}>{t(`subject_step_${currentStep.id}`)}</div>
      <div className={css.main}>
        {questionText()}
        {!isIntroStep && (
          <a href={'#veiledning'}>
            <span>{t('intro_guidance_link')}</span>
            <Icon symbol={'arrow-down'} tabIndex={-1} />
          </a>
        )}
      </div>
      {!isIntroStep && (
        <div className={css.options}>
          {currentStep.options.map((o) => {
            const optionId = `option_${o.type.value.toLowerCase()}`;
            return (
              <div key={optionId}>
                <input
                  key={`${optionId}-${
                    o.type.value === currentAnswer?.type.value
                  }`}
                  id={optionId}
                  type={'radio'}
                  name={'answer'}
                  value={o.type.value}
                  checked={o.type.value === currentAnswer?.type.value}
                  onChange={() => {
                    setCurrentAnswer(o);
                    onOptionSelected(o);
                  }}
                />
                <label htmlFor={optionId}>{t(optionId)}</label>
              </div>
            );
          })}
        </div>
      )}
      {children}
    </section>
  );
};

export const Question = () => {
  const { t } = useTranslation('veileder');
  const { veilederState, dispatch } = useVeilederContext();
  const { cmsNode } = useCmsById('veiledning', veilederState.current?.cmsRef);
  const isIntroStep = (): boolean =>
    !!veilederState.current &&
    veilederState.current.id === veilederState.intro.id;

  return (
    <article className={css.Question}>
      {veilederState.current && cmsNode && (
        <>
          <Introduction
            question={cmsNode.title}
            currentStep={veilederState.current}
            onOptionSelected={(option: Option) =>
              setLastAnswer(dispatch, option)
            }
            isIntroStep={isIntroStep()}
          >
            <Actions
              onNextClicked={() => {
                setCurrent(
                  dispatch,
                  veilederState.lastAnswer
                    ? veilederState.lastAnswer.nextStepRef
                    : null
                );
              }}
              onPrevious={() => goBack(dispatch)}
              isIntroStep={isIntroStep()}
            />
          </Introduction>
          <ContentBox
            headerText={isIntroStep() ? t('title_about') : t('title_guidance')}
            cmsRef={veilederState.current.cmsRef}
          />
        </>
      )}
    </article>
  );
};
