import React from 'react';
import { useParams } from 'react-router-dom';

import { useTjenesteByVirksomhet } from 'shared/hooks/tjeneste-hooks';
import { NotFound } from 'Components/NotFound';
import Spinner from 'Components/Spinner/Spinner';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import { TjenesteViewer } from 'Components/TjenesteViewer';

export default function PreviewTjenesteSide() {
  const { id } = useParams<{ id: string }>();
  const { activeVirksomhet } = useActiveVirksomhet();
  const { data: tjeneste, isLoading, isError } = useTjenesteByVirksomhet(
    activeVirksomhet?.id || '',
    id
  );

  if (isError) {
    return <NotFound />;
  }

  if (isLoading || !tjeneste) {
    return <Spinner isActive={isLoading} />;
  }

  return <TjenesteViewer tjeneste={tjeneste} />;
}
