import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './Firmaprofil.module.scss';
import { useCreateVirksomhet } from 'shared/hooks/virksomhet-hooks';
import formatOrgNr from 'shared/format-orgnr';
import { useFeatureToggle } from '../shared/feature-toggle';
import { Features } from '../shared-constants';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import { VirksomhetSelector } from './VirksomhetSelector';
import { Loading } from 'Components/Loading';
import TjenesteRegistration from './TjenesteRegistration';
import { Virksomhet } from 'models';
import { Information } from './Information';
import { useLogin } from 'shared/authentication';

const FirmaHeader = ({
  activeVirksomhet,
  altTitle
}: {
  activeVirksomhet?: Virksomhet;
  altTitle: string;
}) => {
  const { t } = useTranslation('translation');
  return (
    <header>
      {activeVirksomhet ? (
        <>
          <h1>{activeVirksomhet.navn}</h1>
          <div>{t('org_no') + formatOrgNr(activeVirksomhet.orgnr)}</div>
        </>
      ) : (
        <h1>{altTitle}</h1>
      )}
    </header>
  );
};

export default function Firmaprofil(): ReactElement {
  const { isAdministrator } = useLogin();
  const { activeVirksomhet } = useActiveVirksomhet();
  const { isLoading } = useCreateVirksomhet();
  const { isEnabled } = useFeatureToggle();
  const { t } = useTranslation('translation');

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  return (
    <div className={css.Firmaprofil}>
      <div className={css.Wrapper}>
        {isAdministrator() && <VirksomhetSelector />}
        <FirmaHeader
          activeVirksomhet={activeVirksomhet}
          altTitle={t('firmaprofil')}
        />
        {isEnabled(Features.Tjenesteregister) && (
          <TjenesteRegistration activeVirksomhet={activeVirksomhet} />
        )}
        {isEnabled(Features.LoggInn) &&
          !isEnabled(Features.Tjenesteregister) && <Information />}
      </div>
    </div>
  );
}
