import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormGroup from 'Components/UI/FormGroup/FormGroup';
import css from '../TjenesteRegistrationForm.module.scss';
import List from 'Components/UI/List/List';
import { TjenesteRequest } from 'models';

type ToggleProps = {
  value?: boolean;
  handleChange: (update: Partial<TjenesteRequest>) => void;
};

export const ResaleToggle = ({ value, handleChange }: ToggleProps) => {
  const { t } = useTranslation('translation');

  return (
    <FormGroup isValid={typeof value === 'boolean'}>
      <div className={classNames('radiolist', css.question)}>
        <label>{t('rt_selges_via_partnere')}</label>
        <List orientation={'horizontal'} alignment={'start'} gap={'lg'}>
          <label>
            <input
              type={'radio'}
              name={'salg_via_partnere'}
              value={'1'}
              checked={value}
              onChange={(event) =>
                handleChange({
                  resaleEnabled: event.target.value === '1'
                })
              }
            />
            <span className={'marker'} />
            <span>{t('ja')}</span>
          </label>
          <label>
            <input
              type={'radio'}
              name={'salg_via_partnere'}
              value={'0'}
              checked={!value}
              onChange={(event) =>
                handleChange({
                  resaleEnabled: event.target.value === '1'
                })
              }
            />
            <span className={'marker'} />
            <span>{t('nei')}</span>
          </label>
        </List>
      </div>
    </FormGroup>
  );
};
