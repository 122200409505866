import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import css from 'CmsNode/CmsNode.module.scss';
import { Skyreisen } from 'CmsNode/Skyreisen';
import {
  Body,
  LeadParagraph as LP,
  TableOfContents,
  Title as T,
  Time
} from 'CmsNode/parts';
import { Article, Process } from 'shared/api/drupal';

type ContentProps<T extends Article> = {
  cmsNode: T;
  showTitle: boolean;
  showToc: boolean;
  showLeadParagraph: boolean;
  showUpdatedDate: boolean;
};

export const CmsContent = <T extends Article>({
  cmsNode,
  showTitle,
  showToc,
  showLeadParagraph,
  showUpdatedDate
}: ContentProps<T>) => {
  const { t } = useTranslation('translation');
  const [headerRefs, setHeaderRefs] = useState<Element[]>([]);

  const isSkyreise = cmsNode.type === 'process';
  const isSkyreiseStep = cmsNode.type === 'process-step';
  const isAvtale = cmsNode.type === 'avtale';
  const isLandingsside = cmsNode.type === 'landingsside';

  const hasProcessMenu =
    !!(cmsNode?.steps && cmsNode.steps.length > 0) ||
    !!(cmsNode?.process && cmsNode.process.depth > 2);

  const AvtaleTimestamp = () => (
    <section className={css.timestamp}>
      {cmsNode?.created && (
        <Time title={t('publisert_dato')} date={cmsNode.created} />
      )}
      {cmsNode?.changed && (
        <Time title={t('sist_endret')} date={cmsNode.changed} />
      )}
    </section>
  );

  const ToC = () => (
    <>
      {!isSkyreiseStep && !isLandingsside && showToc && (
        <TableOfContents refs={headerRefs} />
      )}
    </>
  );

  const Title = () => (
    <>
      {showTitle && (
        <T cmsNode={cmsNode} altAvtaleType={t('avtale_type_unknown')} />
      )}
    </>
  );

  const LeadParagraph = () => (
    <>
      {showLeadParagraph && !!cmsNode.field_intro_text && (
        <LP cmsNode={cmsNode} />
      )}
    </>
  );

  const ShowUpdatedDate = () => (
    <>
      {showUpdatedDate && !!cmsNode.changed && !isAvtale && (
        <div className={css.sistEndert}>
          <Time title={t('sist_endret')} date={cmsNode.changed} />
        </div>
      )}
    </>
  );

  const Skyreise = () => (
    <>
      {(isSkyreise || isSkyreiseStep) && (
        <Skyreisen cmsNode={cmsNode as Process} />
      )}
    </>
  );

  return (
    <div
      className={css.CmsNode}
      data-cy={'cms-content'}
      data-er-skyreise={isSkyreiseStep}
      data-skyreise-har-navigasjon={hasProcessMenu}
      data-er-landingsside={isLandingsside}
    >
      <article className={isAvtale ? css.avtale : undefined}>
        {cmsNode && (
          <>
            <Title />
            <ToC />
            <Skyreise />
            {isAvtale && <AvtaleTimestamp />}
            <LeadParagraph />
            <Body cmsNode={cmsNode} setHeaderRefs={setHeaderRefs} />
            <ShowUpdatedDate />
          </>
        )}
        {!cmsNode}
      </article>
    </div>
  );
};
