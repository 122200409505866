import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import css from './TabSelector.module.scss';

export interface Tab {
  id: string;
  label: string;
  selected: boolean;
}

interface TabSelectorProps {
  tabs: Tab[];
  selected: number;
  onSelect?: any;
  className?: string;
}

export default function TabSelector({
  tabs,
  selected,
  onSelect,
  className
}: TabSelectorProps) {
  const [selectedTab, setSelectedTab] = useState<number>(selected);

  const selectTab = (index: number) => {
    setSelectedTab(index);
    if (typeof onSelect === 'function') {
      onSelect(index);
    }
  };

  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  return (
    <div className={classNames(css.TabSelector, className)} role="tablist">
      {tabs.map((tab, index) => (
        <button
          key={tab.id}
          className={index === selectedTab ? css.active : 'inactive'}
          onClick={() => selectTab(index)}
          role="tab"
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
}
