import { useState } from 'react';

export const useAsyncReducer = (reducer: any, initState: any): any[] => {
  const [state, setState] = useState(initState);
  const dispatch = async (action: any) => {
    const result = reducer(state, action);
    if (typeof result.then === 'function') {
      try {
        setState({ ...state, loading: true });
        const newState = await result;
        setState(newState);
      } catch (err) {
        setState({ ...state, error: err });
      }
    } else {
      setState(result);
    }
  };

  return [state, dispatch];
};
