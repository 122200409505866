import DOMPurify from 'dompurify';

import {
  DrupalArticleIds,
  DrupalVeiledningForBrukAvSkyEtterSchremsIIIds,
  DrupalVeiledningPersonverkrisikoforEtterretningIds,
  DrupalVeiledningForBrukAvSkyEtterSchremsIIPath,
  DrupalVeiledningPersonvernrisikoforEtterretningPath
} from 'shared-constants';
import { DrupalType } from 'shared/api/drupal';

export const idToRoute = (id: string): string | undefined => {
  switch (id) {
    case DrupalArticleIds.VeiledningForside:
      return '/veiledning';
    case DrupalArticleIds.Skyreisen:
      return '/veiledning/skyreisen';
    case DrupalArticleIds.OmMarkedsplassen:
      return '/om-markedsplassen';
    case DrupalArticleIds.TilslutningAvtalene:
      return '/tilslutning-til-avtalene';
    default:
      return undefined;
  }
};

const mapToRoute = (drupalType: DrupalType, nodeId: string): string => {
  const knownRoute = idToRoute(nodeId);
  if (knownRoute) {
    return knownRoute;
  }
  const sanitizedDrupalType = drupalType.match(/^\[nb\][\s]+/)
    ? drupalType.replace(/^\[nb\][\s]+/, '')
    : drupalType;
  switch (sanitizedDrupalType) {
    case 'agreement':
      return `/avtaler/${nodeId}`;
    case 'landing_page':
      return mapToHardcodedPageRoute(nodeId);
    case 'page':
      return mapToHardcodedPageRoute(nodeId);
    case 'news':
      return `/nyheter/${nodeId}`;
    case 'process':
      return '/veiledning/skyreisen';
    case 'process_page':
      return `/veiledning/skyreisen/${nodeId}`;
    default:
      return `/veiledning/${nodeId}`;
  }
};

const mapToHardcodedPageRoute = (nodeId: string): string => {
  let route = `/veiledning/${nodeId}`;

  if (nodeId === DrupalArticleIds.VeiledningForBrukAvSkyEtterSchremsII) {
    route = `/veiledning/${DrupalVeiledningForBrukAvSkyEtterSchremsIIPath}`;
  } else if (nodeId === DrupalArticleIds.PersonvernRisiko) {
    route = `/veiledning/${DrupalVeiledningForBrukAvSkyEtterSchremsIIPath}/${DrupalVeiledningPersonvernrisikoforEtterretningPath}`;
  } else if (
    Object.values(DrupalVeiledningForBrukAvSkyEtterSchremsIIIds).includes(
      nodeId
    )
  ) {
    route = `/veiledning/${DrupalVeiledningForBrukAvSkyEtterSchremsIIPath}/${nodeId}`;
  } else if (
    Object.values(DrupalVeiledningPersonverkrisikoforEtterretningIds).includes(
      nodeId
    )
  ) {
    route = `/veiledning/${DrupalVeiledningForBrukAvSkyEtterSchremsIIPath}/${DrupalVeiledningPersonvernrisikoforEtterretningPath}/${nodeId}`;
  }

  return route;
};

const markedsplassenMapToRoute = (node): string => {
  return node.toString().replace(process.env.REACT_APP_PRODUCTION_ROOT_URI, '');
};

export const toAnchorName = (text: string | null) =>
  text !== null
    ? text
        .trim()
        .replace(/\s/g, '-')
        .replace(/[^a-zA-Z-0-9]/g, '')
        .toLowerCase()
    : '';

const toDrupalInternalHref = (node): string => {
  const drupalType = node.attributes['data-type'].value as DrupalType;
  return mapToRoute(drupalType, node.attributes['data-id'].value);
};

const toMarkedsplassenInternalHref = (node): string => {
  const localHref = node.href;
  return markedsplassenMapToRoute(localHref);
};

const isDrupalLink = (node): boolean => {
  return (
    node.attributes.href !== undefined &&
    node.attributes.href.value.startsWith('/') &&
    node.attributes['data-type'] !== undefined &&
    node.attributes['data-id'] !== undefined
  );
};

const isMediaLink = (node): boolean => {
  return (
    node.attributes['data-media-type'] !== undefined ||
    node.attributes['data-media-title'] !== undefined ||
    node.attributes['data-media-size'] !== undefined ||
    node.attributes['data-media-extension'] !== undefined
  );
};

export const isMarkedsplassenInternalLink = (node): boolean => {
  return (
    node.origin === process.env.REACT_APP_PRODUCTION_ROOT_URI &&
    isMediaLink(node)
  );
};

export const isInternalLink = (node): boolean => {
  return (
    !isMediaLink(node) &&
    (isDrupalLink(node) || isMarkedsplassenInternalLink(node))
  );
};

const isNodeType = (node: Element, type: string | Array<string>): boolean => {
  if (Array.isArray(type)) {
    return type.includes(node.nodeName.toUpperCase());
  } else {
    return node.nodeName.toUpperCase() === type;
  }
};

export const HEADER_TAGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

const isHeaderElement = (node: Element): boolean => {
  // The title of a link list (the Gutenberg block "manuell liste") from Drupal appears as a H2, but it's actually
  // a div styled to look like H2. This element must be included when checking if a node is a header.
  return (
    isNodeType(node, HEADER_TAGS) ||
    (isNodeType(node, 'DIV') && node.classList.contains('list__title'))
  );
};

const addHooks = (): void => {
  const afterSanitizeAttributes = (node) => {
    if (isNodeType(node, HEADER_TAGS.concat(['P', 'DIV']))) {
      if (!node.hasChildNodes() && !node.textContent) {
        node.remove();
      }
    }

    if (isHeaderElement(node) && node.textContent !== null) {
      node.setAttribute('id', toAnchorName(node.textContent));
    }

    if (isNodeType(node, 'A')) {
      if (isDrupalLink(node)) {
        node.setAttribute('href', toDrupalInternalHref(node));
      } else if (isMarkedsplassenInternalLink(node)) {
        node.setAttribute('href', toMarkedsplassenInternalHref(node));
        node.removeAttribute('data-type');
      }
    }
  };

  const stripNonBreakSpaceFromHeaders = (node) => {
    if (isHeaderElement(node) && node.textContent !== null) {
      node.textContent = node.textContent.replace(/\xA0/g, ' ').trim();
    }
  };

  DOMPurify.addHook('afterSanitizeAttributes', afterSanitizeAttributes);
  DOMPurify.addHook('uponSanitizeElement', stripNonBreakSpaceFromHeaders);
};

const SANITIZE_OPTIONS = {
  ADD_ATTR: ['class'],
  ALLOW_DATA_ATTR: true
};

const SANITIZE_OPTIONS_NO_TAGS = {
  ...SANITIZE_OPTIONS,
  ALLOWED_TAGS: []
};

export const sanitizeHtml = (
  content: string | null,
  removeHtmlTags = false
): string => {
  if (content === null) {
    return '';
  }

  addHooks();

  const purifiedHtml = DOMPurify.sanitize(
    content,
    removeHtmlTags ? SANITIZE_OPTIONS_NO_TAGS : SANITIZE_OPTIONS
  );

  DOMPurify.removeAllHooks();

  return purifiedHtml;
};
