import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import css from './Grid.module.scss';

export interface GridProps {
  columns: 1 | 2 | 3;
  className?: string;
  children?: ReactNode;
}

export default function Grid({
  columns,
  className,
  children
}: GridProps): ReactElement {
  return (
    <div
      className={classNames([css.Grid, className])}
      data-grid-columns={columns}
    >
      {children}
    </div>
  );
}
