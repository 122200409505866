import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { Loading } from 'Components/Loading';
import { useCmsById } from 'store/CmsQuery';
import Icon from '../Icon/Icon';
import css from './Process.module.scss';

interface ProcessStepProps {
  cmsRef: string;
  tooltip: string;
  step: number;
  baseUrl: string;
  active?: boolean;
  hoveredStep: number;
  onStepHover: (index: number) => void;
}

export const ProcessStep = ({
  cmsRef,
  baseUrl,
  tooltip,
  step,
  active,
  hoveredStep,
  onStepHover
}: ProcessStepProps) => {
  const { cmsNode, isLoading } = useCmsById('process-step', cmsRef);
  const classes = [css.ProcessStep];

  if (active) {
    classes.unshift(css.Active);
  }

  if (hoveredStep - 1 === step) {
    classes.unshift(css.PreHover);
  }

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  if (cmsNode) {
    return (
      <>
        <li className={classNames(classes)}>
          <Link
            to={`${baseUrl}${cmsNode.id}`}
            className={css.StepLink}
            onMouseEnter={() => {
              onStepHover(active ? -1 : step);
            }}
            onMouseLeave={() => {
              onStepHover(-1);
            }}
            onFocus={() => {
              onStepHover(active ? -1 : step);
            }}
            onBlur={() => {
              onStepHover(-1);
            }}
          >
            {isLoading ? (
              <Loading loading={isLoading} />
            ) : (
              <>
                {cmsNode.title}
                {tooltip && tooltip.trim().length > 0 && (
                  <div className={css.IconContainer}>
                    <div
                      data-for="custom-event"
                      data-tip={tooltip}
                      data-event="click focus"
                      data-place="bottom"
                      data-globaleventoff="click"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Icon symbol={'info'} tabIndex={-1} />
                    </div>
                  </div>
                )}
              </>
            )}
          </Link>
        </li>
        <ReactTooltip
          className={css.Tooltip}
          id="custom-event"
          globalEventOff="click"
          effect={'solid'}
          place="bottom"
          backgroundColor="white"
          arrowColor={'#1a97df'}
          isCapture={true}
          delayHide={10}
        />
      </>
    );
  } else {
    return <></>;
  }
};
