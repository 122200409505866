import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSearch, useSearchDispatch } from 'store';
import Pagination from 'Components/Pagination/Pagination';
import Spinner from 'Components/Spinner/Spinner';
import css from './SearchResults.module.scss';
import Grid from '../UI/Grid/Grid';
import TjenesteCard from '../TjenesteCard/TjenesteCard';
import { Kategori, Tjeneste } from '../../models';
import { useVirksomheter } from '../../shared/hooks/virksomhet-hooks';

const PAGE_SIZE = 20;

export interface KategoriProps {
  kategori: Kategori | undefined;
}

export default function SearchResults({ kategori }: KategoriProps) {
  const search = useSearch();
  const searchDispatch = useSearchDispatch();
  const { data: virksomheter } = useVirksomheter();
  const { t } = useTranslation('translation');

  const produsent = virksomheter?.find(
    (virksomhet) => virksomhet.id === search.produsentId
  );

  const searchText = () => {
    if (kategori) {
      const text = () =>
        search.kategorier.length > 0 ? search.kategorier[0] : kategori.id;
      return (
        <>
          {search.numberOfElementsFound === 1
            ? t('1_tjeneste_i')
            : t('tjeneste_i')}{' '}
          <strong>{t('kat_' + text())}</strong>
        </>
      );
    }
    if (search.produsentId) {
      const produsentNavn = produsent?.navn;
      return (
        <>
          {search.numberOfElementsFound === 1
            ? t('1_tjeneste_i')
            : t('tjeneste_i')}{' '}
          <strong>{produsentNavn}</strong>
        </>
      );
    }
  };

  const Hits = () => {
    return (
      <Grid columns={2}>
        {search.elements.map((item) => {
          return <TjenesteCard key={item.id} tjeneste={item as Tjeneste} />;
        })}
      </Grid>
    );
  };

  const resultPages = (): number => {
    return Math.ceil((search.numberOfElementsFound ?? 0) / PAGE_SIZE);
  };

  const searchResults = () => {
    if (search.loading) {
      return;
    }
    if (search.executed && search.elements.length === 0) {
      return (
        <li>
          <h3>{t('Ingen_tjeneste')}</h3>
        </li>
      );
    }
    return <Hits />;
  };

  const handleClick = (index) => {
    searchDispatch({ type: 'update', payload: { page: index } });
  };

  return (
    <div className={css.SearchResults}>
      <div className={css.Header}>
        <Spinner isActive={search.loading} label={t('Søker')} />
        {!search.loading && search.numberOfElementsFound !== 0 && (
          <>
            <strong>{search.numberOfElementsFound}</strong> {searchText()}
          </>
        )}
      </div>
      <ul className={css.Hits}>{searchResults()}</ul>
      {!search.loading &&
        !!search.numberOfElementsFound &&
        search.numberOfElementsFound > PAGE_SIZE && (
          <Pagination
            pageCount={resultPages()}
            pageIndex={search.page}
            setPageIndex={handleClick}
          />
        )}
    </div>
  );
}
