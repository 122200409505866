import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { TJENESTEREGISTER_API_BASE_URL } from 'shared-constants';

class HttpService {
  readonly axiosInstance = axios.create({
    baseURL: TJENESTEREGISTER_API_BASE_URL,
    headers: {
      'Content-type': 'application/json'
    }
  });

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosInstance.get<T, R>(url, {
      ...config,
      headers: this.headers(config)
    });
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosInstance.post<T, R>(url, data, {
      ...config,
      headers: this.headers(config)
    });
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosInstance.put<T, R>(url, data, {
      ...config,
      headers: this.headers(config)
    });
  }

  patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosInstance.patch<T, R>(url, data, {
      ...config,
      headers: this.headers(config)
    });
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.axiosInstance.delete<T, R>(url, {
      ...config,
      headers: this.headers(config)
    });
  }

  private headers = (
    config?: AxiosRequestConfig
  ): { [key: string]: string } => {
    return { ...config?.headers } || {};
  };
}

export default new HttpService();

export function buildUrl(parts: string[]): string {
  let url = parts.join('/');
  if (url.indexOf('://') === -1 && !url.startsWith('/')) {
    url = '/' + url;
  }
  return url.replace(':///', '://');
}
