import {
  DrupalCollection,
  DrupalResponse,
  ContentNode,
  TaxonomyNode
} from 'shared/api/drupal';

export const node: ContentNode = {
  type: 'node--page',
  id: '010ff6bf-3151-48d8-b10d-d9f37526dc02',
  links: {
    self: {
      href: ''
    }
  },
  attributes: {
    drupal_internal__nid: 3,
    drupal_internal__vid: 7,
    langcode: 'nb',
    revision_timestamp: '2021-04-19T11:34:39+00:00',
    revision_log: null,
    status: false,
    title: 'Mock title',
    created: new Date(2021, 4, 19, 11, 25, 41),
    changed: new Date(2021, 4, 19, 11, 34, 39),
    promote: false,
    sticky: false,
    default_langcode: true,
    revision_translation_affected: true,
    moderation_state: 'draft',
    path: {
      alias: null,
      pid: null,
      langcode: 'nb'
    },
    process: null,
    content_translation_source: 'und',
    content_translation_outdated: false,
    published_time: new Date(2038, 1, 19, 3, 14, 7),
    body: {
      value: '',
      format: 'gutenberg',
      processed: ''
    },
    field_intro_text: 'Ingress',
    field_show_toc: true
  },
  relationships: {
    process_parent: {
      data: null,
      links: {
        self: {
          href: ''
        }
      }
    },
    process_children: {
      data: [],
      links: {
        self: {
          href: ''
        }
      }
    },
    node_type: {
      data: null,
      links: {
        self: {
          href: ''
        }
      }
    },
    revision_uid: {
      data: null,
      links: {
        self: {
          href: ''
        }
      }
    },
    uid: {
      data: null,
      links: {
        self: {
          href: ''
        }
      }
    },
    field_image: {
      data: null,
      links: {
        related: {
          href: ''
        },
        self: {
          href: ''
        }
      }
    }
  }
};

const processNode: ContentNode = {
  ...node,
  type: 'node--process',
  id: 'adca5126-85ec-4aed-8de2-c3efdabbd77a',
  attributes: {
    ...node.attributes,
    drupal_internal__nid: 21
  },
  relationships: {
    ...node.relationships,
    process_children: {
      data: ['ada86036-00c2-4c49-a767-5f01141291da'],
      links: { self: { href: '' }, related: { href: '' } }
    }
  }
};

const processPageNode: ContentNode = {
  ...node,
  type: 'node--process_page',
  id: 'ada86036-00c2-4c49-a767-5f01141291da',
  relationships: {
    ...node.relationships,
    process_parent: {
      ...node.relationships.process_parent,
      data: {
        id: 'adca5126-85ec-4aed-8de2-c3efdabbd77a'
      }
    }
  }
};

const agreementNode: ContentNode = {
  ...node,
  type: 'node--agreement',
  id: '1ef93447-953a-45d8-a5a9-0789f582121e',
  relationships: {
    ...node.relationships,
    field_agreement_type: {
      ...node.relationships.field_agreement_type,
      data: {
        type: 'taxonomy_term--agreement_type',
        id: '04d07eff-5816-4d14-a7f2-ed37f5ac2c53'
      }
    }
  }
};

const agreementTaxonomy: TaxonomyNode = {
  ...node,
  type: 'taxonomy_term--agreement_type',
  id: '04d07eff-5816-4d14-a7f2-ed37f5ac2c53',
  attributes: {
    ...node.attributes,
    name: 'Rammeavtale'
  }
};

export const page: DrupalResponse = {
  jsonapi: {
    version: '1.0',
    meta: {
      links: {
        self: {
          href: ''
        }
      }
    }
  },
  data: node,
  meta: {
    count: '1'
  },
  links: {
    self: {
      href: ''
    }
  }
};

export const pages: DrupalCollection = {
  jsonapi: {
    version: '1.0',
    meta: {
      links: {
        self: {
          href: ''
        }
      }
    }
  },
  data: [node],
  meta: {
    count: '1'
  },
  links: {
    self: {
      href: ''
    }
  }
};

export const process: DrupalResponse = {
  ...page,
  data: processNode
};

export const processCollection: DrupalCollection = {
  ...pages,
  data: [processNode]
};

export const processPage: DrupalResponse = {
  ...page,
  data: processPageNode
};

export const agreement: DrupalResponse = {
  ...page,
  data: agreementNode,
  included: [agreementTaxonomy]
};

export const agreementCollection: DrupalCollection = {
  ...pages,
  data: [agreementNode],
  included: [agreementTaxonomy]
};
