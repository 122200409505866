import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { usePageHeadContext } from 'store/PageHeadContext';
import css from './NotFound.module.scss';

export const NotFound = () => {
  const { t } = useTranslation('translation');
  const { setPageTitle } = usePageHeadContext();

  useEffect(() => {
    setPageTitle(t('error_404_header'));
  }, [setPageTitle, t]);

  return (
    <div className={css.NotFound}>
      <div>
        <img src={'/images/not-found.svg'} alt={t('error_404_alt')} />
      </div>
      <div>
        <h1>{t('error_404_header')}</h1>
        <p dangerouslySetInnerHTML={{ __html: t('error_404_message') }} />
        <p>
          <a href={'/'}>{t('error_404_back')}</a>
        </p>
      </div>
    </div>
  );
};
