import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

import css from './List.module.scss';

type OrientationType = 'vertical' | 'horizontal';
type AlignmentType = 'start' | 'end' | 'center';
type SpacingType = 'around' | 'between' | 'stretch';
type GapType = 'lg' | 'md' | 'sm' | 'none';

export interface ListProps {
  orientation?: OrientationType;
  alignment?: AlignmentType;
  spacing?: SpacingType;
  gap?: GapType;
  className?: string;
  children?: ReactNode;
}

export default function List({
  orientation,
  alignment,
  spacing,
  gap = 'md',
  className,
  children
}: ListProps): ReactElement {
  return (
    <div
      className={classNames([css.List, className])}
      data-list-orientation={orientation}
      data-list-alignment={alignment}
      data-list-spacing={spacing}
      data-list-gap={gap}
    >
      {children}
    </div>
  );
}
