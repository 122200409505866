import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'Components/Icon/Icon';
import css from './Toolbar.module.scss';

export interface ToolbarItem {
  label: string;
  symbol: string;
  link?: string;
  action?: any;
}

interface ToolbarProps {
  toolbarItems: ToolbarItem[];
}

export default function Toolbar(props: ToolbarProps) {
  const renderToolbarItems = () => {
    return props.toolbarItems.map((item: ToolbarItem, index) => {
      if (item.link) {
        return (
          <li key={index}>
            {!!item.link && (
              <Link to={item.link}>
                <Icon symbol={item.symbol} tabIndex={-1} />
                {item.label}
              </Link>
            )}
          </li>
        );
      } else if (item.action) {
        return (
          <li key={index} onClick={item.action}>
            <Icon symbol={item.symbol} tabIndex={-1} />
            {item.label}
          </li>
        );
      }
      return (
        <li key={index} className={css.disabled}>
          <Icon symbol={item.symbol} tabIndex={-1} />
          {item.label}
        </li>
      );
    });
  };

  return (
    <nav className={css.Toolbar}>
      <ul>{renderToolbarItems()}</ul>
    </nav>
  );
}
