import React, { createContext, ReactNode, useContext } from 'react';

import { Artikkel } from 'models';
import { useAsyncReducer } from './async-reducer';
import { artiklerMock } from 'mocks';

interface AvtalerState {
  avtale?: Artikkel;
  avtaler?: Artikkel[];
  loading: boolean;
}

type Action = {
  type: 'get' | 'all';
  payload?: any;
};

type Dispatch = (action: Action) => void;
type Props = { children: ReactNode };

const initialState: AvtalerState = {
  avtale: undefined,
  avtaler: undefined,
  loading: false
};

export const AvtalerContext = createContext<AvtalerState | undefined>(
  undefined
);
export const AvtalerDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const avtalerReducer = (state: AvtalerState, action: any): any => {
  if (action.type === 'get') {
    if (state.loading) {
      return state;
    }

    if (!action.payload.id) {
      return state;
    }

    state = {
      ...state,
      avtale: artiklerMock.find(
        (artikkel) => artikkel.uuid[0].value === action.payload.id
      ) as Artikkel,
      loading: false
    };

    return {
      ...state
    };
  } else if (action.type === 'all') {
    if (state.loading) {
      return state;
    }

    state = {
      ...state,
      avtaler: artiklerMock,
      loading: false
    };

    return {
      ...state
    };
  }

  return state;
};

function AvtalerContextProvider(props: Props) {
  const { children } = props;
  const [state, dispatch] = useAsyncReducer(avtalerReducer, initialState);

  return (
    <AvtalerContext.Provider value={state}>
      <AvtalerDispatchContext.Provider value={dispatch}>
        {children}
      </AvtalerDispatchContext.Provider>
    </AvtalerContext.Provider>
  );
}

const useAvtaler = () => {
  const context = useContext(AvtalerContext);
  if (context === undefined) {
    throw new Error('useAvtaler must be used within a AvtalerContextProvider');
  }
  return context;
};

const useAvtalerDispatch = () => {
  const context = useContext(AvtalerDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useAvtalerDispatch must be used within a AvtalerContextProvider'
    );
  }
  return context;
};

export { AvtalerContextProvider, useAvtaler, useAvtalerDispatch };
