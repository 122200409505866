import React from 'react';

import css from './Firmaprofil.module.scss';
import { useCmsById } from 'store/CmsQuery';
import { DrupalArticleIds } from 'shared-constants';
import { Article } from 'shared/api/drupal';
import { Body as CmsBody } from '../CmsNode/parts';
import { Loading } from 'Components/Loading';

export const Information = () => {
  const { cmsNode, isLoading } = useCmsById<Article>(
    'veiledning',
    DrupalArticleIds.Firmaprofil
  );

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  if (cmsNode) {
    return (
      <div className={css.Information}>
        <CmsBody cmsNode={cmsNode} />
      </div>
    );
  }

  return <></>;
};
