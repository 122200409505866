import React, { ReactElement, useEffect, useState } from 'react';

import css from './SearchField.module.scss';
import { useSearch, useSearchDispatch } from 'store';
import Icon from '../Icon/Icon';

interface SearchProps {
  placeholder: string;
}

interface InternalState {
  query: string;
}

const initialState: InternalState = {
  query: ''
};

export default function SearchField(props: SearchProps): ReactElement {
  const [state, setState] = useState(initialState);
  const search = useSearch();
  const dispatch = useSearchDispatch();

  useEffect(() => {
    setState({
      query: search.query
    });
  }, [search.query]);

  const handleInputFieldUpdate = (input: HTMLInputElement): void => {
    setState({
      query: input.value
    });

    dispatch({
      type: 'update',
      payload: {
        query: input.value
      }
    });
  };

  return (
    <label className={css.searchField}>
      <input
        type={'search'}
        name={'query'}
        value={state.query}
        aria-label={props.placeholder}
        placeholder={props.placeholder}
        onChange={(event) => {
          handleInputFieldUpdate(event.target as HTMLInputElement);
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            dispatch({ type: 'search' });
          }
        }}
      />
      <button
        type={'button'}
        onClick={() => {
          dispatch({ type: 'search' });
        }}
      >
        <Icon symbol={'search'} tabIndex={-1} />
      </button>
    </label>
  );
}
