/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import css from './LeverandoerResults.module.scss';
import { NewLeverandoer } from 'models';
import Grid from 'Components/UI/Grid/Grid';
import { ReactComponent as ExportList } from '../../images/export-list.svg';
import LeverandoerCard from '../LeverandoerCard/LeverandoerCard';
import { exportToCSV } from 'shared/exportToCSV';

export interface LeverandoerResultsProps {
  leverandoer: NewLeverandoer[];
}

export default function LeverandoerResults({
  leverandoer
}: LeverandoerResultsProps) {
  const { t } = useTranslation('translation');

  const handleDownloadAsCSV = () => {
    exportToCSV(leverandoer);
  };

  const Hits = () => {
    return (
      <Grid columns={2}>
        {leverandoer &&
          !!leverandoer.length &&
          leverandoer.map((item, id) => {
            return (
              <LeverandoerCard key={id} leverandoer={item as NewLeverandoer} />
            );
          })}
      </Grid>
    );
  };

  return (
    <div className={css.Results}>
      <div className={css.resultTopLineWrapper}>
        <p>{leverandoer && leverandoer.length + ' ' + t('Leverandoerer')}</p>

        <div className={css.exportListWrapper} onClick={handleDownloadAsCSV}>
          <p>{t('eksport_list')}</p>
          <ExportList />
        </div>
      </div>

      <ul className={css.Hits}>{Hits()}</ul>
    </div>
  );
}
