import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import css from './Brand.module.scss';

export type BrandVariant = 'header' | 'footer';

interface BrandProps {
  variant?: BrandVariant;
}

export default function Brand(props: BrandProps): ReactElement {
  const { t } = useTranslation('translation');
  const variant: BrandVariant = props.variant ? props.variant : 'header';

  return (
    <Link className={classNames([css.Brand, css[variant]])} to={'/'}>
      <img src={`/logo-${variant}.svg`} alt={'DFØ Logo'} />
      <div className={css.Border} />
      <h1>{t('app_title')}</h1>
      <p>{t('app_subtitle')}</p>
    </Link>
  );
}
