import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TJENESTER_URL } from 'shared-constants';
import formatOrgNr from 'shared/format-orgnr';
import Spinner from 'Components/Spinner/Spinner';
import Toolbar, { ToolbarItem } from 'Components/Toolbar/Toolbar';
import { buildUrl } from 'shared/http-common';
import css from './LeverandoerSide.module.scss';
import LinkSection from 'Components/LinkSection/LinkSection';
import { useLeverandoerInfo } from 'shared/hooks/leverandoer-hooks';

export default function LeverandoerSide() {
  const { tjenesteId, leverandoerId } = useParams<{
    tjenesteId: string;
    leverandoerId: string;
  }>();
  const { data: leverandoerInfo, isLoading } = useLeverandoerInfo(
    tjenesteId,
    leverandoerId
  );
  const { t } = useTranslation('translation');

  const leverandoerNavn = (): string => leverandoerInfo?.virksomhet.navn ?? '';

  const leverandoerOrgNr = (): string =>
    leverandoerInfo?.virksomhet.orgnr.toString() ?? '';

  const formatDate = (dato: string | null | undefined): string => {
    if (!dato) {
      return '';
    }

    return new Intl.DateTimeFormat('no-NB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).format(new Date(dato));
  };

  const toolbarItems: ToolbarItem[] = [
    {
      label: t('Leverandoerer'),
      link: buildUrl([TJENESTER_URL, tjenesteId]) + '/leverandoerer',
      symbol: 'arrow-left'
    }
  ];

  return (
    <div className={css.LeverandoerSide}>
      <header>
        <Toolbar toolbarItems={toolbarItems} />
        <h1>{leverandoerNavn()}</h1>
        <h2>
          {t('orgnr')}: {formatOrgNr(leverandoerOrgNr())}
        </h2>
      </header>

      <section>
        <span>{t('st_informasjonen_under_kommer')}</span>
        {leverandoerInfo?.sistEndretDato && (
          <span className={css.dato}>
            {t('st_sist_oppdatert', {
              dato: formatDate(leverandoerInfo?.sistEndretDato)
            })}
          </span>
        )}
      </section>

      <section>
        <h1>{t('st_hvordan_vi_hjelper')}</h1>
        <Spinner isActive={isLoading} />
        <p className={css.beskrivelse}>{leverandoerInfo?.beskrivelse}</p>
      </section>
      {leverandoerInfo?.url && (
        <LinkSection
          title={t('st_beskrivelse_lenke_tekst')}
          url={leverandoerInfo?.url}
        />
      )}
    </div>
  );
}
