import { LeverandoerInfo } from 'models';

export const leverandoererMocks: LeverandoerInfo[] = [
  {
    beskrivelse:
      'alsdjfalsdk fjalskdf jsalf jsladfj salfj asløfj slaf jlsafj salf.\njasdsfk aslfd sadlfj',
    virksomhet: {
      id: '01222',
      navn: 'Amesto',
      orgnr: 1
    }
  },
  {
    beskrivelse:
      'fjalskdf jsalf jsladfj salfj asløfj slaf jlsafj.\nsalf jasdsfk aslfd sadlfj asdf aa sd ddd d s',
    virksomhet: {
      id: '01223',
      navn: 'Microsoft',
      orgnr: 2
    }
  }
];
