import { Action, ActionType } from 'store/actions';

export interface State {
  loading: boolean;
  message: string;
}

const initialState = {
  loading: false,
  message: ''
};

export function globalReducers(
  state: State = initialState,
  action: Action
): State {
  let message: string;
  switch (action.type as string) {
    case ActionType.LOADING:
      state = {
        ...state,
        loading: true
      };
      break;

    case ActionType.ERROR:
      message = action.payload.error.toString();

      if (action.payload.error.response) {
        message = action.payload.error.toString();

        switch (action.payload.error.response.status) {
          case 401:
            message = 'error_generisk_melding';
            break;

          case 403:
            message = 'error_generisk_melding';
            break;

          case 413:
            message = 'error_413_too_large';
            break;

          default:
            message = 'error_generisk_melding_med_feedback_link';
        }
      }

      state = {
        ...state,
        message,
        loading: !action.payload.stopLoadingFlag
      };
      break;

    case ActionType.ERROR_CLOSE:
      state = {
        ...state,
        message: ''
      };
      break;

    default:
      if (
        !action.type.startsWith('@@INIT') &&
        !action.type.startsWith('@@redux/INIT') &&
        !action.type.startsWith('AAD_') &&
        process.env.NODE_ENV === 'development'
      ) {
        console.log('No handler defined for', action.type);
      }
      break;
  }

  return state;
}
