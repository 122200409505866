import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import css from './FormGroup.module.scss';

export interface FormGroupProps {
  isValid?: boolean;
  invalidMessage?: string;
  children?: ReactNode | ReactNode[] | JSX.Element | JSX.Element[];
}

export default function FormGroup({
  isValid,
  invalidMessage,
  children
}: FormGroupProps): ReactElement {
  const { t } = useTranslation('translation');

  return (
    <div
      className={classNames(
        css.FormGroup,
        isValid === undefined ? null : css.ShowValidation,
        isValid !== undefined && isValid ? null : css.IsInvalid
      )}
    >
      {children}
      {!isValid && (
        <p className={css.Error}>
          {invalidMessage ? invalidMessage : t('fg_generisk_feilmelding')}
        </p>
      )}
    </div>
  );
}
