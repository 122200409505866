import React, {
  BaseSyntheticEvent,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { LeverandoerInfo } from 'models';
import { DFO, store } from 'store';
import css from './LeverandoerTjenesteSide.module.scss';
import style from '../OversiktTjenesteSide/OversiktTjenesteSide.module.scss';
import {
  leverandoerQueryKeys,
  useLeverandoerInfo,
  useUpdateLeverandoerInfo
} from 'shared/hooks/leverandoer-hooks';
import { buildUrl } from 'shared/http-common';
import { FIRMAPROFIL_URL } from '../shared-constants';
import { useActiveVirksomhet } from 'store/VirksomhetContext';

interface LeverandoerTjenesteSideState {
  leverandoer?: LeverandoerInfo;
  beskrivelse: string;
  url?: string;
}

const initialState: LeverandoerTjenesteSideState = {
  beskrivelse: '',
  url: ''
};

export default function LeverandoerTjenesteSide(): ReactElement {
  const [state, setState] = useState(initialState);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { activeVirksomhet } = useActiveVirksomhet();
  const queryClient = useQueryClient();
  const { data: leverandoerInfo, isLoading } = useLeverandoerInfo(
    id,
    activeVirksomhet?.id
  );

  const {
    update: updateLeverandoer,
    isLoading: isUpdating
  } = useUpdateLeverandoerInfo();

  useEffect(() => {
    if (leverandoerInfo) {
      const nyBeskrivelse = leverandoerInfo?.beskrivelse ?? '';
      const nyUrl = leverandoerInfo?.url ?? '';
      setState((state) => {
        return {
          ...state,
          beskrivelse: nyBeskrivelse,
          url: nyUrl
        };
      });
    }
  }, [leverandoerInfo]);

  const oppdaterBeskrivelse = (event: BaseSyntheticEvent): void => {
    setState({
      ...state,
      beskrivelse: event.target.value
    });
  };

  const handleLeverandoerUpdate = (
    oppdaterLeverandoer = {
      beskrivelse: state.beskrivelse,
      url: state.url
    }
  ): void => {
    if (activeVirksomhet && isLeverandoerUpdated()) {
      updateLeverandoer(
        id,
        activeVirksomhet.id as string,
        oppdaterLeverandoer,
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              leverandoerQueryKeys.byLeverandoerId(id, activeVirksomhet.id)
            );
          },
          onError: (error) => {
            store.dispatch(DFO.error(error, true));
          }
        }
      );
    }
  };

  const urlEndret = (input: HTMLInputElement): void => {
    setState({
      ...state,
      url: input.value
    });
  };

  const isLeverandoerUpdated = () => {
    return (
      state.url !== leverandoerInfo?.url ||
      state.beskrivelse !== leverandoerInfo?.beskrivelse
    );
  };

  const tilbakeTilFirmaprofil = (): void => {
    history.push(buildUrl([FIRMAPROFIL_URL]));
  };

  return (
    <>
      {!isLoading && (
        <div className={css.LeverandoerTjenesteSide}>
          <div className={css.Wrapper}>
            {leverandoerInfo && (
              <section>
                <h1>{t('st_leverandoerinformasjon')}</h1>
                <p>{t('st_beskrivelse')}</p>
                <textarea
                  name={'beskrivelse'}
                  aria-label={t('st_beskrivelsesfelt')}
                  onBlur={() => handleLeverandoerUpdate()}
                  onChange={oppdaterBeskrivelse}
                  value={state.beskrivelse}
                />
                <label>
                  <h1>{t('rt_tjeneste_lenke')}</h1>
                  <p>{t('rt_tjeneste_lenke_veiledning')}</p>
                  <input
                    type={'text'}
                    name={'url'}
                    aria-label={t('rt_tjeneste_lenke')}
                    defaultValue={state.url}
                    onInput={(e) => urlEndret(e.target as HTMLInputElement)}
                    onBlur={() => handleLeverandoerUpdate()}
                  />
                </label>
              </section>
            )}
            <div className={style.buttonTextContainer}>
              <button
                disabled={!state.beskrivelse || isUpdating}
                onClick={tilbakeTilFirmaprofil}
              >
                {t('ferdig')}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
