import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import css from './ImporterTjenesterSide.module.scss';
import {
  TJENESTER_IMPORT_STATUS_URL,
  TJENESTER_IMPORT_URL,
  TJENESTER_URL,
  VIRKSOMHETER_URL
} from 'shared-constants';
import http, { buildUrl } from 'shared/http-common';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import {
  authorizationHeaderFromToken,
  useAuthentication
} from 'shared/authentication';
import Spinner from 'Components/Spinner/Spinner';
import Toolbar from 'Components/Toolbar/Toolbar';
import Grid from 'Components/UI/Grid/Grid';
import Icon from 'Components/Icon/Icon';

export type ImportStatusResponse = {
  id: string;
  count?: number;
  status: string;
};

export default function ImporterTjenesterStatusSide(): ReactElement {
  const { t } = useTranslation('translation');
  const { context } = useAuthentication();
  const { activeVirksomhet } = useActiveVirksomhet();
  const [importJob, setImportJob] = useState<ImportStatusResponse | null>(null);

  const checkImportJobStatus = useCallback(() => {
    if (!importJob && activeVirksomhet && context.token) {
      const importJobUrl = buildUrl([
        VIRKSOMHETER_URL,
        activeVirksomhet.id,
        TJENESTER_URL,
        TJENESTER_IMPORT_URL,
        TJENESTER_IMPORT_STATUS_URL
      ]);

      http
        .get(importJobUrl, {
          headers: authorizationHeaderFromToken(context.token)
        })
        .then((response) => {
          setImportJob(response.data);
        });
    }
  }, [activeVirksomhet, context, importJob]);

  useEffect(() => {
    checkImportJobStatus();
  }, [checkImportJobStatus]);

  return (
    <div className={css.ImporterTjenesterSide}>
      {importJob ? (
        <>
          <header>
            <h1>{t('import_fullfoert')}</h1>
          </header>
          <Toolbar
            toolbarItems={[
              {
                label: t('ot_firmaprofil'),
                link: '/firmaprofil',
                symbol: 'arrow-left'
              }
            ]}
          />
          <main>
            <section>
              <p>{t('import_oppsummering_av_import')}</p>
              <Grid columns={2}>
                <div className={css.StatusPanel}>
                  <Icon symbol={'check'} tabIndex={-1} />
                  <p>
                    <em>{t('antall_tjenester', { count: importJob.count })}</em>{' '}
                    {t('import_ble_importert')}
                  </p>
                </div>
              </Grid>
            </section>
            <Link
              to={'/firmaprofil'}
              className={classNames(['button', css.ReturnButton])}
            >
              {t('import_gaa_til_tjenesteoversikten')}
              <Icon symbol={'arrow-right'} tabIndex={-1} />
            </Link>
          </main>
        </>
      ) : (
        <main>
          <Spinner isActive={true} />
        </main>
      )}
    </div>
  );
}
