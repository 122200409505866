import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TjenesteListe from 'Components/TjenesteListe/TjenesteListe';
import { useLogin } from 'shared/authentication';
import List from 'Components/UI/List/List';
import { useFeatureToggle } from 'shared/feature-toggle';
import { Features } from 'shared-constants';
import Icon from '../Components/Icon/Icon';
import css from './Firmaprofil.module.scss';
import { Virksomhet } from '../models';

type ActionProps = {
  visible?: boolean;
  disabled?: boolean;
  url: string;
  icon: string;
  labelKey: string;
};

const Action = ({
  visible = true,
  url,
  icon,
  labelKey,
  disabled = false
}: ActionProps) => {
  const history = useHistory();
  const { t } = useTranslation('translation');
  if (!visible) {
    return <></>;
  }
  return (
    <div
      className={css.Firmaprofil__actions}
      aria-disabled={disabled}
      onClick={() => {
        history.push(url);
      }}
    >
      <Icon symbol={icon} tabIndex={-1} />
      <span>{t(labelKey)}</span>
    </div>
  );
};

const Toolbar = ({ activeVirksomhet }: { activeVirksomhet: Virksomhet }) => {
  const { isAdministrator } = useLogin();
  const { isEnabled } = useFeatureToggle();

  return (
    <List orientation={'horizontal'} alignment={'start'} gap={'lg'}>
      <Action
        visible={isAdministrator()}
        url={'/min-side/rediger'}
        icon={'plus-circle'}
        labelKey={'rediger_virksomhet'}
      />
      <Action
        visible={isEnabled(Features.TjenesteregisterSkjema)}
        url={'/firmaprofil/tjenester/registrer'}
        icon={'plus-circle'}
        labelKey={'ny_tjeneste'}
        disabled={!activeVirksomhet}
      />
      <Action
        visible={isEnabled(Features.ExcelImport)}
        url={'/firmaprofil/tjenester/import'}
        icon={'upload'}
        labelKey={'importer_fra_excel'}
      />
    </List>
  );
};

export default function TjenesteRegistration({
  activeVirksomhet
}: {
  activeVirksomhet: Virksomhet | undefined;
}) {
  return (
    <section>
      {activeVirksomhet && (
        <>
          <Toolbar activeVirksomhet={activeVirksomhet} />
          <TjenesteListe
            produsentId={activeVirksomhet.id}
            pathParts={['firmaprofil', 'tjenester']}
          />
        </>
      )}
    </section>
  );
}
