import { Kategori } from 'models';

export const mockSaasKategori: Kategori = {
  id: 47,
  navn: 'Programvare i skyen',
  children: [
    {
      id: 5,
      navn: 'Legal and enforcement',
      parent: {
        id: 47,
        navn: 'Programvare',
        children: []
      },
      children: []
    },
    {
      id: 6,
      navn: 'Information and communication technology (ICT)',
      parent: {
        id: 47,
        navn: 'Programvare',
        children: []
      },
      children: []
    }
  ]
};

export const mockKategoriChild: Kategori = {
  id: 5,
  navn: 'Legal and enforcement',
  parent: {
    id: 47,
    navn: 'Programvare',
    children: []
  },
  children: []
};

export const mockHostingKategori: Kategori = {
  id: 46,
  navn: 'Hosting',
  children: [
    {
      id: 33,
      navn: 'NoSQL database',
      parent: {
        id: 46,
        navn: 'Hosting',
        children: []
      },
      children: []
    },
    {
      id: 34,
      navn: 'Object storage',
      parent: {
        id: 46,
        navn: 'Hosting',
        children: []
      },
      children: []
    }
  ]
};
