import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Virksomhet } from 'models';

const ActiveVirksomhetContext = createContext<
  | {
      activeVirksomhet: Virksomhet | undefined;
      setActiveVirksomhet: React.Dispatch<
        React.SetStateAction<Virksomhet | undefined>
      >;
    }
  | undefined
>(undefined);

export function ActiveVirksomhetProvider({
  children
}: {
  children: ReactNode;
}) {
  const [virksomhet, setVirksomhet] = useState<Virksomhet | undefined>(
    undefined
  );
  const value = {
    activeVirksomhet: virksomhet,
    setActiveVirksomhet: setVirksomhet
  };
  return (
    <ActiveVirksomhetContext.Provider value={value}>
      {children}
    </ActiveVirksomhetContext.Provider>
  );
}

export const useActiveVirksomhet = () => {
  const context = useContext(ActiveVirksomhetContext);
  if (context === undefined) {
    throw new Error(
      'useActiveVirksomhet must be used within a ActiveVirksomhetProvider'
    );
  }
  return context;
};
