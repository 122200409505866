import axios from 'axios';

import { UNLEASH_API_BASE_URL } from 'shared-constants';

export interface UnleashConfig {
  proxyUrl: string;
  clientKey: string;
  appName: string;
  environment: string;
  refreshInterval: number; // in ms
  timeout?: number;
  debug?: boolean;
}

export interface UnleashToggle {
  name: string;
  enabled: boolean;
  variant: Variant;
}

interface Variant {
  name: string;
  payload?: {
    type: string;
    value: string;
  };
}

export class Unleash {
  private readonly _config: UnleashConfig;

  constructor(config: UnleashConfig) {
    this._config = config;
    if (!this._config.timeout) {
      this._config.timeout = 5000;
    }
  }

  get config(): UnleashConfig {
    return { ...this._config };
  }

  get unleashEndpoint() {
    return `${this._config.proxyUrl}/?appName=${this._config.appName}&environment=${this._config.environment}`;
  }

  fetch(): Promise<{ toggles: UnleashToggle[] }> {
    return axios
      .get<{ toggles: UnleashToggle[] }>(this.unleashEndpoint, {
        timeout: this._config.timeout,
        headers: {
          Authorization: this._config.clientKey
        }
      })
      .then((response) => response.data)
      .catch(() => {
        console.error('Error fetching toggles. No toggles enabled.');
        return { toggles: [] };
      });
  }
}

const unleashConfig = {
  proxyUrl: UNLEASH_API_BASE_URL,
  clientKey: `${process.env.REACT_APP_UNLEASH_TOKEN}`,
  appName: `${process.env.REACT_APP_UNLEASH_APP_NAME}`,
  environment: `${process.env.REACT_APP_UNLEASH_ENV}`,
  refreshInterval: 10_000_000_000
};
export default new Unleash(unleashConfig);
