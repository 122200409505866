/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './LeverandoerCard.module.scss';
import List from 'Components/UI/List/List';
import { NewLeverandoer, VirksomhetType } from 'models';
import Tag from 'Components/UI/Tag/Tag';
import Icon from 'Components/Icon/Icon';

export interface LeverandoerCardProps {
  leverandoer: NewLeverandoer;
}

export default function LeverandoerCard({ leverandoer }: LeverandoerCardProps) {
  const { t } = useTranslation('translation');

  //TODO: Hardkodet logo som nå hentes fra session, fra databasen senere
  // const logo =
  //   window.sessionStorage.getItem('logo') ||
  //   'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTUevxNyoogFmX1mEz29yjOkc5cq-4sq2Rqhw&usqp=CAU';
  // const Logo = () => (
  //   <div>
  //     <img src={logo} alt={t('logo')} />
  //   </div>
  // );

  const LeverandoerCardHeader = () => (
    <List orientation={'vertical'} spacing={'between'} gap={'sm'}>
      <h3>{leverandoer && leverandoer.navn}</h3>
      <div className={css.kategori_wrapper}>
        {leverandoer?.kategorier.map((kategori) => (
          <div className={css.LeverandorCard__kategori} key={kategori.id}>
            <p>{kategori.navn}</p>
          </div>
        ))}
      </div>
    </List>
  );

  // const CardMain = () => (
  //   <main>
  //     <p>{leverandoer?.beskrivelse}</p>
  //   </main>
  // );

  const LeverandoerCardContactInfo = () => (
    <List orientation={'horizontal'}>
      <div className={css.LeverandorCard__numberWrap}>
        {leverandoer?.kontaktTelefonStat && (
          <>
            <Tag text={t('stat')} />
            <p>{leverandoer?.kontaktTelefonStat}</p>
          </>
        )}
        {leverandoer?.kontaktTelefonKommune && (
          <>
            <Tag text={t('kommune')} />
            <p>{leverandoer?.kontaktTelefonKommune}</p>
          </>
        )}
      </div>
      <div className={css.more_button}>
        {t('mer')}
        <Icon symbol={'arrow-right'} tabIndex={-1} />
      </div>
    </List>
  );

  const getEncodedName = (name: string) => name.replace('/', '_');

  const Card = () => (
    <Link
      to={`/leverandøroversikt/${getEncodedName(leverandoer?.navn)}?id=${
        leverandoer?.id
      }&type=${VirksomhetType.LEVERANDOER}`}
      className={css.LeverandorCard}
    >
      <LeverandoerCardHeader />
      {/* <CardMain /> */}
      <LeverandoerCardContactInfo />
    </Link>
  );

  return <Card />;
}
