import React, { useState } from 'react';

import { ProcessMenu } from 'Components/ProcessMenu';
import { Loading } from 'Components/Loading';
import { Process as ProcessComponent, Step } from 'Components/Process';
import { Process } from 'shared/api/drupal';
import { useCmsById } from 'store/CmsQuery';
import css from './CmsNode.module.scss';
import { useFeatureToggle } from '../shared/feature-toggle';
import { Features } from '../shared-constants';

export const Skyreisen = <T extends Process>({ cmsNode }: { cmsNode: T }) => {
  const { isEnabled } = useFeatureToggle();
  const {
    cmsNode: processRoot,
    isLoading: isLoadingRoot
  } = useCmsById<Process>('process', cmsNode?.process.processId);
  const [activeRootUuid, setActiveRootUuid] = useState<string>(cmsNode.uuid);

  const steps: Step[] =
    processRoot && processRoot.steps
      ? processRoot.steps.map((s) => ({
          cmsRef: s,
          baseUrl: '/veiledning/skyreisen/',
          tooltip: ''
        }))
      : [];
  const activeStep =
    cmsNode && processRoot
      ? processRoot.steps.findIndex((s) => s === activeRootUuid)
      : -1;

  if (isLoadingRoot) {
    return (
      <section className={css.skyreise}>
        <Loading loading={isLoadingRoot} />
      </section>
    );
  }

  if (cmsNode && processRoot) {
    return (
      <>
        <section className={css.skyreise}>
          <ProcessComponent steps={steps} activeStep={activeStep} />
        </section>
        {isEnabled(Features.ProcessMenu) && (
          <ProcessMenu
            cmsNode={cmsNode}
            setActiveRootUuid={setActiveRootUuid}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};
