import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './Card.module.scss';
import Icon from 'Components/Icon/Icon';

type CardLayoutType = 'default' | 'horizontal' | 'centered';
type CardThemeType =
  | 'default'
  | 'primary'
  | 'primarydark'
  | 'secondary'
  | 'tertiary'
  | 'blank';
type CardOverrideType = 'tjenestekategori' | 'avtale' | 'header' | 'feedback';

export interface CardProps {
  layout?: CardLayoutType;
  theme?: CardThemeType;
  type?: CardOverrideType;
  compact?: boolean;
  meta?: any;
  tittel?: string;
  innhold?: string;
  bunntekst?: any;
  handling?: any;
  illustrasjon?: any;
  link?: string;
  children?: ReactNode;
  hidden?: boolean;
}

export interface CardHandlingProps {
  url: string;
  text?: string;
  label?: string;
  reverse?: boolean;
}

interface CardActionProps extends CardHandlingProps {
  type: 'button' | 'link';
}

function CardHeader({ tittel }: CardProps): ReactElement | null {
  if (!tittel) {
    return null;
  }
  return (
    <header className={css.Card__tittel}>
      <h1>{tittel}</h1>
    </header>
  );
}

function CardMain({ innhold, children }: CardProps): ReactElement {
  if (children) {
    return <main className={css.Card__innhold}>{children}</main>;
  }
  if (innhold) {
    return (
      <main className={css.Card__innhold}>
        <p>{innhold}</p>
      </main>
    );
  }
  return <></>;
}

function CardFooter({ bunntekst }: CardProps): ReactElement | null {
  if (!bunntekst) {
    return null;
  }
  return <footer className={css.Card__bunntekst}>{bunntekst}</footer>;
}

function CardHandling({ handling }: CardProps): ReactElement | null {
  if (!handling) {
    return null;
  }
  return <div className={css.Card__handling}>{handling}</div>;
}

function CardIllustrasjon({ illustrasjon }: CardProps): ReactElement | null {
  if (!illustrasjon) {
    return null;
  }
  return <div className={css.Card__illustrasjon}>{illustrasjon}</div>;
}

export function CardHandlingLink({
  url,
  text,
  label,
  reverse
}: CardHandlingProps): ReactElement {
  return (
    <CardAction
      url={url}
      text={text}
      label={label}
      reverse={reverse}
      type={'link'}
    />
  );
}

export function CardHandlingButton({
  url,
  text,
  label
}: CardHandlingProps): ReactElement {
  return <CardAction url={url} text={text} label={label} type={'button'} />;
}

function CardAction({ url, text, label, type, reverse }: CardActionProps) {
  const { t } = useTranslation('translation');
  const history = useHistory();

  text = typeof text === 'string' ? text : t('forside_se_mer');
  label = typeof label === 'string' ? label : t('forside_se_mer');

  return type === 'link' ? (
    <Link to={url} aria-label={label}>
      {reverse && (
        <>
          <Icon symbol={'arrow-left'} tabIndex={-1} />
          <p>{text}</p>
        </>
      )}
      {!reverse && (
        <>
          {text}
          <Icon symbol={'arrow-right'} tabIndex={-1} />
        </>
      )}
    </Link>
  ) : (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(url);
      }}
      aria-label={label}
    >
      {text}
    </button>
  );
}

export default function Card({
  layout = 'default',
  theme = 'default',
  type,
  compact,
  meta,
  tittel,
  innhold,
  bunntekst,
  handling,
  illustrasjon,
  link,
  children
}: CardProps): ReactElement {
  // const history = useHistory();
  const cssClasses = [css.Card];

  if (link) {
    cssClasses.push(css.Link);
  }

  if (compact) {
    cssClasses.push(css.Compact);
  }

  if (type) {
    cssClasses.push(css[`${type[0].toUpperCase()}${type.slice(1)}`]);
  }

  return (
    <article
      className={classNames(cssClasses)}
      data-href={link}
      data-layout={layout}
      data-type={type}
      data-theme={theme}
    >
      {meta && <div className={css.Card__meta}>{meta}</div>}
      {tittel && <CardHeader tittel={tittel} />}
      {(innhold || children) && (
        <CardMain innhold={innhold}>{children}</CardMain>
      )}
      {bunntekst && <CardFooter bunntekst={bunntekst} />}
      {handling && <CardHandling handling={handling} />}
      {illustrasjon && <CardIllustrasjon illustrasjon={illustrasjon} />}
    </article>
  );
}
