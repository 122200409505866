import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Icon/Icon';
import PaginationButton from './PaginationButton';
import css from './Pagination.module.scss';

interface Props {
  pageCount: number;
  pageIndex: number;
  setPageIndex: Dispatch<SetStateAction<number>>;
}

export default function Pagination(props: Props): React.ReactElement | null {
  const { t } = useTranslation('translation');
  const { pageCount, pageIndex, setPageIndex } = props;

  const handleClick = (nextIndex: number): void => {
    if (pageIndex === nextIndex) {
      // Avoid re-render
      return;
    }

    if (nextIndex < 0 || nextIndex > pageCount - 1) {
      throw new Error(
        `Array index out of bounds: index: ${nextIndex}, size: ${pageCount - 1}`
      );
    }

    setPageIndex(nextIndex);
  };

  // Render blank if no pages
  if (!pageCount) {
    return null;
  }

  return (
    <div className={css.Pagination}>
      <nav aria-labelledby="pagination-title">
        <h2 id="pagination-title">Resultatsider</h2>

        <div>
          <PaginationButton
            disabled={pageIndex <= 0}
            label={t('soek_forrige_side')}
            onClick={() => handleClick(pageIndex - 1)}
          >
            <Icon symbol="arrow-left" tabIndex={-1} />
          </PaginationButton>

          {[...Array(pageCount)].map((_, index) => (
            <PaginationButton
              key={index}
              label={t('soek_index_side', { index: index + 1 })}
              selected={pageIndex === index}
              onClick={() => handleClick(index)}
            >
              {index + 1}
            </PaginationButton>
          ))}

          <PaginationButton
            disabled={pageIndex >= pageCount - 1}
            label={t('soek_neste_side')}
            onClick={() => handleClick(pageIndex + 1)}
          >
            <Icon symbol="arrow-right" tabIndex={-1} />
          </PaginationButton>
        </div>
      </nav>
    </div>
  );
}
