import React, { ReactElement } from 'react';

import Brand from './Brand';
import MenuBar from './MenuBar';
import css from './Header.module.scss';

export default function Header(): ReactElement {
  return (
    <header className={css.Header}>
      <div className={css.Wrapper}>
        <Brand />
        <MenuBar />
      </div>
    </header>
  );
}
