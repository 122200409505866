import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'Components/Icon/Icon';
import { CombinedAppState, DFO } from 'store';
import css from './AlertBox.module.scss';

export default function AlertBox() {
  const message = useSelector(
    (appState: CombinedAppState) => appState.global.message
  );
  const dispatch = useDispatch();
  const isMountedRef = useRef(false);
  const [state, setState] = useState({
    melding: '',
    presented: false
  });
  const { t } = useTranslation('translation');

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });

  useEffect(() => {
    if (message) {
      let melding = message;
      if (melding.startsWith('error_')) {
        melding = t(melding);
      }

      setState((state) => {
        return {
          ...state,
          melding,
          presented: true
        };
      });
    } else {
      setState((state) => {
        return {
          ...state,
          presented: false
        };
      });
    }
  }, [message, t]);

  const lukk = (): void => {
    dispatch(DFO.closeError());
  };

  return (
    <div
      className={classNames(css.AlertBox, state.presented ? css.presented : '')}
      onDoubleClick={lukk}
    >
      <div>
        <Icon symbol={'warning'} tabIndex={-1} />
      </div>
      <div
        className={css.message}
        dangerouslySetInnerHTML={{ __html: state.melding }}
      />
      <div>
        <button className={css.close} onClick={lukk}>
          <Icon symbol={'close'} tabIndex={-1} />
        </button>
      </div>
    </div>
  );
}
