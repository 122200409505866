import React, { ReactElement, ReactNode } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import List from 'Components/UI/List/List';
import Icon from 'Components/Icon/Icon';

export interface ConfirmDialogProps {
  isOpen?: boolean;
  message?: string;
  children?: ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  cancelText?: string;
  confirmText?: string;
}

export default function ConfirmDialog({
  isOpen = true,
  message,
  children,
  onCancel,
  onConfirm,
  cancelText,
  confirmText
}: ConfirmDialogProps): ReactElement {
  const { t } = useTranslation('translation');

  const handleCancel = (): void => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = (): void => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleCancel}
      appElement={document.body}
      className="MP-Modal__Modal MP-Modal__Modal__Confirmation"
      overlayClassName="MP-Modal__Overlay"
    >
      <button
        onClick={handleCancel}
        className="MP-Modal__Close"
        aria-label={t('confirm_dialog_close')}
      >
        <Icon symbol={'close'} tabIndex={-1} />
      </button>
      {message && <section>{message}</section>}
      {children}
      <List orientation={'horizontal'} alignment={'start'}>
        <button onClick={handleConfirm}>
          {confirmText || t('confirm_dialog_confirm')}
        </button>
        <button className="link" onClick={handleCancel}>
          {cancelText || t('confirm_dialog_cancel')}
        </button>
      </List>
    </ReactModal>
  );
}
