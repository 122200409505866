import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import css from './RegistrerTypeVirksomhet.module.scss';
import { VirksomhetType } from 'models';

export default function RegistrerTypeVirksomhet(): ReactElement {
  const { t } = useTranslation('translation');
  const [aktorType, setAktorType] = useState<VirksomhetType>(
    VirksomhetType.LEVERANDOER
  );
  const history = useHistory();

  const handleAktorTypeChange = (type: VirksomhetType) => {
    setAktorType(type);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    history.push(`/registrer?type=${aktorType}`);
  };

  return (
    <div className={css.RegistrerTypeVirksomhet}>
      <h1>{t('typeAktorTittel') + '?'} </h1>
      <form className={css.form} onSubmit={formSubmit}>
        <label>
          <input
            type="radio"
            value="leverandør"
            name={t('leverandør')}
            onChange={() => handleAktorTypeChange(VirksomhetType.LEVERANDOER)}
            checked={aktorType == VirksomhetType.LEVERANDOER}
          />
          {t('leverandør')}
        </label>
        <label>
          <input
            type="radio"
            value="virksomhet"
            name={t('offentligVirksomhet')}
            onChange={() => handleAktorTypeChange(VirksomhetType.OPPDRAGSGIVER)}
            checked={aktorType == VirksomhetType.OPPDRAGSGIVER}
          />
          {t('offentligVirksomhet')}
        </label>
        <button type="submit">{t('rt_modal_neste_steg')}</button>
      </form>
    </div>
  );
}
