import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { Kategori } from 'models';
import http, { buildUrl } from '../http-common';
import { KATEGORIER_URL } from 'shared-constants';
import { DFO, store } from 'store';

export function useKategorier() {
  async function getKategorier() {
    return http
      .get(buildUrl([KATEGORIER_URL]))
      .then((response: AxiosResponse<Kategori[]>) => response.data);
  }

  const query = useQuery<Kategori[]>('kategorier', getKategorier, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    onError: (error) => {
      store.dispatch(DFO.error(error, true));
    }
  });

  return { data: query.data || [], isLoading: query.isLoading };
}
