import {
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient
} from 'react-query';
import { AxiosResponse } from 'axios';
import { MutateOptions } from 'react-query/types/core/types';
import { useEffect, useState } from 'react';

import { Tjeneste, TjenesteRequest, TjenesteUpdate } from 'models';
import { DFO, store } from 'store';
import http, { buildUrl } from '../http-common';
import {
  BILDE_URL,
  TJENESTE_PUBLISER_URL,
  TJENESTER_URL,
  VIRKSOMHETER_URL
} from 'shared-constants';
import {
  authorizationHeaderFromToken,
  useAuthentication
} from 'shared/authentication';

type FetchTjenesteError = {
  code: number;
  message: string;
};

export const tjenesteQueryKeys = {
  root: () => ['tjenester'],
  byId: (id?: string) => [...tjenesteQueryKeys.root(), id],
  byVirksomhetId: (virksomhetId?: string) => [
    ...tjenesteQueryKeys.root(),
    virksomhetId
  ],
  byIdWithinVirksomhet: (virksomhetId?: string, tjenesteId?: string) => [
    ...tjenesteQueryKeys.byId(tjenesteId),
    virksomhetId
  ]
};

const getUrl = (virksomhetId: string | null, tjenesteId?: string) => {
  if (virksomhetId === null) {
    return buildUrl([VIRKSOMHETER_URL]);
  }
  return tjenesteId === undefined
    ? buildUrl([VIRKSOMHETER_URL, virksomhetId, TJENESTER_URL])
    : buildUrl([VIRKSOMHETER_URL, virksomhetId, TJENESTER_URL, tjenesteId]);
};

const useCachedTjeneste = (tjenesteId: string) => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching(tjenesteQueryKeys.byId(tjenesteId));
  const [cachedTjeneste, setCachedTjeneste] = useState<Tjeneste | undefined>();

  useEffect(() => {
    if (isFetching === 0) {
      setCachedTjeneste(
        queryClient.getQueryData<Tjeneste>(tjenesteQueryKeys.byId(tjenesteId))
      );
    }
  }, [queryClient, isFetching, tjenesteId]);
  return { tjeneste: cachedTjeneste };
};

const useGetTjenesteQuery = (
  tjenesteId: string,
  byVirksomhet?: {
    id: string;
    token?: string;
  }
) => {
  const url =
    byVirksomhet === undefined || byVirksomhet.id.length < 1
      ? buildUrl([TJENESTER_URL, tjenesteId])
      : getUrl(byVirksomhet.id, tjenesteId);

  const headers = authorizationHeaderFromToken(byVirksomhet?.token);

  async function getTjeneste() {
    return http
      .get(url, { headers })
      .then((response: AxiosResponse<Tjeneste>) => response.data)
      .catch((e) => {
        throw { code: e.response.status, message: e.response.data ?? '' };
      });
  }

  const isQueryEnabled = () => {
    if (byVirksomhet === undefined) {
      return tjenesteId.length > 0;
    }
    return tjenesteId.length > 0 && byVirksomhet.id.length > 0;
  };

  return useQuery<Tjeneste, FetchTjenesteError>(
    tjenesteQueryKeys.byId(tjenesteId),
    getTjeneste,
    {
      enabled: isQueryEnabled(),
      retry: (count, err) => err.code !== 404,
      onError: (err) => {
        if (err.code !== 404) {
          store.dispatch(DFO.error(err.message, true));
        }
      }
    }
  );
};

function useTjeneste(tjenesteId: string) {
  return useGetTjenesteQuery(tjenesteId);
}

function useTjenesteByVirksomhet(virksomhetId: string, tjenesteId: string) {
  const { context } = useAuthentication();

  return useGetTjenesteQuery(tjenesteId, {
    id: virksomhetId,
    token: context.token
  });
}

function useTjenesterByVirksomhet(virksomhetId: string) {
  const { context } = useAuthentication();

  async function getTjenesterWithinVirksomhet() {
    return http
      .get(getUrl(virksomhetId), {
        headers: authorizationHeaderFromToken(context.token)
      })
      .then((response: AxiosResponse<Tjeneste[]>) => response.data);
  }

  return useQuery<Tjeneste[]>(
    [...tjenesteQueryKeys.byVirksomhetId(virksomhetId)],
    getTjenesterWithinVirksomhet,
    {
      onError: (error) => {
        store.dispatch(DFO.error(error, true));
      }
    }
  );
}

function useCreateTjeneste() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(postTjeneste);

  async function postTjeneste(payload: TjenesteRequest) {
    return http
      .post(getUrl(payload.produsentId), payload, {
        headers: authorizationHeaderFromToken(context.token)
      })
      .then((result: AxiosResponse<Tjeneste>) => result.data);
  }

  function create(
    payload: TjenesteRequest,
    options?: MutateOptions<Tjeneste, unknown, TjenesteRequest>
  ) {
    mutate(payload, options);
  }

  return { create, isLoading };
}

function useUpdateTjeneste() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(putTjeneste);

  async function putTjeneste(tjeneste: TjenesteUpdate) {
    return http
      .put(getUrl(tjeneste.produsentId, tjeneste.id), tjeneste, {
        headers: authorizationHeaderFromToken(context.token)
      })
      .then((result: AxiosResponse<Tjeneste>) => result.data);
  }

  function update(
    payload: TjenesteUpdate,
    options?: MutateOptions<Tjeneste, unknown, TjenesteUpdate>
  ) {
    mutate(payload, options);
  }

  return { update, isLoading };
}

type PostBildePayload = {
  produsentId: string;
  tjenesteId: string;
  files: File[];
};

function useUpdateTjenestebilde() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(postTjenesteBilde);

  async function postTjenesteBilde(payload: PostBildePayload) {
    const formData = new FormData();

    payload.files.forEach((file) => {
      formData.append('file', file);
      formData.append('fileName', file.name);
    });

    return http
      .post(
        buildUrl([getUrl(payload.produsentId, payload.tjenesteId), BILDE_URL]),
        formData,
        {
          headers: authorizationHeaderFromToken(context.token)
        }
      )
      .then((result: AxiosResponse<Tjeneste>) => result.data);
  }

  function updateBilde(
    produsentId: string,
    tjenesteId: string,
    files: File[],
    options?: MutateOptions<Tjeneste, unknown, PostBildePayload>
  ) {
    mutate({ produsentId, tjenesteId, files }, options);
  }

  return { updateBilde, isLoading };
}

type PublishTjenestePayload = {
  produsentId: string;
  tjenesteId: string;
  publishDate: Date;
};

function usePublishTjeneste() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(publish);

  async function publish(payload: PublishTjenestePayload) {
    return http
      .post(
        buildUrl([
          getUrl(payload.produsentId, payload.tjenesteId),
          TJENESTE_PUBLISER_URL
        ]),
        { publisert: payload.publishDate },
        {
          headers: authorizationHeaderFromToken(context.token)
        }
      )
      .then((result: AxiosResponse<Tjeneste>) => result.data);
  }

  function dispatchMutate(
    produsentId: string,
    tjenesteId: string,
    publishDate: Date,
    options?: MutateOptions<Tjeneste, unknown, PublishTjenestePayload>
  ) {
    mutate({ produsentId, tjenesteId, publishDate }, options);
  }

  return { publish: dispatchMutate, isLoading };
}

type UnpublishTjenestePayload = {
  produsentId: string;
  tjenesteId: string;
};

function useUnpublishTjeneste() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(unpublish);

  async function unpublish(payload: UnpublishTjenestePayload) {
    return http.delete(
      buildUrl([
        getUrl(payload.produsentId, payload.tjenesteId),
        TJENESTE_PUBLISER_URL
      ]),
      {
        headers: authorizationHeaderFromToken(context.token)
      }
    );
  }

  function dispatchMutate(
    produsentId: string,
    tjenesteId: string,
    options?: MutateOptions<unknown, unknown, UnpublishTjenestePayload>
  ) {
    mutate({ produsentId, tjenesteId }, options);
  }

  return { unpublish: dispatchMutate, isLoading };
}

export {
  useTjeneste,
  useCachedTjeneste,
  useTjenesteByVirksomhet,
  useTjenesterByVirksomhet,
  useCreateTjeneste,
  useUpdateTjeneste,
  useUpdateTjenestebilde,
  usePublishTjeneste,
  useUnpublishTjeneste
};
