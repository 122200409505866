import useScrollPosition from '@react-hook/window-scroll';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sanitizeHtml, toAnchorName } from 'shared/sanitize-html';
import Icon from 'Components/Icon/Icon';
import css from 'CmsNode/CmsNode.module.scss';

type TocItem = { text: string; id: string; ref: Element; active?: boolean };

export const TableOfContents = (props: { refs: Element[] }) => {
  const { t } = useTranslation('translation');
  const { refs } = props;
  const [items, setToc] = useState<TocItem[]>([]);
  const [activeItem, setActiveItem] = useState<number>(-1);
  const [isScrollEvent, setScrollEvent] = useState<boolean>(true);
  const scrollY = useScrollPosition();
  const prevScrollY = useRef(scrollY);
  const [scrollTimerRef, setScrollTimerRef] = useState<number | null>(null);
  const clearClickOnScrollStop = useCallback(() => {
    if (scrollTimerRef !== null) {
      clearTimeout(scrollTimerRef);
    }
    setScrollTimerRef(window.setTimeout(() => setScrollEvent(true), 200));
  }, [scrollTimerRef]);

  useEffect(() => {
    window.addEventListener('scroll', clearClickOnScrollStop);
    return () => window.removeEventListener('scroll', clearClickOnScrollStop);
  }, [clearClickOnScrollStop]);

  useEffect(() => {
    setToc(
      refs.map((ref) => {
        const text = sanitizeHtml(ref.textContent, true);
        return { text, id: toAnchorName(text), ref };
      })
    );
    setActiveItem(-1);
    setScrollEvent(true);
  }, [refs]);

  useEffect(() => {
    if (isScrollEvent && prevScrollY.current !== scrollY) {
      let nearest = -1;
      let currentMinDistance = Infinity;
      const viewportHeight = document.documentElement.clientHeight;
      items.forEach((item, i) => {
        const topRatio = item.ref.getBoundingClientRect().top / viewportHeight;
        const bottomRatio =
          item.ref.getBoundingClientRect().bottom / viewportHeight;
        const centerRatio = (topRatio + bottomRatio) / 2;
        const distance = Math.abs(centerRatio - 0.5);
        if (distance < currentMinDistance) {
          nearest = i;
          currentMinDistance = distance;
        }
      });
      setActiveItem(nearest);
    }
    prevScrollY.current = scrollY;
  }, [scrollY, items, isScrollEvent]);

  const onItemClick = (itemIndex: number) => {
    setActiveItem(itemIndex);
    setScrollEvent(false);
  };

  return (
    <>
      {items.length > 0 && (
        <section className={css.toc}>
          <div>
            <header>
              <h2>{t('innholdsfortegnelse')}</h2>
            </header>
            <ul>
              {items.map((header, i) => (
                <li key={i}>
                  {activeItem === i && <Icon symbol={'arrow-right'} />}
                  <a href={`#${header.id}`} onClick={() => onItemClick(i)}>
                    {header.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </section>
      )}
    </>
  );
};
