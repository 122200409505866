import { Virksomhet } from '../../models';

export const Roles = ['company_verified', 'company_admin', 'mp_admin'] as const;
type Role = typeof Roles[number];
type AccessToken = string;

type UserVirksomhet = Omit<Virksomhet, 'id'>;

export interface User {
  name: string;
  email: string;
  roles: Role[];
  virksomhet?: UserVirksomhet;
}

export interface AuthenticationContext {
  token?: AccessToken;
  user?: User;
}

export interface KeycloakProfile {
  name: string;
  email: string;
  company: Company;
  sub?: string;
  emailVerified?: boolean;
  preferredUsername?: string;
  givenName?: string;
  familyName?: string;
}

interface Company {
  name: string;
  orgNumber: string;
}
