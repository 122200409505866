import React, { useEffect, useState } from 'react';

import Icon from 'Components/Icon/Icon';
import css from './CloudSaveIndicator.module.scss';

interface CloudSaveIndicatorProps {
  isActive: boolean;
  activeLabel: string;
  staticLabel: string;
}

export default function CloudSaveIndicator(props: CloudSaveIndicatorProps) {
  const [state, setState] = useState({ isActive: true, touched: 0 });

  useEffect(() => {
    setState({
      ...state,
      isActive: props.isActive,
      touched: state.touched + 1
    });
    // eslint-disable-next-line
  }, [props.isActive]);

  const isTouched = (): boolean => {
    return state.touched > 1;
  };

  const renderCloudSaveIndicator = () => {
    if (!isTouched()) {
      return;
    }

    return (
      <>
        {!state.isActive && <Icon symbol={'cloud-check'} />}
        {state.isActive && <Icon symbol={'cloud-sync'} />}{' '}
        {state.isActive ? props.activeLabel : props.staticLabel}
      </>
    );
  };

  return (
    <div className={css.CloudSaveIndicator}>{renderCloudSaveIndicator()}</div>
  );
}
