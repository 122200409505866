import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import css from './MinProfil.module.scss';
import { useFormField } from 'shared/hooks/form-field';
import { useLogin } from 'shared/authentication';

function MinProfil(): ReactElement {
  // const history = useHistory();
  const { t } = useTranslation('translation');
  const { user } = useLogin();

  const { value: name } = useFormField(user?.name);
  // const { value: phoneNum, onChange: onPhoneNumChange } = useFormField(
  //   '00000000'
  // );
  const { value: email, onChange: onEmailChange } = useFormField(user?.email);

  // const register = (): void => {
  //   const brukerProfil = {
  //     navn: name,
  //     email: email
  //   };

  //   history.push('/dashboard/min-side');
  // };

  return (
    <div className={css.MinProfil}>
      <h1>{t('din_kontaktinformasjon')}</h1>
      <form>
        <label>
          {t('navn')}
          <input
            type="text"
            value={name}
            name={t('navn')}
            aria-label={t('navn')}
            disabled
          />
        </label>
        {/* <label>
          {t('telefon')}
          <input
            type="tel"
            placeholder={t('min_profil_telefon_placeholder')}
            name={t('telefon')}
            aria-label={t('telefon')}
            value={phoneNum}
            onChange={onPhoneNumChange}
          />
        </label> */}
        <label>
          {t('epost')}
          <input
            type="email"
            placeholder={t('min_profil_epost_placeholder')}
            name={t('epost')}
            aria-label={t('epost')}
            value={email}
            onChange={onEmailChange}
            disabled
          />
        </label>

        <span>{t('min_profil_personopplysninger')}</span>
        <br />
        <br />

        {/* <button
          type={'button'}
          onClick={() => register()}
          aria-label={t('registrer')}
        >
          {t('lagre')}
        </button> */}
      </form>
    </div>
  );
}

export default MinProfil;
