import { ChangeEvent, FocusEvent, useCallback, useState } from 'react';

export const useFormField = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [dirty, setDirty] = useState(false);
  const [visited, setVisited] = useState(false);
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value);
      setDirty(true);
    },
    []
  );
  const onBlur = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setVisited(true),
    []
  );
  return {
    value,
    isDirty: dirty,
    isVisited: visited,
    onChange,
    onBlur: onBlur
  };
};
