// Eslint disable because of https://github.com/date-fns/date-fns/issues/1756
// eslint-disable-next-line
import { format } from 'date-fns';
// eslint-disable-next-line
import { nb } from 'date-fns/locale';
import i18next from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'nb',
    keySeparator: false,
    interpolation: {
      format: (value, formatting) =>
        value instanceof Date
          ? format(value as Date, formatting as string, { locale: nb })
          : value
    }
  });
