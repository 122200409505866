export enum ActionType {
  ERROR = '[FEILMELDING] En feil har oppstått',
  ERROR_CLOSE = '[FEILMELDING] Lukk',
  LOADING = 'Laster'
}

export interface Action {
  type: ActionType;
  payload?: any;
  timestamp?: Date;
}

export class DFO {
  static error(error: any, stopLoadingFlag?: boolean): Action {
    return {
      type: ActionType.ERROR,
      payload: { error, stopLoadingFlag: stopLoadingFlag ?? false }
    };
  }

  static closeError(): Action {
    return {
      type: ActionType.ERROR_CLOSE
    };
  }

  static loading(): Action {
    return {
      type: ActionType.LOADING
    };
  }
}
