import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

class Initializer {
  private static initAppInsights() {
    if (process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING) {
      const browserHistory = createBrowserHistory();
      const reactPlugin = new ReactPlugin();
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: `${process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING}`,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
          }
        }
      });
      appInsights.loadAppInsights();
    } else {
      console.info(
        'Skipping Application Insights. REACT_APP_APP_INSIGHTS_CONNECTION_STRING not set.'
      );
    }
  }

  init() {
    Initializer.initAppInsights();
  }
}

export const initializer = new Initializer();
