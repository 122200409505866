import classNames from 'classnames';
import React from 'react';

import css from './Spinner.module.scss';

type SpinnerProps = {
  isActive: boolean;
  label?: string;
  orientation?: 'horizontal' | 'vertical';
};

export default function Spinner({
  isActive,
  label,
  orientation = 'horizontal'
}: SpinnerProps) {
  return (
    <div
      className={classNames(
        css.Spinner,
        isActive && css.isActive,
        css[orientation]
      )}
      data-testid="spinner"
    >
      {label}
    </div>
  );
}
