import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { FIRMAPROFIL_URL, TJENESTER_URL } from 'shared-constants';
import Toolbar, { ToolbarItem } from 'Components/Toolbar/Toolbar';
import Panel, {
  PanelStateColor,
  PanelStateIcon
} from 'Components/UI/Panel/Panel';
import List from 'Components/UI/List/List';
import CloudSaveIndicator from 'Components/CloudSaveIndicator/CloudSaveIndicator';
import { TjenesteUpdate } from 'models';
import { buildUrl } from 'shared/http-common';
import { DFO, store } from 'store';
import css from './RedigerTjenesteSide.module.scss';
import {
  tjenesteQueryKeys,
  useTjenesteByVirksomhet,
  useUpdateTjeneste,
  useUpdateTjenestebilde
} from 'shared/hooks/tjeneste-hooks';
import { useKategorier } from 'shared/hooks/kategori-hooks';
import TjenesteRegistrationForm from 'Components/TjenesteRegistrationForm';
import { useActiveVirksomhet } from '../store/VirksomhetContext';

export default function RedigerTjenesteSide() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('translation');
  const history = useHistory();
  const queryClient = useQueryClient();
  const { activeVirksomhet } = useActiveVirksomhet();
  const { data: kategorier } = useKategorier();
  const {
    data: tjeneste,
    isLoading: isLoadingTjeneste
  } = useTjenesteByVirksomhet(activeVirksomhet?.id || '', id);
  const {
    update: updateTjeneste,
    isLoading: isLoadingUpdate
  } = useUpdateTjeneste();
  const { updateBilde, isLoading: isLoadingBilde } = useUpdateTjenestebilde();
  const isAnyLoading = isLoadingTjeneste || isLoadingUpdate || isLoadingBilde;

  const onSuccess = () =>
    queryClient.invalidateQueries(tjenesteQueryKeys.byId(id));
  const onError = (error) => store.dispatch(DFO.error(error, true));

  const handleImageChange = (file: File): void => {
    if (activeVirksomhet && tjeneste) {
      updateBilde(activeVirksomhet.id, tjeneste.id as string, [file], {
        onSuccess,
        onError
      });
    }
  };

  const toolbarItems: ToolbarItem[] = [
    {
      label: t('rt_tjenesteprofil'),
      link: '/firmaprofil/tjenester/' + tjeneste?.id,
      symbol: 'arrow-left'
    }
  ];

  const tilbakeTilOversikten = (): void => {
    history.push(
      buildUrl([FIRMAPROFIL_URL, TJENESTER_URL, tjeneste?.id ?? ''])
    );
  };

  const panelStateIcon = (): PanelStateIcon =>
    tjeneste?.klarTilPublisering ? 'check' : 'warning';

  const panelStateColor = (): PanelStateColor =>
    tjeneste?.klarTilPublisering ? 'tertiary' : 'warning';

  return (
    <>
      {tjeneste && (
        <div className={css.RedigerTjenesteSide}>
          <div className={css.Wrapper}>
            <header className={css.header}>
              <p>{tjeneste.navn}</p>
              <List orientation={'horizontal'} alignment={'start'}>
                <h1>{t('rediger_tjeneste')}</h1>
                <CloudSaveIndicator
                  isActive={isAnyLoading}
                  activeLabel={t('lagrer')}
                  staticLabel={t('lagret')}
                />
              </List>
              <Toolbar toolbarItems={toolbarItems} />
            </header>
            <Panel
              title={'Grunnleggende tjenesteinformasjon'}
              panelStateIcon={panelStateIcon()}
              panelStateColor={panelStateColor()}
            >
              <TjenesteRegistrationForm
                initialTjeneste={{
                  ...tjeneste,
                  produsentId: tjeneste.produsent.id,
                  kategoriId: tjeneste.kategori.id
                }}
                hovedkategorier={kategorier}
                handleChange={(update) => {
                  updateTjeneste(update as TjenesteUpdate, {
                    onSuccess,
                    onError
                  });
                }}
                currentImage={tjeneste.bilde ?? undefined}
                handleImageChange={handleImageChange}
                isUploadingImage={isLoadingBilde}
              />
            </Panel>
            <List orientation={'horizontal'} alignment={'end'}>
              <button onClick={tilbakeTilOversikten}>{t('ferdig')}</button>
            </List>
          </div>
        </div>
      )}
    </>
  );
}
