import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useVirksomheter } from 'shared/hooks/virksomhet-hooks';
import { useActiveVirksomhet } from 'store/VirksomhetContext';

export const VirksomhetSelector = () => {
  const { t } = useTranslation('translation');
  const { data: virksomheter } = useVirksomheter();
  const { activeVirksomhet, setActiveVirksomhet } = useActiveVirksomhet();

  const getVirksomhet = (id: string) => virksomheter?.find((v) => v.id === id);

  const Options = () => (
    <>
      <option key={'0'}>{t('velg_virksomheten')}</option>
      {virksomheter?.map((virksomhet) => (
        <option value={virksomhet.id} key={virksomhet.id}>
          {virksomhet.navn}
        </option>
      ))}
    </>
  );

  return (
    <section>
      <label>
        {t('valgt_virksomhet')}
        <select
          aria-label={t('velg_virksomheten')}
          placeholder={t('velg_virksomheten')}
          value={activeVirksomhet?.id}
          onChange={(event: SyntheticEvent) => {
            const target = event.target as HTMLSelectElement;
            setActiveVirksomhet(getVirksomhet(target.value));
          }}
        >
          <Options />
        </select>
      </label>
    </section>
  );
};
