import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';

import http, { buildUrl } from 'shared/http-common';
import { OPPDRAGSGIVER_URL } from 'shared-constants';
import { Oppdragsgiver, VirksomhetType } from 'models';
import { DFO, store } from 'store';
import {
  authorizationHeaderFromToken,
  useAuthentication
} from 'shared/authentication';
import { useVirksomheter } from './virksomhet-hooks';

const oppdragsgiverQueryKeys = {
  root: () => ['oppdragsgiver'],
  byID: (leverandoerID?: string) => {
    const key = [...oppdragsgiverQueryKeys.root()];
    if (leverandoerID) {
      key.push(leverandoerID);
    }
    return key;
  }
};

type UpdateOppdragsgiver = {
  token: string | undefined;
  data: Oppdragsgiver;
};

function useOppdragsgiver(oppdragsgiverID: string | null, type: string | null) {
  async function getOppdragsgiver() {
    return http
      .get(buildUrl([OPPDRAGSGIVER_URL, oppdragsgiverID ?? '']))
      .then((response: AxiosResponse<Oppdragsgiver>) => response.data);
  }

  return useQuery<Oppdragsgiver>(
    oppdragsgiverQueryKeys.byID(oppdragsgiverID ?? ''),
    getOppdragsgiver,
    {
      onError: (error) => {
        store.dispatch(DFO.error(error, true));
      },
      enabled: !!oppdragsgiverID && type === VirksomhetType.OPPDRAGSGIVER
    }
  );
}

function useGetAllOppdragsgivere() {
  async function getOppdragsgiver() {
    return http
      .get(buildUrl([OPPDRAGSGIVER_URL]))
      .then((response: AxiosResponse<[Oppdragsgiver]>) => response.data);
  }

  return useQuery<Oppdragsgiver[]>(
    oppdragsgiverQueryKeys.byID(''),
    getOppdragsgiver,
    {
      onError: (error) => {
        store.dispatch(DFO.error(error, true));
      }
    }
  );
}

function useUpdateOppdragsgiver() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(putLeverandoerInfo);
  const { refetch } = useGetAllOppdragsgivere();
  const { refetch: refetchVirksomheter } = useVirksomheter();

  async function putLeverandoerInfo(params: UpdateOppdragsgiver) {
    return http
      .patch(buildUrl([OPPDRAGSGIVER_URL, params.data.id]), params.data, {
        headers: authorizationHeaderFromToken(context.token)
      })
      .then((result: AxiosResponse<Oppdragsgiver>) => result.data)
      .finally(() => {
        refetch();
        refetchVirksomheter();
      });
  }

  function update(token: string | undefined, data: Oppdragsgiver) {
    mutate({ token, data });
  }

  return { update, isLoading };
}

export { useOppdragsgiver, useGetAllOppdragsgivere, useUpdateOppdragsgiver };
