import React from 'react';

import css from './Loading.module.scss';
import Spinner from 'Components/Spinner/Spinner';

export const Loading = ({ loading }: { loading: boolean }) => (
  <div className={css.Loading}>
    <Spinner isActive={loading} />
  </div>
);
