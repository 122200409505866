import React from 'react';

import CmsNode from 'CmsNode/CmsNode';
import css from './ContentBox.module.scss';

type ContentBoxProps = {
  headerText?: string;
  cmsRef?: string;
};

export const ContentBox = ({ cmsRef, headerText }: ContentBoxProps) => (
  <section className={css.ContentBox} id={'veiledning'}>
    {headerText && (
      <header>
        <h1>{headerText}</h1>
      </header>
    )}
    {cmsRef && (
      <CmsNode
        type={'veiledning'}
        id={cmsRef}
        showTitle={false}
        showToc={false}
        showUpdatedDate={false}
      />
    )}
  </section>
);
