import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CmsContent } from 'CmsNode/CmsContent';
import { Loading } from 'Components/Loading';
import { NotFound } from 'Components/NotFound';
import { Article, isInstanceOfBody, NodeType } from 'shared/api/drupal';
import { sanitizeHtml } from 'shared/sanitize-html';
import { useCmsById } from 'store/CmsQuery';
import { usePageHeadContext } from 'store/PageHeadContext';

interface CmsNodeProps {
  id?: string;
  type: NodeType;
  showToc?: boolean; // default true
  showTitle?: boolean; // default true
  showLeadParagraph?: boolean; // default true
  showUpdatedDate?: boolean;
}

export default function CmsNode(props: CmsNodeProps): ReactElement {
  const { setPageTitle, setPageDescription } = usePageHeadContext();
  const params = useParams<{ id: string }>();
  const showToc = props.showToc === undefined ? true : props.showToc;
  const showTitle = props.showTitle === undefined ? true : props.showTitle;
  const showLeadParagraph =
    props.showLeadParagraph === undefined ? true : props.showLeadParagraph;
  const showUpdatedDate =
    props.showUpdatedDate === undefined ? true : props.showUpdatedDate;
  const { type } = props;
  const [id, setId] = useState<string | undefined>();
  const { cmsNode, isLoading, isError } = useCmsById(type, id);

  const getIntroText = (node: Article): string =>
    isInstanceOfBody(node.field_intro_text)
      ? node.field_intro_text.processed
      : node.field_intro_text;

  useEffect(() => {
    if (props.id) {
      setId(props.id);
    } else {
      const path = params.id.split('/');
      setId(path[path.length - 1]);
    }
  }, [props.id, params.id]);

  useEffect(() => {
    if (cmsNode) {
      setPageTitle(cmsNode.title);
      setPageDescription(sanitizeHtml(getIntroText(cmsNode), true));
    }
  }, [cmsNode, setPageTitle, setPageDescription]);

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }
  if (isError) {
    return <NotFound />;
  }
  return (
    <>
      {cmsNode && (
        <CmsContent
          cmsNode={cmsNode}
          showTitle={showTitle}
          showToc={showToc}
          showLeadParagraph={showLeadParagraph}
          showUpdatedDate={showUpdatedDate}
        />
      )}
    </>
  );
}
