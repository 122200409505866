import React from 'react';
import classNames from 'classnames';

import css from './Icon.module.scss';

interface IconProps {
  symbol: string;
  className?: string;
  status?: 'success' | 'neutral' | 'warning' | 'error';
  title?: string;
  onClick?: (event: any) => void;
  onBlur?: (event?: any) => void;
  tabIndex?: number;
}

export default function Icon({
  symbol,
  className,
  status,
  title,
  onClick,
  onBlur,
  tabIndex
}: IconProps) {
  const handleClick = (event: any): void => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleBlur = (event?: any): void => {
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <span
      className={classNames(
        css.Icon,
        'icon-' + symbol,
        className,
        status ? css[`${status}`] : null
      )}
      onBlur={(event) => handleBlur(event)}
      onClick={(event) => handleClick(event)}
      onKeyUp={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          handleClick(event);
        }
      }}
      tabIndex={tabIndex ?? 0}
      title={title ? title : ''}
    />
  );
}
