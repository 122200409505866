import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Icon/Icon';
import { ContentBox } from 'Components/Veileder/Components/ContentBox';
import css from 'Components/Veileder/Components/Report/Report.module.scss';
import {
  ReportRef,
  restart,
  useVeilederContext
} from 'Components/Veileder/context';

export const Report = () => {
  const { t } = useTranslation('veileder');
  const { veilederState, dispatch } = useVeilederContext();
  const [reports, setReports] = useState<ReportRef[]>([]);

  useEffect(() => {
    if (veilederState.lastAnswer?.type.value === 'YES') {
      setReports(veilederState.reports.ok);
    } else if (veilederState.lastAnswer?.type.value === 'NO') {
      setReports(veilederState.reports.fail);
    } else {
      setReports([]);
    }
  }, [veilederState.reports, veilederState.lastAnswer]);

  return (
    <div className={css.Report}>
      {veilederState.lastAnswer?.type.value === 'YES' && (
        <>
          <div className={classNames([css.status, css.ok])}>
            <Icon symbol={'like'} tabIndex={-1} />
            {t('report_ok')}
          </div>
          <ContentBox
            cmsRef={veilederState.lastAnswer?.type.cmsRef}
            headerText={t('report_summary')}
          />
        </>
      )}
      {veilederState.lastAnswer?.type.value === 'NO' && (
        <div className={classNames([css.status, css.fail])}>
          <Icon symbol={'warning'} tabIndex={-1} />
          {t('report_fail')}
        </div>
      )}
      {reports.map((r) => (
        <ContentBox
          key={r.headerKey}
          cmsRef={r.cmsRef}
          headerText={t(r.headerKey)}
        />
      ))}
      <button className={css.restart} onClick={() => restart(dispatch)}>
        <span>{t('nav_restart')}</span>
        <Icon symbol={'restart'} tabIndex={-1} />
      </button>
    </div>
  );
};
