import { Tjeneste, TjenesteSource } from '../models';
import { mockKategoriChild, mockSaasKategori } from './kategori-mocks';

export const testTjeneste: Tjeneste = {
  id: 'tjeneste-id-ix3-aize',
  navn: 'Child test-tjeneste',
  beskrivelse: 'En lenge beskrivelse til denne tjenesten',
  kortBeskrivelse: 'En kort beskrivelse til denne tjenesten',
  bilde: '/a-cloud-service.jpg',
  produsent: {
    id: '1',
    orgnr: 123456785,
    navn: 'Cloudy Clouds AS'
  },
  kategori: mockSaasKategori,
  url: 'www.test.as',
  source: TjenesteSource.Form,
  isGroup: false,
  parent: {
    id: 'tjeneste-id-ix1-aize',
    navn: 'Offentlig test-tjeneste',
    source: TjenesteSource.Form,
    isGroup: true,
    children: [
      {
        id: 'tjeneste-id-ix3-aize',
        navn: 'Child test-tjeneste',
        kortBeskrivelse: 'dette er test tjeneste',
        source: TjenesteSource.Form,
        isGroup: false
      } as Tjeneste
    ]
  } as Tjeneste
} as Tjeneste;

export const mockTjeneste: Tjeneste = {
  id: 'tjeneste-id-ix1-aize',
  navn: 'Offentlig test-tjeneste',
  source: TjenesteSource.Form,
  isGroup: true,
  beskrivelse: 'En lenge beskrivelse til denne tjenesten',
  kortBeskrivelse: 'En kort beskrivelse til denne tjenesten',
  bilde: '/a-cloud-service.jpg',
  produsent: {
    id: '1',
    orgnr: 123456785,
    navn: 'Cloudy Clouds AS'
  },
  kategori: mockSaasKategori,
  url: 'www.test.as',
  children: [
    {
      id: 'tjeneste-id-ix3-aize',
      navn: 'Child test-tjeneste',
      kortBeskrivelse: 'dette er test tjeneste',
      source: TjenesteSource.Form,
      isGroup: false,
      kategori: mockKategoriChild
    },
    {
      id: 'tjeneste-id-ix2-aize',
      navn: 'Child 2 test-tjeneste',
      kortBeskrivelse: 'dette er test tjeneste',
      source: TjenesteSource.Form,
      isGroup: false,
      kategori: mockKategoriChild
    }
  ] as Tjeneste[]
} as Tjeneste;
