export const BILDE_URL = 'bilde';
export const DATA_URL = 'data';
export const JIRA_ISSUE_COLLECTOR_URL =
  'https://dfo-no.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/o2joag/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=no-NO&collectorId=918111dd';
export const FIRMAPROFIL_URL = 'firmaprofil';
export const LEVERANDOERER_URL = 'leverandoerer';
export const OPPDRAGSGIVER_URL = 'oppdragsgivere';
export const REDIGER_URL = 'rediger';
export const SELGER_URL = 'selger';
export const KATEGORIER_URL = 'kategorier';
export const TJENESTER_URL = 'tjenester';
export const TJENESTE_PUBLISER_URL = 'publiser';
export const TJENESTER_IMPORT_URL = 'import';
export const TJENESTER_IMPORT_STATUS_URL = 'status';
export const VIRKSOMHETER_URL = 'virksomheter';
export const VIRKSOMHETER_FROM_ORG_NR_URL = 'virksomheter?orgnr=';
export const AVTALER_URL = 'avtaler';
export const NYHETER_URL = 'nyheter';
export const TJENESTEREGISTER_API_BASE_URL = '/api/mp';
export const UNLEASH_API_BASE_URL = '/api/features';
export const CMS_API_BASE_URL = '/api/cms';
export const EXCEL_MAL_URL = '/api/mp/templates/Import_v01.xlsx';

export enum Features {
  Tjenesteregister = 'mp-tjenesteregister',
  IssueCollector = 'mp-issue-collector',
  Veileder = 'mp-schrems-veileder',
  ProcessMenu = 'mp-process-menu',
  LoggInn = 'mp-logginn',
  TjenesteregisterSkjema = 'mp-tjenesteregister-skjema',
  ExcelImport = 'mp-excel-import',
  VeilederEtterSchrems = 'mp-veiledning-etter-schrems-ii'
}

export const DrupalArticleIds = {
  Firmaprofil: process.env.REACT_APP_DRUPAL_FIRMAPROFIL || '',
  Skyreisen: process.env.REACT_APP_DRUPAL_SKYREISEN_NID || '',
  TilslutningAvtalene:
    process.env.REACT_APP_DRUPAL_TILSLUTNING_AVTALER_NID || '',
  OmMarkedsplassen: process.env.REACT_APP_DRUPAL_OM_MARKEDSPLASSEN_NID || '',
  VeiledningForside: process.env.REACT_APP_DRUPAL_VEILEDNING_FORSIDE_NID || '',
  ErDuLeverandoer: process.env.REACT_APP_DRUPAL_ER_DU_LEVERANDOER_NID || '',
  AnskaffeSkytjenester:
    process.env.REACT_APP_DRUPAL_ANSKAFFE_SKYTJENESTER_NID || '',
  HvorforVelgeSky: process.env.REACT_APP_DRUPAL_HVORFOR_VELGE_SKY_NID || '',
  SpesialiserteSamhandlingsloesninger:
    process.env.REACT_APP_DRUPAL_SPESIALISERTE_SAMHANDLINGS_LOESNINGER_NID ||
    '',
  KonsulenttjenesterSky:
    process.env.REACT_APP_DRUPAL_KONSULENT_TJENESTER_SKY_NID || '',
  Begrepsliste: process.env.REACT_APP_DRUPAL_BEGREPSLISTE_NID || '',
  SkyreisenAnskaffeSkytjenester:
    process.env.REACT_APP_DRUPAL_SKYREISEN_ANSKAFFE_SKYTJENESTER_NID || '',
  SkyreisenAnskaffeSkytjenesterVurdereRisiko:
    process.env
      .REACT_APP_DRUPAL_SKYREISEN_ANSKAFFE_SKYTJENESTER_VURDERE_RISIKO_NID ||
    '',
  ImporterTjenester: process.env.REACT_APP_DRUPAL_IMPORTER_TJENESTER_NID || '',
  InvitasjonNytteverdiAvRisikoscore:
    process.env.REACT_APP_DRUPAL_INVITASJON_NYTTEVERDI_AV_RISIKOSCORE_NID || '',
  VeiledningKoordineringsarbeidet:
    process.env.REACT_APP_DRUPAL_VEILEDNING_KOORDINERINGSARBEIDET_NID || '',
  VeiledningForBrukAvSkyEtterSchremsII:
    process.env
      .REACT_APP_DRUPAL_VEILEDNING_FOR_BRUK_AV_SKY_ETTER_SCHREMS_II_FORSIDE_NID ||
    '',
  PersonvernRisiko:
    process.env.REACT_APP_DRUPAL_PERSONVERNRISIKO_FOR_INNHENTING_NID || ''
};

export const DrupalVeiledningForBrukAvSkyEtterSchremsIIPath =
  'veiledning-etter-schrems-ii';

export const DrupalVeiledningForBrukAvSkyEtterSchremsIIIds = {
  OppsummeringAvGruppensVurdering:
    process.env.REACT_APP_DRUPAL_OPPSUMMERING_AV_GRUPPENS_VURDERINGER_NID || '',
  RaadForAAOverholde:
    process.env.REACT_APP_DRUPAL_RAAD_FOR_AA_OVERHOLDE_NID || '',
  BetyrSkytjenestersForbehold:
    process.env.REACT_APP_DRUPAL_BETYR_SKYTJENESTERS_FORBEHOLD_NID || '',
  Instruksjonsmyndighet:
    process.env.REACT_APP_DRUPAL_INSTRUKSJONSMYNDIGHET_NID || ''
};

export const DrupalVeiledningPersonvernrisikoforEtterretningPath =
  'personvernrisiko-for-etterretning';

export const DrupalVeiledningPersonverkrisikoforEtterretningIds = {
  PersonvernForOrdningensSentraleBestemmelser:
    process.env
      .REACT_APP_DRUPAL_PERSONVERNFORORDNINGENS_SENTRALE_BESTEMMELSER_NID || '',
  HvaErEnOverforing:
    process.env.REACT_APP_DRUPAL_HVA_ER_EN_OVERFOERING_NID || '',
  VernEtterEmkOGGrunnlov:
    process.env.REACT_APP_DRUPAL_VERN_ETTER_EMK_OG_GRUNNLOVEN_NID || '',
  HvaSierSchremsII: process.env.REACT_APP_DRUPAL_HVA_SIER_SCHREMS_II_NID || '',
  SkytjenesterMedRegionIeUeEoes:
    process.env.REACT_APP_DRUPAL_SKYTJENESTER_MED_REGION_I_EU_EOES_NID || '',
  OppsummeringAvRelevanteRettSkildere:
    process.env.REACT_APP_DRUPAL_OPPSUMMERING_AV_RELEVANTE_RETTSKILDER_NID ||
    '',
  HjelpTilAaVurderePersonvernrisikoen:
    process.env.REACT_APP_DRUPAL_HJELP_TIL_AA_VURDERE_PERSONVERNRISIKOEN_NID ||
    '',
  AlleSkytjenesterErUnderlagtEtterretning:
    process.env
      .REACT_APP_DRUPAL_ALLE_SKYTJENESTER_ER_UNDERLAGT_ETTERRETNING_NID || '',
  SpoersmaalOgSvarOmAmerikanskEtterforskning:
    process.env
      .REACT_APP_DRUPAL_SPOERSMAAL_OG_SVAR_OM_AMERIKANSK_ETTERFORSKNING_NID ||
    '',
  StatistikkFraSkytjenesteleverandoeren:
    process.env.REACT_APP_DRUPAL_STATISTIKK_FRA_SKYTJENESTELEVERANDOEREN_NID ||
    '',
  ArbeidsrelatertePersonopplysninger:
    process.env.REACT_APP_DRUPAL_ARBEIDSRELATERTE_PERSONOPPLYSNINGER_NID || '',
  OverforingVedSupporttjenester:
    process.env.REACT_APP_DRUPAL_OVERFOERING_VED_SUPPORTTJENESTER_NID || ''
};

export enum RootKategorier {
  UtviklingOgDrift = 46,
  Programvare = 47,
  Konsulenttjenester = 48
}
