import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Article,
  isInstanceOfBody,
  sortArticlesByPublishedTime
} from '../../shared/api/drupal';
import Card, { CardHandlingLink } from '../Card/Card';
import { Loading } from '../Loading';
import { sanitizeHtml } from '../../shared/sanitize-html';
import { buildUrl } from '../../shared/http-common';
import { NYHETER_URL } from '../../shared-constants';

interface NyheterListProps {
  nyheter: Article[];
  isLoading: boolean;
}

export default function NyheterSide({
  nyheter,
  isLoading
}: NyheterListProps): ReactElement {
  const { t } = useTranslation('translation');

  const nyheterDate = (date: Date) => {
    return t('dato_lang', {
      dato: new Date(date)
    });
  };

  const nyhetHandling = (nyhet: Article) => {
    return (
      <CardHandlingLink
        url={buildUrl([NYHETER_URL, `${nyhet.id}`])}
        label={nyhet.title}
      />
    );
  };

  const nyheterList = () => {
    return sortArticlesByPublishedTime(nyheter).map((nyhet) => {
      const getIntroText = (): string =>
        isInstanceOfBody(nyhet.field_intro_text)
          ? nyhet.field_intro_text.value
          : nyhet.field_intro_text;

      const nyhetMeta = () => {
        return sanitizeHtml(getIntroText());
      };

      return (
        <Card
          key={nyhet.id}
          tittel={nyhetMeta()}
          meta={nyhet.title}
          bunntekst={nyheterDate(nyhet.published_time)}
          link={buildUrl([NYHETER_URL, `${nyhet.id}`])}
          handling={nyhetHandling(nyhet)}
        />
      );
    });
  };

  const Nyheter = () => {
    return (
      <>
        {!isLoading && nyheter && nyheter.length > 0 && nyheterList()}
        {!isLoading && nyheter && nyheter.length <= 0 && (
          <p>{t('nyhter_tomt_liste')}</p>
        )}
      </>
    );
  };

  return (
    <>
      {isLoading && <Loading loading={isLoading} />}
      {Nyheter()}
    </>
  );
}
