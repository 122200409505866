import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { isInternalLink } from 'shared/sanitize-html';
import { Article } from 'shared/api/drupal';

type PartProps<T extends Article> = {
  cmsNode: T;
  className?: string;
  children: JSX.Element;
};

export const Part = <T extends Article>({
  cmsNode,
  className,
  children
}: PartProps<T>) => {
  const ref = useRef<HTMLElement>(null);
  // Used when a link in an article from CMS points to another article that should be rendered on markedsplassen.
  // We do this to avoid reloading the whole page when visiting CMS articles within the markedsplassen webapp.
  const [navigate, setNavigate] = useState<{ redirect: boolean; to?: string }>({
    redirect: false
  });

  useEffect(() => setNavigate({ redirect: false }), [cmsNode]);

  useLayoutEffect(() => {
    const redirectTo = (element: Element, event: Event) => {
      event.preventDefault();
      const href = element.getAttribute('href') as string;
      setNavigate({ redirect: true, to: href });
    };
    const getLinks = () => {
      if (ref.current) {
        return Array.from(
          ref.current.querySelectorAll('a').values()
        ).filter((e) => isInternalLink(e));
      }
      return [];
    };

    const links = getLinks();
    links.forEach((e) => {
      e.addEventListener('click', (event) => redirectTo(e, event), false);
    });
  }, [cmsNode]);

  if (navigate.redirect) {
    return <Redirect push to={navigate.to} />;
  }
  return (
    <section ref={ref} className={className ? className : ''}>
      {children}
    </section>
  );
};
