import React, { ReactElement } from 'react';

import MenuDropdownPanel from './MenuDropdownPanel';
import css from './MenuBar.module.scss';
import { useAuthentication } from '../../shared/authentication';

export default function MenuBar(): ReactElement {
  const { isEnabled: authEnabled } = useAuthentication();
  // const features = useFeatureToggle();
  // const { t } = useTranslation('translation');

  // const menuBlocks = (): MenuBlock[] => [
  //   {
  //     label: t('nav_meny_gruppe_markedsplassen'),
  //     links: [
  //       {
  //         to: '/avtaler',
  //         text: t('nav_meny_markedsplassen_avtaler'),
  //         enabled: true
  //       },
  //       {
  //         to: '/veiledning',
  //         text: t('nav_meny_markedsplassen_veiledning'),
  //         enabled: true
  //       },
  //       {
  //         to: '/nyheter',
  //         text: t('nav_meny_markedsplassen_nyheter'),
  //         enabled: true
  //       },
  //       {
  //         to: '/om-markedsplassen',
  //         text: t('Om_markedsplassen'),
  //         enabled: true
  //       },
  //       {
  //         to: `/sok?childrenOf=${RootKategorier.Programvare}`,
  //         text: t('nav_meny_markedsplassen_programvare_i_skyen'),
  //         enabled: features.isEnabled(Features.Tjenesteregister)
  //       },
  //       {
  //         to: `/sok?childrenOf=${RootKategorier.UtviklingOgDrift}`,
  //         text: t('nav_meny_markedsplassen_utvikling_og_drift_i_skyen'),
  //         enabled: features.isEnabled(Features.Tjenesteregister)
  //       },
  //       {
  //         to: `/sok?childrenOf=${RootKategorier.Konsulenttjenester}`,
  //         text: t('nav_meny_markedsplassen_avtaler_konsulenttjenester'),
  //         enabled: features.isEnabled(Features.Tjenesteregister)
  //       }
  //     ]
  //   },
  //   {
  //     label: t('nav_meny_gruppe_anskaffelser'),
  //     subtext: t('nav_meny_gruppe_anskaffelser_subtext'),
  //     links: [
  //       {
  //         to: 'https://www.anskaffelser.no/hva-skal-du-kjope',
  //         text: t('nav_meny_anskaffelser_hva_skal_du_kjoepe'),
  //         enabled: true
  //       },
  //       {
  //         to: 'https://www.anskaffelser.no/anskaffelsesprosessen',
  //         text: t('nav_meny_anskaffelser_anskaffelsesprosessen'),
  //         enabled: true
  //       },
  //       {
  //         to: 'https://www.anskaffelser.no/avtaler-og-regelverk',
  //         text: t('nav_meny_anskaffelser_avtaler_og_regelverk'),
  //         enabled: true
  //       },
  //       {
  //         to: 'https://www.anskaffelser.no/innkjopsledelse',
  //         text: t('nav_meny_anskaffelser_innkjoepsledelse'),
  //         enabled: true
  //       },
  //       {
  //         to: 'https://www.anskaffelser.no/samfunnsansvar',
  //         text: t('nav_meny_anskaffelser_samfunnsansvar'),
  //         enabled: true
  //       },
  //       {
  //         to: 'https://www.anskaffelser.no/innovasjon',
  //         text: t('nav_meny_anskaffelser_innovasjon'),
  //         enabled: true
  //       }
  //     ]
  //   }
  // ];

  return (
    <nav className={css.MenuBar}>
      <ul role="menubar">
        {authEnabled() && <MenuDropdownPanel />}
        {/* <MenuDropdownList
          toggleText={t('nav_meny')}
          toggleIcon={{ close: 'close', open: 'hamburger' }}
          menuBlocks={menuBlocks()}
        /> */}
      </ul>
    </nav>
  );
}
