/**
 * Displays the "Report error" button in the lower right if the issue collector feature toggle is enabled.
 */

import React, { ReactElement, useEffect } from 'react';

import { JIRA_ISSUE_COLLECTOR_URL } from 'shared-constants';

export default function IssueCollector(): ReactElement {
  useEffect(() => {
    displayIssueCollector();
  }, []);

  const displayIssueCollector = () => {
    const issueCollector = document.createElement('script');
    issueCollector.src = `${JIRA_ISSUE_COLLECTOR_URL}`;
    issueCollector.async = true;
    document.body.appendChild(issueCollector);
  };

  return <></>;
}
