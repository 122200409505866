import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import css from './TjenesteCard.module.scss';
import List from 'Components/UI/List/List';
import { Tjeneste } from 'models';
import { buildUrl } from 'shared/http-common';
import { TJENESTER_URL } from 'shared-constants';
import Tag from '../UI/Tag/Tag';
import { CardHandlingLink } from '../Card/Card';

export default function TjenesteCard({
  tjeneste
}: {
  tjeneste: Tjeneste | undefined;
}): ReactElement {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const cssClasses = [css.TjenesteCard];

  const TjenesteCardHeader = () => (
    <List orientation={'vertical'} spacing={'between'} gap={'sm'}>
      {tjeneste?.produsent && <p>{tjeneste.produsent.navn}</p>}
      {tjeneste && tjeneste.navn && (
        <header className={css.TjenesteCard__title}>
          <h1>{tjeneste.navn}</h1>
        </header>
      )}
    </List>
  );

  const CardMain = () => (
    <>
      {tjeneste && tjeneste.kortBeskrivelse && (
        <main className={css.TjenesteCard__description}>
          <p>{tjeneste.kortBeskrivelse}</p>
        </main>
      )}
    </>
  );

  const url = (): string => {
    return buildUrl([TJENESTER_URL, `${tjeneste && tjeneste.id}`]);
  };

  const TjenesteCardHandling = () => (
    <List orientation={'horizontal'}>
      <div className={css.TjenesteCard__label}>
        {tjeneste?.isGroup && <Tag text={t('gruppe')} variant={'primary'} />}
      </div>
      <CardHandlingLink
        url={url()}
        label={t('vis_tjeneste')}
        text={t('vis_tjeneste')}
      />
    </List>
  );

  return (
    <div
      className={classNames(cssClasses)}
      data-href={url()}
      onClick={(e) => {
        if (url()) {
          e.preventDefault();
          e.stopPropagation();
          history.push(url());
        }
      }}
    >
      <TjenesteCardHeader />
      <CardMain />
      <TjenesteCardHandling />
    </div>
  );
}
