import { AxiosResponse } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback, useState } from 'react';

import http, { buildUrl } from 'shared/http-common';
import {
  VIRKSOMHETER_FROM_ORG_NR_URL,
  VIRKSOMHETER_URL
} from 'shared-constants';
import { CreateVirksomhetRequest, Virksomhet } from 'models';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import { removeWhitespace } from '../string-utils';
import { DFO, store } from 'store';

const virksomhetQueryKeys = {
  root: () => ['virksomheter'],
  byOrgNumber: (orgNumber?: string) => {
    const key = [...virksomhetQueryKeys.root()];
    if (orgNumber) {
      key.push(orgNumber);
    }
    return key;
  }
};

export const useVirksomheter = () => {
  async function getVirksomheter() {
    return http
      .get(buildUrl([VIRKSOMHETER_URL]))
      .then((response: AxiosResponse<Virksomhet[]>) => response.data);
  }

  return useQuery<Virksomhet[]>(virksomhetQueryKeys.root(), getVirksomheter, {
    onError: (error) => {
      store.dispatch(DFO.error(error, true));
    }
  });
};

type CreateVirksomhetCallbacks = {
  onSuccess: (data: Virksomhet) => void;
  onError: () => void;
};

type CreateVirksomhet = {
  newVirksomhet: CreateVirksomhetRequest;
  accessToken: string | undefined;
};

export function useCreateVirksomhet() {
  const queryClient = useQueryClient();
  const { activeVirksomhet, setActiveVirksomhet } = useActiveVirksomhet();
  const [isLoading, setIsLoading] = useState(false);
  const getVirksomhet = async (orgNumber: string) =>
    http
      .get(buildUrl([VIRKSOMHETER_FROM_ORG_NR_URL + orgNumber.toString()]))
      .then((response: AxiosResponse<Virksomhet[]>) => response.data)
      .then((virksomheter) =>
        virksomheter.length > 0 ? virksomheter[0] : undefined
      );
  const createVirksomhet = async ({
    newVirksomhet,
    accessToken
  }: CreateVirksomhet) =>
    http
      .post(buildUrl([VIRKSOMHETER_URL]), newVirksomhet, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then((response: AxiosResponse<Virksomhet>) => response.data);
  const { mutate } = useMutation(createVirksomhet);
  const noop = () => {
    // default onSuccess/onError: do nothing
  };
  const onSuccess = useCallback(
    (virksomhet: Virksomhet, onSuccessCallback: (data: Virksomhet) => void) => {
      queryClient.invalidateQueries(
        virksomhetQueryKeys.byOrgNumber(virksomhet.orgnr.toString())
      );
      setActiveVirksomhet(activeVirksomhet ? activeVirksomhet : virksomhet);
      setIsLoading(false);
      onSuccessCallback(virksomhet);
    },
    [queryClient, activeVirksomhet, setActiveVirksomhet]
  );
  const onError = useCallback((onErrorCallback: () => void) => {
    setIsLoading(false);
    onErrorCallback();
  }, []);
  const create = useCallback(
    (
      virksomhet: CreateVirksomhetRequest,
      accessToken: string | undefined,
      {
        onSuccess: onSuccessCallback,
        onError: onErrorCallback
      }: CreateVirksomhetCallbacks = {
        onSuccess: noop,
        onError: noop
      }
    ): void => {
      const strippedOrgNumber = removeWhitespace(virksomhet.orgnr.toString());
      setIsLoading(true);
      getVirksomhet(strippedOrgNumber)
        .then((data) => {
          if (data) {
            onSuccess(data, onSuccessCallback);
          } else {
            mutate(
              {
                newVirksomhet: { ...virksomhet, orgnr: +strippedOrgNumber },
                accessToken: accessToken
              },
              {
                onSuccess: (createdVirksomhet) => {
                  onSuccess(createdVirksomhet, onSuccessCallback);
                },
                onError: () => onError(onErrorCallback)
              }
            );
          }
        })
        .catch(() => onError(onErrorCallback));
    },
    [onSuccess, onError, mutate]
  );

  return { create, isLoading, getVirksomhet };
}
