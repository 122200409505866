import React, { ReactElement, useEffect, useReducer, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import css from './RegistrerTjenesteSide.module.scss';
import { Tjeneste, TjenesteRequest, TjenesteSource } from 'models';
import { DFO, store } from 'store';
import { useCreateTjeneste } from 'shared/hooks/tjeneste-hooks';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import { useKategorier } from 'shared/hooks/kategori-hooks';
import List from 'Components/UI/List/List';
import TjenesteRegistrationForm from 'Components/TjenesteRegistrationForm';
import { isBlank } from '../shared/string-utils';
import Spinner from '../Components/Spinner/Spinner';
import FormGroup from '../Components/UI/FormGroup/FormGroup';

const initialTjeneste: TjenesteRequest = {
  navn: '',
  produsentId: null,
  kategoriId: null,
  source: TjenesteSource.Form,
  kortBeskrivelse: null,
  url: null,
  resaleEnabled: false,
  publisert: null
};

export default function RegistrerTjenesteSide(): ReactElement {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { data: kategorier } = useKategorier();
  const { activeVirksomhet: virksomhet } = useActiveVirksomhet();
  const { create, isLoading } = useCreateTjeneste();
  const tjenesteReducer = (
    tjeneste: TjenesteRequest,
    update: Partial<TjenesteRequest>
  ): TjenesteRequest => ({ ...tjeneste, ...update });
  const [tjeneste, dispatch] = useReducer(tjenesteReducer, initialTjeneste);
  const [valgtRolle, setValgtRolle] = useState<string | null>(null);

  useEffect(() => {
    dispatch({ produsentId: virksomhet?.id });
  }, [virksomhet]);

  const createTjeneste = () => {
    create(tjeneste, {
      onSuccess: (data: Tjeneste) => {
        history.push(`/firmaprofil/tjenester/${data.id}`);
      },
      onError: (error) => {
        store.dispatch(
          DFO.error(`${t('rt_modal_opprett_tjeneste_feil')}: ${error}`)
        );
      }
    });
  };

  const isNotValid = () =>
    isBlank(tjeneste.navn) ||
    isBlank(tjeneste.kortBeskrivelse) ||
    isBlank(tjeneste.url) ||
    tjeneste.kategoriId === null;

  const RoleRadio = ({
    labelKey,
    value,
    checked = false,
    disabled = false,
    onChange
  }: {
    labelKey: string;
    value: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: any) => void;
  }) => (
    <Trans>
      <label className={disabled ? 'disabled' : undefined}>
        <input
          defaultChecked={checked}
          disabled={disabled}
          type="radio"
          name="rolle"
          value={value}
          alt={t(labelKey)}
          aria-invalid={false}
          aria-required={true}
          onChange={onChange}
        />
        <span className="marker" />
        <span className={css.RadioButtonsText}>{t(labelKey)}</span>
      </label>
    </Trans>
  );

  const TjenesteRoleSelector = () => {
    enum Rolle {
      Produsent = 'produsent',
      Leverandoer = 'leverandoer'
    }

    return (
      <FormGroup isValid={true}>
        <section className="radiolist">
          <label>{t('rt_rolle')}</label>
          <RoleRadio
            labelKey={'rt_rolle_produsent'}
            value={Rolle.Produsent}
            onChange={(e) => {
              setValgtRolle(e.target.value);
            }}
            checked={valgtRolle === Rolle.Produsent}
          />
          <RoleRadio
            labelKey={'rt_rolle_forhandler'}
            value={Rolle.Leverandoer}
            onChange={(e) => {
              setValgtRolle(e.target.value);
            }}
            checked={valgtRolle === Rolle.Leverandoer}
            disabled
          />
        </section>
      </FormGroup>
    );
  };

  return (
    <div className={css.RegistrerTjenesteSide}>
      <div className={css.Wrapper}>
        <header>
          <h1>{t('rts_tittel')}</h1>
        </header>
        <TjenesteRoleSelector />
        {valgtRolle !== null && (
          <>
            <TjenesteRegistrationForm
              hovedkategorier={kategorier}
              initialTjeneste={tjeneste}
              handleChange={(update) => dispatch(update)}
            />
            <List orientation={'horizontal'} alignment={'end'}>
              <span>{t('rt_publiser_veiledning')}</span>
              <button
                type={'button'}
                disabled={isNotValid() || isLoading}
                aria-label={t('rt_registrer')}
                onClick={createTjeneste}
              >
                {t('rt_registrer')}
              </button>
              {isLoading && <Spinner isActive={true} />}
            </List>
          </>
        )}
      </div>
    </div>
  );
}
