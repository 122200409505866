import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import SearchField from 'Components/SearchField/SearchField';
import KategoriFilter from 'Components/FunksjonskategoriFilter/KategoriFilter';
import SearchResults from 'Components/SearchResults/SearchResults';
import { useSearch } from 'store';
import css from './SokeSide.module.scss';
import { useKategorier } from '../shared/hooks/kategori-hooks';
import { useVirksomheter } from '../shared/hooks/virksomhet-hooks';

export default function SokeSide(): ReactElement {
  const search = useSearch();
  const kategorier = useKategorier();
  const { data: virksomheter } = useVirksomheter();

  const { t } = useTranslation('translation');

  const valgtKategori = kategorier.data.find(
    (k) => k.id === search.childrenOf[0]
  );

  const produsent = virksomheter?.find(
    (virksomhet) => virksomhet.id === search.produsentId
  );

  const placeHolderText = (): string => {
    if (valgtKategori) {
      return t('Søk_i') + ' ' + t('kat_' + valgtKategori.id);
    }
    return t('Søk_etter');
  };

  return (
    <div className={css.SokeSide}>
      <div className={css.header}>
        <h1>
          {!valgtKategori && !search.produsentId && t('Søk')}
          {valgtKategori && !search.produsentId && t('kat_' + valgtKategori.id)}
          {search.produsentId && produsent?.navn}
        </h1>
        {valgtKategori && !search.produsentId && (
          <p>{t('kat_' + valgtKategori.id + '_ingress')}</p>
        )}
      </div>
      <SearchField placeholder={placeHolderText()} />
      <div className={css.sok}>
        <div className={css.searchField}>
          <KategoriFilter />
        </div>
        <div>
          <SearchResults kategori={valgtKategori} />
        </div>
      </div>
    </div>
  );
}
