import React, { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';

import FormGroup from 'Components/UI/FormGroup/FormGroup';
import { TjenesteRequest } from 'models';
import { useFormField } from 'shared/hooks/form-field';
import { isBlank } from 'shared/string-utils';

type InputProps = {
  labelKey: string;
  placeholderKey?: string;
  hintKey?: string;
  fieldName: keyof TjenesteRequest;
  initialValue?: string;
  maxLength?: number;
  handleBlur: (update: Partial<TjenesteRequest>) => void;
};

export const TextArea = ({
  labelKey,
  placeholderKey,
  hintKey,
  fieldName,
  initialValue,
  maxLength,
  handleBlur
}: InputProps) => {
  const { t } = useTranslation('translation');
  const { value, onChange, isVisited, onBlur } = useFormField(initialValue);
  const invalidMessage =
    maxLength !== undefined && maxLength > 0
      ? t('fg_feilmelding_lengde', { maxLength })
      : t('fg_generisk_feilmelding');

  const _handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    onBlur(event);
    if (value !== initialValue && isValid()) {
      handleBlur({ [fieldName]: value });
    }
  };

  const isValid = () => {
    if (!isVisited) {
      return true;
    }
    if (isBlank(value)) {
      return false;
    }
    if (maxLength !== undefined && maxLength > 0) {
      return value.length <= maxLength;
    }
    return true;
  };

  const Hint = () => <>{hintKey && <p>{t(hintKey)}</p>}</>;

  return (
    <FormGroup isValid={isValid()} invalidMessage={invalidMessage}>
      <label>
        {t(labelKey)}
        <textarea
          name={labelKey}
          aria-label={t(labelKey)}
          aria-invalid={isBlank(value)}
          aria-required={true}
          defaultValue={initialValue ?? ''}
          placeholder={t(placeholderKey ?? '', '')}
          onChange={onChange}
          onBlur={_handleBlur}
        />
        <Hint />
      </label>
    </FormGroup>
  );
};

export const Text = ({
  labelKey,
  placeholderKey,
  hintKey,
  fieldName,
  initialValue,
  handleBlur
}: InputProps) => {
  const { t } = useTranslation('translation');
  const { value, onChange, isVisited, onBlur } = useFormField(initialValue);

  const _handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onBlur(event);
    if (value !== initialValue && !isBlank(value)) {
      handleBlur({ [fieldName]: value });
    }
  };

  const Hint = () => <>{hintKey && <p>{t(hintKey)}</p>}</>;

  return (
    <FormGroup isValid={!isVisited || !isBlank(value)}>
      <label>
        {t(labelKey)}
        <input
          type={'text'}
          name={fieldName}
          aria-label={t(labelKey)}
          aria-invalid={isBlank(value)}
          aria-required={true}
          defaultValue={initialValue ?? ''}
          placeholder={t(placeholderKey ?? '', '')}
          onChange={onChange}
          onBlur={_handleBlur}
        />
        <Hint />
      </label>
    </FormGroup>
  );
};
