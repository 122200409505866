import React from 'react';
import { useTranslation } from 'react-i18next';

import Toolbar, { ToolbarItem } from 'Components/Toolbar/Toolbar';
import css from './IkkeAutorisert.module.scss';
import formatOrgNr from 'shared/format-orgnr';
import { useLogin } from 'shared/authentication';

function IkkeAutorisert() {
  const { t } = useTranslation('translation');
  const { user } = useLogin();

  const toolbarItems: ToolbarItem[] = [
    {
      label: t('forsiden'),
      link: '/',
      symbol: 'arrow-left'
    }
  ];

  return (
    <div className={css.IkkeAutorisert}>
      <header>
        <h1> {t('auth_vent_overskrift')}</h1>
        <Toolbar toolbarItems={toolbarItems} />
      </header>

      <div className={css.ingress}>
        {t('auth_vent_ingress_1')}
        {t('auth_vent_ingress_2')}
      </div>

      <div>
        <p>{t('auth_vent_ingress_body')}</p>
        <p>
          {t('auth_vent_ingress_body_2')}{' '}
          <a href="mailto: markedsplassen@dfo.no">markedsplassen@dfo.no</a>.
        </p>
      </div>

      <h2>{t('auth_vent_om_deg')}</h2>

      <ul>
        <li>{t('auth_vent_navn')}</li>
        <li>{user?.name}</li>
      </ul>

      <ul>
        <li>{t('auth_vent_e_post')}</li>
        <li>{user?.email}</li>
      </ul>
      <ul>
        <li>{t('auth_vent_orgnr')}</li>
        <li>
          {user?.virksomhet ? formatOrgNr(user.virksomhet?.orgnr || 0) : ''}
        </li>
      </ul>
      <ul>
        <li>{t('auth_vent_virksomhet')}</li>
        <li>{user?.virksomhet?.navn}</li>
      </ul>
    </div>
  );
}

export default IkkeAutorisert;
