import axios from 'axios';

class ResourceClient {
  readonly httpClient = axios.create({
    baseURL: '/api/resources/'
  });

  private stripLeadingSlash = (path: string) =>
    path.startsWith('/') ? path.substring(1) : path;

  async jsonResource(path: string): Promise<unknown> {
    try {
      const response = await this.httpClient.get<unknown>(
        this.stripLeadingSlash(path)
      );
      return response.data;
    } catch (e) {
      console.error(`Could not load resource ${path}`);
      return {};
    }
  }
}

const resources = new ResourceClient();
export default resources;
