import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Card, { CardProps } from 'Components/Card/Card';
import Icon from 'Components/Icon/Icon';
import { usePageHeadContext } from 'store/PageHeadContext';
import css from './Forside.module.scss';
import { useAuthentication } from 'shared/authentication';

// const initialState = {
//   query: ''
// };

export default function Forside(): ReactElement {
  const { context, isAuthenticated } = useAuthentication();
  // const [state, setState] = useState<{
  //   query: string;
  // }>(initialState);
  const { t } = useTranslation('translation');
  // const features = useFeatureToggle();
  // const history = useHistory();
  const { setPageTitle, setPageDescription } = usePageHeadContext();
  //const placeholderText = t('Søk_etter');

  useEffect(() => {
    setPageTitle('');
    setPageDescription(t('forside_ingress'));
  }, [setPageTitle, setPageDescription, t]);

  // const handleQueryChange = (input: HTMLInputElement): void => {
  //   setState({ ...state, query: input.value });
  // };

  const objectsToCards = (list: CardProps[]) => {
    return list
      .filter((kort) => !kort.hidden)
      .map((kort, index) => objectToCard(kort, index));
  };

  const objectToCard = (kort, index) => (
    <Link to={kort.link} key={index}>
      <Card
        layout={kort.layout}
        theme={kort.theme}
        type={kort.type}
        key={index}
        meta={kort.meta}
        tittel={kort.tittel}
        innhold={kort.innhold}
        bunntekst={kort.bunntekst}
        illustrasjon={kort.illustrasjon}
        handling={kort.handling}
        link={kort.link}
      />
    </Link>
  );

  const handlinger: CardProps[] = [
    {
      theme: 'tertiary',
      meta: isAuthenticated()
        ? t('forside_leverandoer_innlogget', {
            navn: context.user?.name
          })
        : t('logg_inn'),
      tittel: isAuthenticated()
        ? t('forside_ny_tjenester')
        : t('logg_inn_tekst'),
      link: '/'
    }
  ];

  // const veiledninger: CardProps[] = [
  //   {
  //     layout: 'horizontal',
  //     theme: 'secondary',
  //     meta: t('forside_veiledning'),
  //     tittel: t('forside_veiledning_beskrivelse'),
  //     illustrasjon: (
  //       <img
  //         src={'/images/spoersmaalstegn.svg'}
  //         alt={t('forside_veiledning_illustrasjon')}
  //       />
  //     ),
  //     link: '/veiledning',
  //     handling: (
  //       <CardHandlingLink
  //         url={'/veiledning/'}
  //         label={t('forside_veiledning_beskrivelse')}
  //       />
  //     )
  //   },
  //   {
  //     meta: t('forside_veiledning'),
  //     tittel: t('forside_veiledning_anskaffe_skytjenester'),
  //     link: '/veiledning/' + DrupalArticleIds.AnskaffeSkytjenester,
  //     handling: (
  //       <CardHandlingLink
  //         url={'/veiledning/' + DrupalArticleIds.AnskaffeSkytjenester}
  //         label={t('forside_veiledning_anskaffe_skytjenester')}
  //       />
  //     )
  //   },
  //   {
  //     meta: t('forside_veiledning'),
  //     tittel: t('forside_veiledning_vurdere_risiko'),
  //     link: [
  //       '/veiledning',
  //       'skyreisen',
  //       DrupalArticleIds.SkyreisenAnskaffeSkytjenester,
  //       DrupalArticleIds.SkyreisenAnskaffeSkytjenesterVurdereRisiko
  //     ].join('/'),
  //     handling: (
  //       <CardHandlingLink
  //         url={[
  //           '/veiledning',
  //           'skyreisen',
  //           DrupalArticleIds.SkyreisenAnskaffeSkytjenester,
  //           DrupalArticleIds.SkyreisenAnskaffeSkytjenesterVurdereRisiko
  //         ].join('/')}
  //         label={t('forside_veiledning_vurdere_risiko')}
  //       />
  //     )
  //   },
  //   {
  //     meta: t('forside_veiledning'),
  //     tittel: t('forside_veiledning_hvorfor_velge_sky'),
  //     link: '/veiledning/' + DrupalArticleIds.HvorforVelgeSky,
  //     handling: (
  //       <CardHandlingLink
  //         url={'/veiledning/' + DrupalArticleIds.HvorforVelgeSky}
  //         label={t('forside_veiledning_hvorfor_velge_sky')}
  //       />
  //     )
  //   },
  //   {
  //     meta: t('forside_veiledning'),
  //     tittel: t('forside_veiledning_anskaffe_skytjenester_tittel'),
  //     link:
  //       '/veiledning/skyreisen/' +
  //       DrupalArticleIds.SkyreisenAnskaffeSkytjenester,
  //     handling: (
  //       <CardHandlingLink
  //         url={
  //           '/veiledning/skyreisen/' +
  //           DrupalArticleIds.SkyreisenAnskaffeSkytjenester
  //         }
  //         label={t('forside_veiledning_anskaffe_skytjenester_tittel')}
  //       />
  //     )
  //   }
  // ];

  // const avtaler: CardProps[] = [];

  // if (features.isEnabled(Features.VeilederEtterSchrems)) {
  //   avtaler.push({
  //     meta: t('forside_veiledning'),
  //     tittel: t('forside_veiledning_bruk_av_sky_etter_schrems_ii'),
  //     link: '/veiledning/veiledning-etter-schrems-ii',
  //     handling: (
  //       <CardHandlingLink
  //         url={'/veiledning/veiledning-etter-schrems-ii'}
  //         label={t('forside_veiledning_bruk_av_sky_etter_schrems_ii')}
  //       />
  //     )
  //   });
  // }

  // avtaler.push(
  //   {
  //     theme: 'secondary',
  //     meta: t('forside_avtaler'),
  //     tittel: t('forside_kommende_avtaler'),
  //     link: '/avtaler',
  //     handling: (
  //       <CardHandlingLink
  //         url={'/avtaler'}
  //         text={t('forside_avtaler_se_alle')}
  //         label={t('forside_kommende_avtaler')}
  //       />
  //     )
  //   },
  //   {
  //     type: 'avtale',
  //     meta: t('forside_informasjonssikkerhet'),
  //     tittel: t('forside_invitasjon_nytteverdi_av_risikoscore'),
  //     bunntekst: t('forside_starter_hoesten_2022'),
  //     link: '/nyheter/' + DrupalArticleIds.InvitasjonNytteverdiAvRisikoscore,
  //     handling: (
  //       <CardHandlingLink
  //         url={'/nyheter/' + DrupalArticleIds.InvitasjonNytteverdiAvRisikoscore}
  //         text={''}
  //         label={t('forside_invitasjon_nytteverdi_av_risikoscore')}
  //       />
  //     )
  //   }
  // );

  // if (!features.isEnabled(Features.VeilederEtterSchrems)) {
  //   avtaler.push({
  //     type: 'avtale',
  //     meta: t('forside_veiledning'),
  //     tittel: t('forside_koordineringsarbeidet'),
  //     bunntekst: t('forside_kommer_hoesten_2022'),
  //     link: '/veiledning/' + DrupalArticleIds.VeiledningKoordineringsarbeidet,
  //     handling: (
  //       <CardHandlingLink
  //         url={
  //           '/veiledning/' + DrupalArticleIds.VeiledningKoordineringsarbeidet
  //         }
  //         text={''}
  //         label={t('forside_koordineringsarbeidet')}
  //       />
  //     )
  //   });
  // }

  return (
    <div className={css.Forside}>
      {/* <section>
        <Card
          theme={'blank'}
          layout={'horizontal'}
          type={'header'}
          illustrasjon={
            <img src={'/images/innovasjon.svg'} alt={t('forside_tall_boble')} />
          }
          compact={true}
        >
          <p>{t('forside_ingress')}</p>
          <p className={'text-right'}>
            <Link to={'/om-markedsplassen'}>{t('forside_ingress_link')}</Link>
          </p>
          {features.isEnabled(Features.Tjenesteregister) && (
            <div>
              <label className={css.searchField}>
                <input
                  type={'search'}
                  name={'query'}
                  value={state.query}
                  aria-label={placeholderText}
                  placeholder={placeholderText}
                  onChange={(event) => {
                    handleQueryChange(event.target as HTMLInputElement);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      history.push('/sok?query=' + state.query);
                    }
                  }}
                />
                <button
                  type={'button'}
                  onClick={() => {
                    history.push('/sok?query=' + state.query);
                  }}
                >
                  <Icon symbol={'search'} tabIndex={-1} />
                </button>
              </label>
            </div>
          )}
        </Card>
      </section> */}
      {/* {features.isEnabled(Features.Tjenesteregister) && (
        <section className={css.col_3}>
          <Tjenestekategorier />
        </section>
      )} */}
      <section className={css.handlingBokser}>
        {objectsToCards(handlinger)}
      </section>
      {/* <NyheterList numberArtikler={3} /> */}
      {/* <section className={css.veiledningBokser}>
        {objectsToCards(veiledninger)}
        {objectsToCards(avtaler)}
      </section> */}
      <section>
        <Card
          theme={'blank'}
          layout={'centered'}
          type={'feedback'}
          tittel={t('forside_feedback_tittel')}
          illustrasjon={<Icon symbol={'platter'} tabIndex={-1} />}
        >
          <p>{t('forside_feedback_ingress')}</p>
          <p>
            <a href={`mailto:${t('feedback_email')}`}>{t('feedback_email')}</a>
          </p>
        </Card>
      </section>
    </div>
  );
}
