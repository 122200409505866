import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Icon from 'Components/Icon/Icon';
import css from './MenuBar.module.scss';

interface MenuLink {
  to: string;
  text: string;
  enabled: boolean;
}

export interface MenuBlock {
  label: string;
  subtext?: string;
  links: MenuLink[];
}

interface ToggleIcons {
  close: string;
  open: string;
}

type DropdownTypes = 'list' | 'panel';

export interface MenuDropdownProps {
  toggleText: string;
  toggleIcon?: ToggleIcons;
  dropdownType?: DropdownTypes;
  menuBlocks?: MenuBlock[];
  children?: MenuBlock[] | any;
}

export default function MenuDropdown({
  toggleText,
  toggleIcon,
  dropdownType = 'list',
  children
}: MenuDropdownProps): ReactElement {
  const menuDropdown = useRef<HTMLLIElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    document.addEventListener('keyup', handleKeyup);
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
      document.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const handleKeyup = (e) => {
    switch (e.key) {
      case 'Escape':
        setIsOpen(false);
        break;
      case 'Tab':
        if (!menuDropdown.current?.contains(e.target as HTMLElement)) {
          setIsOpen(false);
        }
        break;
      default:
        break;
    }
  };

  const handleClick = (e) => {
    if (!menuDropdown.current?.contains(e.target as HTMLElement) && isOpen) {
      setIsOpen(false);
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li
      role="menuitem"
      aria-haspopup="true"
      aria-expanded={isOpen}
      ref={menuDropdown}
    >
      <div className={css.Overlay} onClick={toggleOpen} />
      <button className={css.Toggle} onClick={toggleOpen}>
        <span className="hidden-md">{toggleText}</span>
        {toggleIcon && (
          <Icon
            symbol={isOpen ? toggleIcon.close : toggleIcon.open}
            tabIndex={-1}
          />
        )}
      </button>
      <ul
        role="menu"
        className={
          css[`${dropdownType[0].toUpperCase()}${dropdownType.slice(1)}`]
        }
      >
        {children}
      </ul>
    </li>
  );
}
