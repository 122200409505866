import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Kategori } from 'models';
import css from './LeverandoerKategorier.module.scss';
import { useKategorier } from 'shared/hooks/kategori-hooks';

interface LeverandoerKategorierProps {
  setKategorier: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function LeverandoerKategorier(
  props: LeverandoerKategorierProps
): ReactElement {
  const { t } = useTranslation('translation');
  const kategorier = useKategorier();
  const [chosenKategorier, setChosenKategorier] = useState<string[]>([]);

  const handleChangeCheckBox = (event) => {
    let updatedList = [...chosenKategorier];

    if (event?.target.checked) {
      updatedList = [...chosenKategorier, event.target.value];
    } else {
      updatedList.splice(chosenKategorier.indexOf(event.target.value), 1);
    }
    setChosenKategorier(updatedList);
    props.setKategorier(updatedList);
  };

  const KategoriListe = ({ barn: barn }: { barn: Kategori[] }) => {
    return (
      <>
        {barn.length > 0 &&
          barn.map((kategori) => {
            return (
              <li key={kategori.id}>
                <label className={css.checkbox_label}>
                  <input
                    checked={chosenKategorier.includes(kategori.navn)}
                    type="checkbox"
                    name={t(`kat_${kategori.id}`)}
                    value={kategori.navn}
                    className={css.checkbox}
                    onChange={handleChangeCheckBox}
                  />
                  {t(`kat_${kategori.id}`)}
                </label>
              </li>
            );
          })}
      </>
    );
  };

  return (
    <section className={css.LeverandoerKategorier}>
      <h1>{t('kategorier')}</h1>
      <ul>
        {kategorier.data && kategorier.data.length > 0 && (
          <KategoriListe barn={kategorier.data[1].children} />
        )}
      </ul>
    </section>
  );
}
