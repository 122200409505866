import React, { useState } from 'react';

import FormGroup from 'Components/UI/FormGroup/FormGroup';
import css from '../TjenesteRegistrationForm.module.scss';
import { KategoriSelector as GenericKategoriSelector } from 'Components/KategoriSelector';
import { Kategori, TjenesteRequest } from 'models';

type SelectorProps = {
  selectedKategoriId: number | null;
  kategorier: Kategori[];
  handleChange: (update: Partial<TjenesteRequest>) => void;
};

export const KategoriSelector = ({
  selectedKategoriId,
  kategorier,
  handleChange
}: SelectorProps) => {
  const [visited, setVisited] = useState(false);

  const isValid = () => {
    if (!visited) {
      return true;
    }
    return selectedKategoriId !== null && selectedKategoriId > 0;
  };

  return (
    <FormGroup isValid={isValid()}>
      <div className={css.dropDown}>
        <GenericKategoriSelector
          kategorier={kategorier}
          selectedKategoriId={selectedKategoriId ?? undefined}
          handleChange={(kategoriId) => handleChange({ kategoriId })}
          handleBlur={() => setVisited(true)}
        />
      </div>
    </FormGroup>
  );
};
