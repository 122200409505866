import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { useCmsCache } from 'store/CmsQuery';
import css from './Breadcrumbs.module.scss';
import { useCachedTjeneste } from 'shared/hooks/tjeneste-hooks';

type BreadcrumbsRoute = {
  path: string;
  breadcrumb?:
    | React.ComponentType
    | React.ElementType
    | string
    | React.FunctionComponent
    | null;
  // see: https://reacttraining.com/react-router/web/api/matchPath
  matchOptions?: {
    exact?: boolean;
    strict?: boolean;
    sensitive?: boolean;
  };
  // optional nested routes (for react-router config compatibility)
  routes?: BreadcrumbsRoute[];
  // optional props to be passed through directly to the breadcrumb component
  props?: { [x: string]: unknown };
};

type Options = {
  // disable all default generation of breadcrumbs
  disableDefaults?: boolean;
  // exclude certain paths fom generating breadcrumbs
  excludePaths?: string[];
};

const CmsBreadcrumb = (props: { match }) => {
  const { id } = props.match.params;
  const { cache } = useCmsCache();
  const [title, setTitle] = useState<string | null>(null);

  useEffect(() => {
    const path = id.split('/');
    const lookupId = path[path.length - 1];
    const cachedNode = cache.find((n) => n.id === +lookupId);
    if (cachedNode) {
      setTitle(cachedNode.title);
    }
  }, [cache, id]);

  return <span>{title || ''}</span>;
};

const TjenesteBreadcrumb = (props: { match }) => {
  const { t } = useTranslation('translation');
  const { id } = props.match.params;
  const [title, setTitle] = useState<string | null>(null);
  const { tjeneste } = useCachedTjeneste(id);

  useEffect(() => {
    setTitle(tjeneste?.navn ? tjeneste.navn : t('bc_tjenesteprofil'));
  }, [tjeneste, t]);

  return <span>{title || ''}</span>;
};

export default function Breadcrumbs(): ReactElement {
  const { t } = useTranslation('translation');

  const routes: BreadcrumbsRoute[] = [
    {
      path: '/',
      breadcrumb: t('dashboard')
    },
    {
      path: '/sok',
      breadcrumb: t('bc_sok')
    },
    {
      path: '/om-markedsplassen',
      breadcrumb: t('Om_markedsplassen')
    },
    {
      path: '/avtaler',
      breadcrumb: t('bc_avtaler')
    },
    {
      path: '/avtaler/:id',
      breadcrumb: CmsBreadcrumb
    },
    {
      path: '/veiledning/veiledning-etter-schrems-ii',
      breadcrumb: t('bc_veiledning_etter_schrems_ii')
    },
    {
      path:
        '/veiledning/veiledning-etter-schrems-ii/personvernrisiko-for-etterretning',
      breadcrumb: t('bc_personvernrisiko_for_etterretning')
    },
    {
      path:
        '/veiledning/veiledning-etter-schrems-ii/personvernrisiko-for-etterretning/:id',
      breadcrumb: CmsBreadcrumb
    },
    {
      path: '/veiledning/veiledning-etter-schrems-ii/:id',
      breadcrumb: CmsBreadcrumb
    },
    {
      path: '/veiledning',
      breadcrumb: t('bc_veiledning')
    },
    {
      path: '/veiledning/schrems',
      breadcrumb: t('bc_schrems')
    },
    {
      path: '/veiledning/schrems/veileder',
      breadcrumb: t('bc_schrems_veileder')
    },
    {
      path: '/veiledning/skyreisen',
      breadcrumb: t('bc_skyreisen')
    },
    {
      path: '/veiledning/:id',
      breadcrumb: CmsBreadcrumb
    },
    {
      path: '/veiledning/skyreisen/:id+',
      breadcrumb: CmsBreadcrumb
    },
    {
      path: '/tjenester/:id',
      breadcrumb: t('bc_tjeneste')
    },
    {
      path: '/firmaprofil',
      breadcrumb: t('bc_firmaprofil')
    },
    {
      path: '/firmaprofil/tjenester/registrer',
      breadcrumb: t('bc_tjenester_registrer')
    },
    {
      path: '/admin',
      breadcrumb: t('admin_dashboard')
    },
    {
      path: '/type-aktor',
      breadcrumb: t('typeAktor')
    },
    {
      path: '/registrer-virksomhet',
      breadcrumb: t('registrer_virksomhet')
    },
    {
      path: '/registrer-leverandoer',
      breadcrumb: t('registrer_leverandor')
    },
    {
      path: '/min-side',
      breadcrumb: t('min_side_tittel')
    },
    {
      path: '/min-side/min-profil',
      breadcrumb: t('min_profil')
    },
    {
      path: '/admin/registrerte-leverandører',
      breadcrumb: t('registrerte_leverandorer')
    },
    {
      path: '/firmaprofil/tjenester/import',
      breadcrumb: t('bc_tjenester_import')
    },
    {
      path: '/firmaprofil/tjenester/import/status',
      breadcrumb: t('bc_tjenester_import_status')
    },
    {
      path: '/firmaprofil/tjenester/:id',
      breadcrumb: TjenesteBreadcrumb
    },
    {
      path: '/firmaprofil/tjenester/:id/rediger',
      breadcrumb: t('bc_rediger_tjeneste')
    },
    {
      path: '/firmaprofil/tjenester/:id/selger',
      breadcrumb: t('bc_selger_tjeneste')
    },
    {
      path: '/tjenester/:tjenesteId/leverandoerer/:leverandoerId',
      breadcrumb: t('bc_leverandor')
    },
    {
      path: '/nyheter',
      breadcrumb: t('bc_nyheter')
    },
    {
      path: '/nyheter/:id',
      breadcrumb: CmsBreadcrumb
    }
  ];

  const options: Options = {
    excludePaths: [
      '/firmaprofil/tjenester',
      '/tjenester/:tjenesteId/leverandoerer',
      '/tjenester',
      '/avtaler/under-arbeid',
      '/avtaler/aktive',
      '/avtaler/framtidige',
      '/tjenester/:id/beskrivelse',
      '/tjenester/:id/leverandoerer',
      '/login'
    ]
  };

  const breadcrumbs = useBreadcrumbs(routes, options);

  return breadcrumbs.length > 1 ? (
    <nav className={css.Breadcrumbs}>
      <ul>
        {breadcrumbs.map(({ match, breadcrumb }) => (
          <li key={match.url}>
            <NavLink exact to={match.url} activeClassName={css.selected}>
              {breadcrumb}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  ) : (
    <></>
  );
}
