import axios from 'axios';
import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TJENESTEREGISTER_API_BASE_URL } from 'shared-constants';
import { DFO, store } from 'store';
import { useAsyncReducer } from './async-reducer';
import { Virksomhet } from '../../models';

export interface SearchState {
  elements: SearchResultElement[];
  query: string;
  childrenOf: number[];
  kategorier: number[];
  produsentId: string;
  numberOfElementsFound?: number;
  page: number;
  limit?: number;
  offset?: number;
  executed: boolean;
  loading: boolean;
  initialized?: boolean;
  error?: any;
}

const initialState: SearchState = {
  elements: [],
  childrenOf: [],
  kategorier: [],
  produsentId: '',
  query: '',
  executed: false,
  loading: false,
  page: 0,
  initialized: false
};

export interface SearchResultElement {
  id?: string | null;
  navn: string;
  kortBeskrivelse?: string | null;
  isGroup?: boolean;
  produsent: Virksomhet;
}

type Action = { type: 'search' | 'update' | 'clear'; payload?: any };
type Dispatch = (action: Action) => void;
type Props = { children: ReactNode };

export const SearchContext = createContext<SearchState | undefined>(undefined);
export const SearchDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchContextProvider(props: Props) {
  const { children } = props;
  const [state, dispatch] = useAsyncReducer(searchReducer, initialState);
  const queryString = useQuery();
  const history = useHistory();

  useEffect(() => {
    const payload = {
      query: queryString.get('query'),
      childrenOf: queryString.getAll('childrenOf').map((str) => +str),
      kategorier: queryString.getAll('kategori').map((str) => +str),
      produsentId: queryString.get('produsentId'),
      limit: queryString.get('limit'),
      offset: queryString.get('offset'),
      initialized: true
    };

    dispatch({
      type: 'update',
      payload
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.initialized) {
      dispatch({ type: 'search' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.initialized]);

  useEffect(() => {
    if (state.executed && !state.loading) {
      history.push(sokUrl(state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.executed, state.loading]);

  return (
    <SearchContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchContext.Provider>
  );
}

const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchContextProvider');
  }
  return context;
};

const useSearchDispatch = () => {
  const context = useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useSearchDispatch must be used within a SearchContextProvider'
    );
  }
  return context;
};

function hasNoSearchParameters(state: SearchState) {
  return (
    state.query == '' &&
    state.kategorier?.length === 0 &&
    state.childrenOf.length === 0 &&
    state.produsentId.length === 0
  );
}

const searchReducer = (state: SearchState, action: any): any => {
  switch (action.type) {
    case 'search':
      if (hasNoSearchParameters(state)) {
        return state;
      }

      if (!state.initialized) {
        return state;
      }

      if (state.loading) {
        return state;
      }

      if (state.executed) {
        return state;
      }

      state = {
        ...state,
        loading: true,
        executed: true,
        offset: state.page * 20,
        numberOfElementsFound: undefined
      };

      return Promise.resolve(
        axios
          .get(sokUrl(state, true))
          .then((response) => {
            return {
              ...state,
              loading: false,
              elements: response.data.elements as SearchResultElement[],
              numberOfElementsFound: response.data.totalCount
            };
          })
          .catch((error) => {
            store.dispatch(DFO.error(error, true));
            return {
              ...state,
              loading: false,
              elements: []
            };
          })
      );

    case 'update':
      for (const key in action.payload) {
        if (action.payload[key] === null) {
          delete action.payload[key];
        }
      }

      state = {
        ...state,
        ...action.payload,
        executed: false
      };
      break;

    case 'clear':
      state = {
        ...state,
        query: '',
        childrenOf: [],
        kategorier: [],
        produsentId: '',
        executed: false,
        elements: [],
        numberOfElementsFound: undefined
      };
      break;

    default:
      break;
  }

  return state;
};

function sokUrl(state: SearchState, api = false): string {
  const url = api ? TJENESTEREGISTER_API_BASE_URL + '/tjenester?' : '/sok?';
  const parts: string[] = [];

  state.childrenOf?.forEach((c) => parts.push('childrenOf=' + c));
  state.kategorier?.forEach((k) => parts.push('kategori=' + k));

  if (state.produsentId) {
    parts.push('produsentId=' + state.produsentId);
  }
  if (state.limit) {
    parts.push('limit=' + state.limit);
  }
  if (state.offset) {
    parts.push('offset=' + state.offset);
  }
  if (state.query) {
    parts.push('query=' + encodeURI(state.query));
  }
  return url + parts.join('&');
}

export { SearchContextProvider, useSearch, useSearchDispatch, sokUrl };
