import React, { ReactElement, ReactNode, useState } from 'react';
import classNames from 'classnames';

import css from './Panel.module.scss';
import Icon from 'Components/Icon/Icon';
import List from '../List/List';

export type PanelStateIcon = 'warning' | 'check';

export type PanelStateColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'error'
  | 'warning';

export interface PanelProps {
  title?: string;
  text?: string;
  children?: ReactNode;
  panelStateIcon?: PanelStateIcon;
  panelStateColor?: PanelStateColor;
  onClick?: (event: any) => void;
  expandable?: boolean;
  startExpanded?: boolean;
}

export default function Panel({
  title,
  text,
  children,
  panelStateColor,
  panelStateIcon,
  onClick,
  expandable = false,
  startExpanded = true
}: PanelProps): ReactElement {
  const [expanded, setExpanded] = useState(startExpanded);

  return (
    <article
      className={classNames(
        css.Panel,
        expanded ? css.Expanded : css.Collapsed,
        onClick ? css.Clickable : undefined
      )}
      onClick={(e) => (onClick ? onClick(e) : undefined)}
    >
      <header className={css.Header}>
        {panelStateIcon && (
          <div className={css.Status} data-state-color={panelStateColor}>
            <Icon symbol={panelStateIcon} tabIndex={-1} />
          </div>
        )}
        {title && <h1>{title}</h1>}
        {onClick && (
          <button
            className={classNames(css.Toggle, 'link')}
            onClick={(e) => onClick(e)}
          >
            <List>
              <p>{text}</p>
              <Icon symbol={'arrow-right'} tabIndex={-1} />
            </List>
          </button>
        )}
        {!onClick && expandable && (
          <button
            className={classNames(css.Toggle, 'link')}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded && <Icon symbol={'chevron-up'} tabIndex={-1} />}
            {!expanded && <Icon symbol={'chevron-down'} tabIndex={-1} />}
          </button>
        )}
      </header>
      {children && <section className={css.Content}>{children}</section>}
    </article>
  );
}
