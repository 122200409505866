import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer
} from 'react';

import resources from 'shared/api/resources';

export type Answer = {
  value: 'YES' | 'NO' | 'NOOP';
  cmsRef?: string;
};

export type Option = {
  type: Answer;
  weight: number;
  nextStepRef: string | null;
};

export interface Step {
  id: string;
  cmsRef: string;
  options: Option[];
  previous?: Step;
}

export type ReportRef = {
  headerKey: string;
  cmsRef: string;
};

interface VeilederModel {
  intro: Step;
  reports: {
    ok: ReportRef[];
    fail: ReportRef[];
  };
  steps: Step[];
}

export interface VeilederState extends VeilederModel {
  lastAnswer?: Option;
  current?: Step;
  previous?: Step;
}

export enum VeilederActionTypes {
  Load = 'Load',
  SetCurrent = 'SetCurrent',
  SetLastAnswer = 'SetLastAnswer',
  GoBack = 'GoBack',
  Restart = 'Restart'
}

type VeilederAction =
  | {
      type: VeilederActionTypes.Load;
      payload: VeilederState;
    }
  | {
      type: VeilederActionTypes.SetCurrent;
      payload: { id: string | null };
    }
  | {
      type: VeilederActionTypes.SetLastAnswer;
      payload: { answer?: Option };
    }
  | {
      type: VeilederActionTypes.GoBack;
    }
  | {
      type: VeilederActionTypes.Restart;
    };

type Dispatch = (action: VeilederAction) => void;

const load = (dispatch: Dispatch, state: VeilederState) => {
  dispatch({ type: VeilederActionTypes.Load, payload: state });
};

const setCurrent = (dispatch: Dispatch, id: string | null) => {
  dispatch({
    type: VeilederActionTypes.SetCurrent,
    payload: { id }
  });
};

const setLastAnswer = (dispatch: Dispatch, answer?: Option) => {
  dispatch({ type: VeilederActionTypes.SetLastAnswer, payload: { answer } });
};

const goBack = (dispatch: Dispatch) => {
  dispatch({
    type: VeilederActionTypes.GoBack
  });
};

const restart = (dispatch: Dispatch) => {
  dispatch({ type: VeilederActionTypes.Restart });
};

const VeilederContext = createContext<
  { veilederState: VeilederState; dispatch: Dispatch } | undefined
>(undefined);

const reducer = (
  state: VeilederState,
  action: VeilederAction
): VeilederState => {
  switch (action.type) {
    case VeilederActionTypes.Load: {
      return {
        ...action.payload,
        current: action.payload.intro,
        lastAnswer: action.payload.intro.options[0]
      };
    }
    case VeilederActionTypes.SetCurrent: {
      const current = state.steps.find((s) => s.id === action.payload.id);
      if (current) {
        return {
          ...state,
          previous: state.current,
          current: { ...current, previous: state.current }
        };
      } else {
        return {
          ...state,
          previous: state.current,
          current
        };
      }
    }
    case VeilederActionTypes.SetLastAnswer:
      return { ...state, lastAnswer: action.payload.answer };
    case VeilederActionTypes.GoBack:
      return {
        ...state,
        previous: state.previous?.previous,
        current: state.previous ?? state.intro
      };
    case VeilederActionTypes.Restart:
      return {
        ...state,
        previous: undefined,
        current: state.intro,
        lastAnswer: state.intro.options[0]
      };
    default:
      return state;
  }
};

const isVeilederState = (obj: unknown): obj is VeilederState => {
  const cast = obj as VeilederState;
  return (
    cast.intro !== undefined &&
    cast.reports !== undefined &&
    Array.isArray(cast.steps)
  );
};

const initialState: VeilederState = ({
  intro: {
    options: []
  },
  reports: {
    ok: [],
    fail: []
  },
  steps: []
} as unknown) as VeilederState;
const VeilederContextProvider = (props: { children: ReactNode }) => {
  const { children } = props;
  const [veilederState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const fetchDefinition = async () => {
      const definition = await resources.jsonResource(
        'veileder/flow-definition.json'
      );
      if (isVeilederState(definition)) {
        load(dispatch, definition);
      } else {
        console.error(
          'Attempted to load definition, but definition was invalid.'
        );
      }
    };
    fetchDefinition();
  }, []);

  const value = { veilederState, dispatch };
  return (
    <VeilederContext.Provider value={value}>
      {children}
    </VeilederContext.Provider>
  );
};

const useVeilederContext = () => {
  const context = useContext(VeilederContext);
  if (context === undefined) {
    throw new Error(
      'useVeilederContext must be used within a VeilederContextProvider'
    );
  }
  return context;
};

export {
  VeilederContext,
  VeilederContextProvider,
  useVeilederContext,
  reducer,
  setCurrent,
  setLastAnswer,
  goBack,
  restart
};
