import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getMonth, getYear, parseISO } from 'date-fns';

import { buildUrl } from 'shared/http-common';
import { Agreement } from 'shared/api/drupal';
import { AVTALER_URL } from 'shared-constants';
import { Loading } from 'Components/Loading';
import TabSelector, { Tab } from 'Components/TabSelector/TabSelector';
import Card, { CardHandlingLink } from 'Components/Card/Card';
import Icon from 'Components/Icon/Icon';
import css from './AvtaleListe.module.scss';

interface AvtaleListeProps {
  avtaler?: Agreement[];
  isLoading: boolean;
}

interface InternalState {
  tabs: AvtaleTab[];
  activeTab: number;
}

const initialState: InternalState = {
  tabs: [],
  activeTab: 0
};

export const avtaleStatuser = ['under-arbeid', 'aktive', 'framtidige'] as const;
type AvtaleStatus = typeof avtaleStatuser[number];

type AgreementStatus = 'draft' | 'active' | 'upcoming';

interface AvtaleTab extends Tab {
  id: AvtaleStatus;
  status: AgreementStatus;
}

export default function AvtaleListe({
  avtaler,
  isLoading
}: AvtaleListeProps): ReactElement {
  const history = useHistory();
  const { t } = useTranslation('translation');
  const location = useLocation();

  const tabs: AvtaleTab[] = [
    {
      id: 'under-arbeid',
      status: 'draft',
      label: t('avtale_tab_under_arbeid'),
      selected: false
    },
    {
      id: 'aktive',
      status: 'active',
      label: t('avtale_tab_aktive'),
      selected: false
    },
    {
      id: 'framtidige',
      status: 'upcoming',
      label: t('avtale_tab_framtidige'),
      selected: false
    }
  ];

  const getActiveTabIndex = tabs.findIndex(
    (tab) =>
      tab.id ===
      location.pathname.split('/')[location.pathname.split('/').length - 1]
  );
  const activeTab = getActiveTabIndex > -1 ? getActiveTabIndex : 0;
  const [state, setState] = useState({
    ...initialState,
    activeTab,
    tabs: tabs
  });

  const valgtAgreementStatus = (): AgreementStatus => {
    return tabs[state.activeTab].status;
  };

  const handleSelect = (index: number) => {
    setState({
      ...state,
      activeTab: index
    });

    history.replace(buildUrl([AVTALER_URL, tabs[index].id]));
  };

  const avtaleMeta = (avtale: Agreement) => {
    return (
      <>
        <span className={css.type}>
          {avtale.field_agreement_type?.name || t('avtale_type_unknown')}
        </span>
        <span className={css.oppdatert}>
          {t('avtale_sist_oppdatert')}
          <time>{t('dato', { dato: new Date(avtale.changed) })}</time>
        </span>
      </>
    );
  };

  const avtaleUrl = (id: number): string => {
    return buildUrl([AVTALER_URL, `${id}`]);
  };

  const avtaleHandling = (avtale: Agreement) => {
    return (
      <CardHandlingLink
        url={avtaleUrl(avtale.id)}
        text={''}
        label={`${avtale.field_agreement_type?.name}. ${avtale.title}.`}
      />
    );
  };

  const ferdigTekst = (avtale: Agreement) => {
    return t(
      `avtale_ferdig_${
        getMonth(parseISO(avtale.field_agreement_date)) < 6 ? 'vaar' : 'hoest'
      }`,
      {
        year: getYear(parseISO(avtale.field_agreement_date))
      }
    );
  };

  const sortAgreements = (agreements: Agreement[]) => {
    const valueOrInfinity = (val) =>
      val === null ? Infinity : new Date(val).getTime();

    const sorter = (a, b) => {
      return (
        valueOrInfinity(a.field_agreement_date) -
        valueOrInfinity(b.field_agreement_date)
      );
    };

    return agreements.sort(sorter);
  };

  const avtaleListe = () => {
    return sortAgreements(filteredAgreements).map((avtale) => {
      return (
        <Card
          key={avtale.id}
          type={'avtale'}
          tittel={avtale.title}
          meta={avtaleMeta(avtale)}
          bunntekst={ferdigTekst(avtale)}
          handling={avtaleHandling(avtale)}
          link={avtaleUrl(avtale.id)}
        />
      );
    });
  };

  const emptyList = () => {
    return (
      <section className={css.EmptyList}>
        <Icon symbol={'empty'} tabIndex={-1} />
        <p>{t(`avtale_${valgtAgreementStatus()}_tom_liste`)}</p>
      </section>
    );
  };

  const filteredAgreements = avtaler?.filter(
    (avtale) => avtale.field_agreement_status == valgtAgreementStatus()
  ) as Agreement[];

  const Avtaler = () => {
    return (
      <>
        {filteredAgreements.length > 0 && !isLoading && avtaleListe()}
        {filteredAgreements.length <= 0 && !isLoading && emptyList()}
      </>
    );
  };

  return (
    <>
      <TabSelector
        tabs={tabs}
        selected={activeTab}
        onSelect={(index) => handleSelect(index)}
      />
      <section className={css.AvtaleListe}>
        {isLoading && <Loading loading={isLoading} />}
        {Avtaler()}
      </section>
    </>
  );
}
