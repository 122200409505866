import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon/Icon';

export interface LinkItemProps {
  isExternalLink: boolean;
  link: string;
  title: string;
}

export default function LinkItem({
  isExternalLink,
  link,
  title
}: LinkItemProps) {
  const ListTitelAndIcon = () => (
    <>
      <span>{title}</span>
      <Icon
        symbol={isExternalLink ? 'external-link' : 'arrow-right'}
        tabIndex={-1}
      />
    </>
  );

  return (
    <li>
      {isExternalLink ? (
        <a href={link}>
          <ListTitelAndIcon />
        </a>
      ) : (
        <Link to={link}>
          <ListTitelAndIcon />
        </Link>
      )}
    </li>
  );
}
