import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import css from './MenuBar.module.scss';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import MenuDropdown from './MenuDropdown';
import { useAuthentication } from 'shared/authentication';

export default function MenuDropdownPanel(): ReactElement {
  const { t } = useTranslation('translation');
  const { activeVirksomhet } = useActiveVirksomhet();
  const { context: authContext, isAuthenticated, logout } = useAuthentication();
  const isAdmin = authContext.user?.roles?.includes('mp_admin');

  const virksomhetNavn = (): string => {
    return activeVirksomhet?.navn ?? t('nav_min_virksomhet');
  };

  return (
    <>
      {isAuthenticated() ? (
        <MenuDropdown
          toggleText={virksomhetNavn()}
          toggleIcon={{ close: 'chevron-up', open: 'chevron-down' }}
          dropdownType={'panel'}
        >
          <li role="menuitem">
            <p>
              <span className={css.noWrap}>{authContext.user?.name}</span>
              <span>{virksomhetNavn()}</span>
            </p>
          </li>
          <li role="menuitem">
            <Link to={'/min-side'} className={css.Button}>
              {t('min_side_tittel')}
            </Link>
          </li>
          <li role="menuitem">
            <Link to={'/'} className={css.Button}>
              {t('dashboard')}
            </Link>
          </li>
          {isAdmin && (
            <li role="menuitem">
              <Link to={'/admin'} className={css.Button}>
                {t('admin_dashboard')}
              </Link>
            </li>
          )}
          <li role="menuitem">
            <Link
              to={'/login'}
              onClick={() => logout()}
              className={css.Link}
              aria-label={t('logg_ut')}
            >
              {t('logg_ut')}
            </Link>
          </li>
        </MenuDropdown>
      ) : (
        <li role="menuitem">
          <Link to={'/'}>{t('logg_inn')}</Link>
        </li>
      )}
    </>
  );
}
