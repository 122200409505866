import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import IssueCollector from 'Components/IssueCollector/IssueCollector';
import { Features } from 'shared-constants';
import { useFeatureToggle } from 'shared/feature-toggle';
import Brand, { BrandVariant } from '../Header/Brand';
import css from './Footer.module.scss';
import LinkList from '../LinkList/LinkList';
import LinkItem from '../LinkList/LinkItem';

export default function Footer(): ReactElement {
  const { t } = useTranslation('translation');
  const features = useFeatureToggle();

  const personvernLink = () =>
    features.isEnabled(Features.Tjenesteregister)
      ? '/personvern'
      : 'https://dfo.no/om-dfo/personvern';

  return (
    <footer className={css.Footer}>
      <div className={css.wrapper}>
        <section>
          <LinkList>
            <LinkItem
              isExternalLink={false}
              link={'/om-markedsplassen'}
              title={t('Om_markedsplassen')}
            />
            <LinkItem
              isExternalLink={!features.isEnabled(Features.Tjenesteregister)}
              link={personvernLink()}
              title={t('Personvern')}
            />
          </LinkList>
        </section>
        <section>
          <Brand variant={'footer' as BrandVariant} />
          <address>
            Lørenfaret 1 C
            <br />
            Pb 7154 St. Olavs plass, 0133 Oslo
            <br />
            {t('Tlf')}: 400 07 997
            <br />
            Org.Nr: 986 252 932
          </address>
        </section>
      </div>
      {features.isEnabled(Features.IssueCollector) && <IssueCollector />}
    </footer>
  );
}
