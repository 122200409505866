import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { buildUrl } from '../shared/http-common';
import Panel, {
  PanelStateColor,
  PanelStateIcon
} from 'Components/UI/Panel/Panel';
import {
  FIRMAPROFIL_URL,
  REDIGER_URL,
  TJENESTER_URL
} from '../shared-constants';
import { Tjeneste } from '../models';

export default function ProdusentTjenesteSide({
  tjeneste
}: {
  tjeneste: Tjeneste;
}): ReactElement {
  const { t } = useTranslation('translation');
  const history = useHistory();

  const tjenesteUrl = (): string => {
    return buildUrl([FIRMAPROFIL_URL, TJENESTER_URL, tjeneste.id, REDIGER_URL]);
  };

  const panelStateIcon = (): PanelStateIcon =>
    tjeneste?.klarTilPublisering ? 'check' : 'warning';

  const panelStateColor = (): PanelStateColor =>
    tjeneste?.klarTilPublisering ? 'tertiary' : 'warning';

  return (
    <Panel
      title={t('ot_informasjon_tittel')}
      text={
        tjeneste?.klarTilPublisering
          ? t('ot_veiledning_tekst_fullført')
          : t('ot_veiledning_tekst_ufullført')
      }
      panelStateIcon={panelStateIcon()}
      panelStateColor={panelStateColor()}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(tjenesteUrl());
      }}
    />
  );
}
