import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Icon from 'Components/Icon/Icon';
import { Loading } from 'Components/Loading';
import { Process } from 'shared/api/drupal';
import { useCmsById } from 'store/CmsQuery';
import css from './ProcessMenu.module.scss';
import cmsContentCss from '../../CmsNode/CmsNode.module.scss';

export const ProcessMenu = <T extends Process>({
  cmsNode,
  setActiveRootUuid
}: {
  cmsNode: T;
  setActiveRootUuid: (rootUuid: string) => void;
}) => {
  const {
    cmsNode: processRoot,
    isLoading: isLoadingRoot
  } = useCmsById<Process>('process', cmsNode?.process.processId);

  if (isLoadingRoot) {
    return <Loading loading={isLoadingRoot} />;
  }

  if (
    processRoot &&
    (processRoot.steps.length > 0 || cmsNode.steps.length > 0)
  ) {
    return (
      <nav className={classNames([css.ProcessMenu, cmsContentCss.ProcessMenu])}>
        {processRoot.steps.map((s) => (
          <ProcessMenuItem
            key={s}
            rootUuid={s}
            nodeUuid={s}
            routePrefix={`/veiledning/skyreisen`}
            setActiveRootUuid={setActiveRootUuid}
          />
        ))}
      </nav>
    );
  }
  return <></>;
};

export type MenuItemProps = {
  rootUuid: string;
  nodeUuid: string;
  routePrefix: string;
  setActiveRootUuid: (rootUuid: string) => void;
};

export const ProcessMenuItem = <T extends Process>({
  rootUuid,
  nodeUuid,
  routePrefix,
  setActiveRootUuid
}: MenuItemProps) => {
  const { cmsNode, isLoading } = useCmsById<T>('process-step', nodeUuid);
  const { id: pathIds } = useParams<{ id: string | undefined }>();
  const pathComponents = pathIds ? pathIds.split('/').map((id) => +id) : [];
  const activeId =
    pathComponents.length > 0 ? pathComponents[pathComponents.length - 1] : -1;
  const isActivePath = !!cmsNode && pathComponents.includes(cmsNode.id);
  const isActiveNode = !!cmsNode && cmsNode.id === activeId;

  useEffect(() => {
    if (isActiveNode) {
      setActiveRootUuid(rootUuid);
    }
  }, [setActiveRootUuid, isActiveNode, rootUuid]);

  const RootMenu = () => {
    if (
      cmsNode &&
      pathComponents.includes(cmsNode.id) &&
      cmsNode.steps.length > 0
    ) {
      return (
        <ul className={css.RootMenu}>
          {cmsNode.steps.map((s) => (
            <React.Fragment key={s}>
              <ProcessMenuItem
                rootUuid={rootUuid}
                nodeUuid={s}
                routePrefix={`${routePrefix}/${cmsNode.id}`}
                setActiveRootUuid={setActiveRootUuid}
              />
            </React.Fragment>
          ))}
        </ul>
      );
    }
    return <></>;
  };

  const SubMenu = () => (
    <ul className={css.SubMenu}>
      {cmsNode &&
        cmsNode.steps.map((s) => (
          <ProcessMenuItem
            key={s}
            rootUuid={rootUuid}
            nodeUuid={s}
            routePrefix={`${routePrefix}/${cmsNode.id}`}
            setActiveRootUuid={setActiveRootUuid}
          />
        ))}
    </ul>
  );

  const Chevron = () => {
    if (cmsNode && cmsNode.steps.length > 0) {
      return <Icon symbol={'chevron-up'} tabIndex={-1} />;
    }
    return <></>;
  };

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  if (cmsNode) {
    if (rootUuid === cmsNode.uuid) {
      return <RootMenu />;
    } else {
      return (
        <li
          className={classNames([
            isActiveNode ? css.activeNode : undefined,
            isActivePath ? css.activePath : undefined
          ])}
        >
          <Link to={`${routePrefix}/${cmsNode.id}`}>
            {cmsNode.title} <Chevron />
          </Link>
          {isActivePath && cmsNode.steps.length > 0 && <SubMenu />}
        </li>
      );
    }
  } else {
    return <></>;
  }
};
