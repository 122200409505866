import { createInstance, useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const isMatomoDisabled = (): boolean => {
  const value = process.env.REACT_APP_MATOMO_DISABLED;
  if (value === undefined) return true;
  try {
    if (typeof JSON.parse(value) === 'boolean') return JSON.parse(value);
  } catch (error) {
    return true;
  }
  return true;
};

const matomoInstance = createInstance({
  urlBase: 'https://tjenester.markedsplassen.anskaffelser.no/',
  siteId: 36,
  trackerUrl: 'https://sporing.dfo.no/matomo.php',
  srcUrl: 'https://sporing.dfo.no/matomo.js',
  disabled: isMatomoDisabled(),
  heartBeat: {
    active: true,
    seconds: 10
  }
});

const Matomo = (): null => {
  const { trackPageView } = useMatomo();
  const location = useLocation();

  useEffect(() => {
    trackPageView({});
  }, [location, trackPageView]);

  return null;
};

export { matomoInstance, Matomo };
