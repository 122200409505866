import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import React, { ReactElement, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import css from 'App.module.scss';
import AlertBox from 'Components/AlertBox/AlertBox';
import Breadcrumbs from 'Components/Breadcrumbs/Breadcrumbs';
import Footer from 'Components/Footer/Footer';
import Header from 'Components/Header/Header';
import Routes from 'Routes';
import { useFeatureToggle } from 'shared/feature-toggle';
import { store } from 'store';
import { PageHeadProvider } from 'store/PageHeadContext';
import { Matomo, matomoInstance } from 'matomoInstance';

function App(): ReactElement {
  const { isInitialized: isTogglesInitialized } = useFeatureToggle();

  return (
    <Provider store={store}>
      <Suspense fallback="">
        {isTogglesInitialized() && (
          <div className={css.App}>
            <Router>
              <Header />
              <main className={css.Main}>
                <div className={css.Wrapper}>
                  <PageHeadProvider>
                    <Breadcrumbs />
                    <MatomoProvider value={matomoInstance}>
                      <Matomo />
                      <Routes />
                    </MatomoProvider>
                  </PageHeadProvider>
                </div>
              </main>
              <Footer />
            </Router>
            <AlertBox />
          </div>
        )}
      </Suspense>
    </Provider>
  );
}

export default App;
