import React, { useEffect, useState } from 'react';

import css from './CheckExternalAvailability.module.scss';

export type ResourceStatus = 'waiting' | 'up' | 'down';

type CheckAvailabilityProps = {
  resourceUrl: string;
  waitingComponent?: JSX.Element;
  availableComponent: JSX.Element;
  unavailableComponent: JSX.Element;
  onStatusChange?: (status?: ResourceStatus) => void;
};

export const CheckExternalAvailability = ({
  resourceUrl,
  waitingComponent,
  availableComponent,
  unavailableComponent,
  onStatusChange
}: CheckAvailabilityProps) => {
  const [status, setStatus] = useState<ResourceStatus | undefined>(undefined);

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(status);
    }
  }, [onStatusChange, status]);

  return (
    <>
      <img
        className={css.CheckExternalAvailability}
        aria-hidden={true}
        alt={'Status: ' + status}
        src={resourceUrl}
        onLoadStart={() => setStatus('waiting')}
        onLoad={() => setStatus('up')}
        onError={() => {
          console.warn('Failed to establish connection with resource');
          setStatus('down');
        }}
      />
      {status === 'up' && availableComponent}
      {status === 'down' && unavailableComponent}
      {status === 'waiting' && waitingComponent}
    </>
  );
};
