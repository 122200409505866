import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import List from '../UI/List/List';
import Tag from '../UI/Tag/Tag';
import { Tjeneste } from '../../models';
import css from './TjenesteDescriptionAndTagsAndImage.module.scss';

export default function TjenesteDescriptionAndTagsAndImage({
  tjeneste
}: {
  tjeneste: Tjeneste | undefined;
}): ReactElement {
  const { t } = useTranslation('translation');

  const TagsForTjenesteAndGroup = () => {
    const tagList = new Set<string>();

    if (tjeneste?.kategori?.id) {
      tagList.add(t(`kat_${tjeneste.kategori.id}`));
    }

    if (tjeneste?.children) {
      tjeneste?.children.map((child) => {
        if (child.kategori?.id) {
          tagList.add(t(`kat_${child.kategori.id}`));
        }
      });
    }

    return (
      <List orientation={'horizontal'} alignment={'start'} gap={'lg'}>
        {[...tagList].map((tag, i) => (
          <Tag text={tag} key={`tag_${i}`} variant={'primaryLight'} />
        ))}
      </List>
    );
  };

  return (
    <>
      <section className={css.DescriptionAndTags}>
        <p className={css.Decription}>{tjeneste?.kortBeskrivelse}</p>
        <h2>{t('ot_kategorier_og_tags')}</h2>
        <TagsForTjenesteAndGroup />
      </section>
      <section>
        {tjeneste?.bilde && (
          <figure>
            <img
              data-testid={'tjeneste-image'}
              className={css.image}
              role="img"
              src={tjeneste.bilde}
              alt={t('tjenestebilde')}
            />
          </figure>
        )}
      </section>
    </>
  );
}
