import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';

import { globalReducers, State } from 'store/reducers/global-reducers';

export * from './actions';
export * from './reducers/global-reducers';
export * from './reducers/search-reducers';
export * from './reducers/avtaler-reducers';

export type CombinedAppState = {
  global: State;
};

const reducers = combineReducers({
  global: globalReducers
});

export const store = createStore(reducers, devToolsEnhancer({}));

if (window['Cypress']) {
  window['store'] = store;
}
