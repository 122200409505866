import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { Kategori } from 'models';
import css from '../TjenesteRegistrationForm.module.scss';
import FormGroup from '../../UI/FormGroup/FormGroup';

export const HovedkategoriSelector = ({
  kategorier,
  handleChange
}: {
  kategorier: Kategori[];
  handleChange: (kategoriId: number) => void;
}) => {
  const { t } = useTranslation('translation');
  const [hovedkategori, setHovedkategori] = useState<number | undefined>();
  const [visited, setVisited] = useState(false);

  useEffect(() => {
    if (hovedkategori) {
      handleChange(hovedkategori);
    }
  }, [hovedkategori, handleChange]);

  return (
    <FormGroup isValid={!visited || !!hovedkategori}>
      <section className="radiolist">
        <label>{t('rt_hovedkategori')}</label>
        {kategorier.map((kategori: Kategori) => (
          <label key={kategori.id.toString()}>
            <input
              type="radio"
              name="hovedkategori"
              value={kategori.id}
              alt={t('kat_' + kategori.id)}
              aria-invalid={!!kategori?.id}
              aria-required={true}
              onChange={(e) => setHovedkategori(+e.target.value)}
              onBlur={() => setVisited(true)}
            />
            <span className="marker" />
            <span className={css.RadioButtonsText}>
              {t('kat_' + kategori.id)}
            </span>
          </label>
        ))}
      </section>
    </FormGroup>
  );
};
