import React from 'react';
import { Route } from 'react-router-dom';

import Spinner from '../../Components/Spinner/Spinner';
import { useLogin } from 'shared/authentication';
import IkkeAutorisert from '../../IkkeAutorisert/IkkeAutorisert';

export function ProtectedRoute({
  // eslint-disable-next-line react/prop-types
  component: Component
}) {
  const { isAuthorized, isAuthenticated } = useLogin();

  const Authenticated = !isAuthorized() ? <IkkeAutorisert /> : <Component />;

  return (
    <Route>
      {!isAuthenticated() ? (
        <div>
          <Spinner isActive={true} />
        </div>
      ) : (
        Authenticated
      )}
    </Route>
  );
}
