import { Virksomhet } from './virksomhet';
import { Kategori } from './kategori';

export enum TjenesteSource {
  Import = 'IMPORT',
  Form = 'FORM'
}

export interface Tjeneste {
  id: string;
  navn: string;
  beskrivelse?: string | null;
  bilde: string | null;
  kategori: Kategori;
  registrertDato: string | null;
  sistEndretDato: string | null;
  produsent: Virksomhet;
  virksomhetTjenesteRelasjon: string[];
  resaleEnabled: boolean;
  klarTilPublisering: boolean;
  publisert: string | null;
  url: string | null;
  kortBeskrivelse: string | null;
  source: TjenesteSource;
  isGroup: boolean;
  parent: Tjeneste | null;
  children: Tjeneste[];
}

export interface TjenesteRequest {
  navn: string;
  produsentId: string | null;
  kategoriId: number | null;
  source: TjenesteSource;
  kortBeskrivelse: string | null;
  url: string | null;
  resaleEnabled: boolean;
  publisert: string | null;
}

export interface TjenesteUpdate extends TjenesteRequest {
  id: string;
}
