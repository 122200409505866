import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Kategori } from 'models';
import { useSearch, useSearchDispatch } from 'store';
import css from './KategoriFilter.module.scss';
import { useKategorier } from '../../shared/hooks/kategori-hooks';
import Icon from '../Icon/Icon';

export default function KategoriFilter(): ReactElement {
  const { t } = useTranslation('translation');
  const search = useSearch();
  const dispatch = useSearchDispatch();
  const kategorier = useKategorier();
  const kategoriId = search.kategorier[search.kategorier.length - 1];

  useEffect(() => {
    dispatch({ type: 'search' });
  }, [search.kategorier, dispatch]);

  const inSelectedOrChildrenOfList = (id: number): boolean => {
    return inSelectedList(id) || inChildrenOfList(id);
  };

  const inSelectedList = (id: number): boolean => {
    return search.kategorier.includes(id);
  };

  const inSelectedChildrenOfList = (id: number): boolean => {
    return search.childrenOf[0] === id && !search.kategorier.length;
  };

  const inChildrenOfList = (id: number): boolean => {
    return search.childrenOf.includes(id);
  };

  const updateSelected = (childrenOfIds: number[], id: number): void => {
    const isHovedKategoriValgt = () => {
      return (
        search.childrenOf[0] === id || kategorier.data.find((k) => k.id === id)
      );
    };
    dispatch({
      type: 'update',
      payload: {
        kategorier: isHovedKategoriValgt() ? [] : [id],
        childrenOf: childrenOfIds
      }
    });
  };

  const getParentIds = (kategori) => {
    const ids: number[] = [];
    if (kategori?.parent?.parent?.id) {
      ids.push(...getParentIds(kategori.parent));
    }
    if (kategori?.parent?.id) {
      ids.push(kategori.parent.id);
    }
    if (!kategori?.parent?.id) {
      ids.push(kategori.id);
    }
    return ids;
  };

  const handleAllValueChange = (): void => {
    const topLevelParentId = search.childrenOf[0];
    dispatch({
      type: 'update',
      payload: {
        kategorier: [],
        childrenOf: search.produsentId ? [] : [topLevelParentId],
        produsentId: search.produsentId
      }
    });
  };

  const isFirstLevelOfChildren = (kategori: Kategori) => {
    return search.childrenOf.map((parent) => parent === kategori?.parent?.id);
  };

  const onlyHovedKategoriSelected = () => {
    return !!search.childrenOf[0] && search.kategorier.length === 0;
  };

  const haveChildren = (kategori: Kategori) => {
    return kategori?.children && kategori.children.length > 0;
  };

  const shouldDisplayAllKategorier = () => {
    return (
      (!!search.childrenOf[0] && search.produsentId) ||
      (search.childrenOf.length > 0 && inSelectedList(kategoriId))
    );
  };

  const KategoriListe = ({ barn: barn }: { barn: Kategori[] }) => {
    const anySiblingsSelected = barn.map((b) =>
      inSelectedOrChildrenOfList(b.id)
    );

    return (
      <>
        {barn.length > 0 &&
          barn.map((kategori) => {
            const childrenOfList: number[] = getParentIds(kategori);

            return (
              <li
                key={kategori.id}
                data-id={kategori.id}
                data-parent-ids={getParentIds(kategori)}
                className={classNames([
                  inSelectedOrChildrenOfList(kategori.id)
                    ? css.selected
                    : undefined,
                  anySiblingsSelected.includes(true) ? css.hidden : undefined
                ])}
              >
                {(kategori.parent || search.produsentId) && (
                  <div className={css.selectedKategorier}>
                    {inSelectedOrChildrenOfList(kategori.id) &&
                      anySiblingsSelected.includes(true) &&
                      !inSelectedList(kategori.id) &&
                      !inSelectedChildrenOfList(kategori.id) && (
                        <Icon symbol={'arrow-left'} tabIndex={-1} />
                      )}
                    <span
                      data-selected={
                        inSelectedList(kategori.id) ||
                        inSelectedChildrenOfList(kategori.id)
                      }
                      data-hoved-kategori={onlyHovedKategoriSelected()}
                      data-first-children={isFirstLevelOfChildren(kategori)}
                      data-have-children={haveChildren(kategori)}
                      onClick={() =>
                        updateSelected(childrenOfList, kategori.id)
                      }
                    >
                      {t(`kat_${kategori.id}`)}
                    </span>
                  </div>
                )}
                {inSelectedOrChildrenOfList(kategori.id) &&
                  kategori.children &&
                  kategori.children.length > 0 && (
                    <ul>
                      <KategoriListe barn={kategori.children} />
                    </ul>
                  )}
              </li>
            );
          })}
      </>
    );
  };

  return (
    <section className={css.FunksjonskategoriFilter}>
      <h1>{t('velg_kategori')}</h1>
      <ul>
        {kategorier.data && kategorier.data.length > 0 && (
          <>
            {shouldDisplayAllKategorier() && (
              <li onClick={() => handleAllValueChange()}>
                <div className={css.selectedKategorier}>
                  <Icon symbol={'arrow-left'} tabIndex={-1} />
                  <span>{t('alle_kategorier')}</span>
                </div>
              </li>
            )}
            <KategoriListe barn={kategorier.data} />
          </>
        )}
      </ul>
    </section>
  );
}
