import React, { ReactElement } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LEVERANDOERER_URL, TJENESTER_URL } from 'shared-constants';
import Icon from 'Components/Icon/Icon';
import { buildUrl } from 'shared/http-common';
import { LeverandoerInfo } from 'models';
import { useLeverandoererInfo } from 'shared/hooks/leverandoer-hooks';
import css from './LeverandoerListe.module.scss';

export default function LeverandoerListe(): ReactElement {
  const { t } = useTranslation('translation');
  const { id } = useParams<{ id: string }>();
  const leverandoerer = useLeverandoererInfo(id);

  const visteLeverandoerer: string[] = [];

  const url = (leverandoer: LeverandoerInfo): string => {
    return buildUrl([
      TJENESTER_URL,
      id,
      LEVERANDOERER_URL,
      leverandoer.virksomhet.id as string
    ]);
  };

  if (leverandoerer.data?.length === 0) {
    return (
      <div className={css.EmptyList}>
        <Icon symbol={'cloud-cross'} tabIndex={-1} />
        <p data-cy="leverandoerer-alt-tekst">
          {t('ingen_leverandoerer_registrert_alt_tekst')}
        </p>
      </div>
    );
  }

  return (
    <div className={css.LeverandoerListe}>
      {leverandoerer.data &&
        leverandoerer.data
          .filter((leverandoer) => {
            if (
              visteLeverandoerer.indexOf(
                leverandoer.virksomhet.id as string
              ) === -1
            ) {
              visteLeverandoerer.push(leverandoer.virksomhet.id as string);
              return true;
            }
            return false;
          })
          .map((leverandoer) => (
            <section key={leverandoer.virksomhet.id}>
              <h1>{leverandoer.virksomhet.navn}</h1>
              <p>{leverandoer.beskrivelse}</p>
              <Link to={url(leverandoer)}>
                <Icon symbol={'arrow-right'} />
              </Link>
            </section>
          ))}
    </div>
  );
}
