import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from 'Components/Loading';
import { ProcessStep } from 'Components/Process/ProcessStep';
import { useCmsById } from 'store/CmsQuery';
import css from './Process.module.scss';

export interface Step {
  cmsRef: string;
  tooltip: string;
  baseUrl: string;
}

export class Step implements Step {
  constructor(
    public cmsRef: string,
    public tooltip: string,
    public baseUrl: string
  ) {}
}

interface ProcessProps {
  steps: Step[];
  activeStep?: number;
}

export const Process = ({ steps, activeStep }: ProcessProps) => {
  const { t } = useTranslation('translation');
  const [hoveredStep, setHoveredStep] = useState<number>(-1);

  const staticTooltips = [
    t('skyreisen_tooltips_fase_1'),
    t('skyreisen_tooltips_fase_2'),
    t('skyreisen_tooltips_fase_3'),
    t('skyreisen_tooltips_fase_4')
  ];

  const Steps = () => (
    <>
      {steps.map((s, i) => (
        <ProcessStep
          key={i}
          cmsRef={s.cmsRef}
          baseUrl={s.baseUrl}
          tooltip={staticTooltips[i]}
          step={i}
          active={activeStep === i}
          hoveredStep={hoveredStep}
          onStepHover={setHoveredStep}
        />
      ))}
    </>
  );

  const isMiddleStepsActive = () =>
    activeStep !== undefined &&
    activeStep >= 0 &&
    activeStep !== 0 &&
    activeStep !== steps.length - 1;

  const getIterateArrowClassNames = (arrowForward: boolean) => {
    if (arrowForward) {
      if (isMiddleStepsActive()) {
        return [css.Forward, css.LightArrow];
      }
      return [css.Forward];
    } else {
      if (isMiddleStepsActive()) {
        return [css.LightArrow];
      }
    }
    return [];
  };

  return (
    <nav className={css.Process} role="navigation">
      <ul>
        <Steps />
      </ul>
      <div className={css.Iterate}>
        <div className={classNames(getIterateArrowClassNames(true))} />
        <div className={classNames(getIterateArrowClassNames(false))} />
      </div>
    </nav>
  );
};

const Config = {
  firstTextPadding: 40,
  regularTextPadding: 88,
  stepWidth: 320,
  stepHeight: 150,
  tipWidth: 44,
  strokeWidth: 3
};

const StaticProcessStep = ({
  index,
  cmsRef
}: {
  index: number;
  cmsRef: string;
}) => {
  const { cmsNode, isLoading } = useCmsById('process-step', cmsRef);
  const textX =
    index === 0 ? Config.firstTextPadding : Config.regularTextPadding;

  if (isLoading) {
    return <Loading loading={isLoading} />;
  }

  if (cmsNode) {
    return (
      <g
        x={0}
        y={0}
        transform={`translate(${
          (Config.stepWidth - Config.tipWidth - Config.strokeWidth) * index
        })`}
      >
        {index === 0 ? (
          <use xlinkHref={'#step-start'} />
        ) : (
          <use xlinkHref={'#step'} />
        )}
        <foreignObject
          x={textX}
          y={0}
          width={Config.stepWidth - Config.tipWidth - textX}
          height={Config.stepHeight}
        >
          <div className={css.StepTitle}>{cmsNode.title}</div>
        </foreignObject>
      </g>
    );
  } else {
    return <></>;
  }
};

export const StaticProcess = ({ steps }: ProcessProps) => {
  const Steps = () => (
    <>
      {steps.map((s, i) => (
        <StaticProcessStep key={s.cmsRef} index={i} cmsRef={s.cmsRef} />
      ))}
    </>
  );

  return (
    <>
      {steps.length != 0 && (
        <svg
          className={css.StaticProcess}
          width={'100%'}
          height={'100%'}
          viewBox={`0 0 ${
            (Config.stepWidth - Config.tipWidth) * steps.length +
            Config.tipWidth
          } ${Config.stepHeight}`}
        >
          <defs>
            <path
              id={'step'}
              d={`M${Config.strokeWidth / 2} ${Config.strokeWidth / 2}
          H${Config.stepWidth - Config.tipWidth}
          L${Config.stepWidth} ${Config.stepHeight / 2}
          L${Config.stepWidth - Config.tipWidth} ${
                Config.stepHeight - Config.strokeWidth / 2
              }
          H${Config.strokeWidth / 2}
          L${Config.tipWidth} ${Config.stepHeight / 2}
          L${Config.strokeWidth / 2} ${Config.strokeWidth / 2}
          Z`}
              strokeWidth={Config.strokeWidth}
            />
            <path
              id={'step-start'}
              d={`M${Config.strokeWidth / 2} ${Config.strokeWidth / 2}
          H${Config.stepWidth - Config.tipWidth}
          L${Config.stepWidth} ${Config.stepHeight / 2}
          L${Config.stepWidth - Config.tipWidth} ${
                Config.stepHeight - Config.strokeWidth / 2
              }
          H${Config.strokeWidth / 2}
          V${Config.strokeWidth / 2}
          Z`}
              strokeWidth={Config.strokeWidth}
            />
          </defs>
          <Steps />
        </svg>
      )}
    </>
  );
};
