import React, { ReactNode } from 'react';

import css from './LinkList.module.scss';
export interface LinkListProps {
  children?: ReactNode;
}

export default function LinkList({ children }: LinkListProps) {
  return <ul className={css.LinkList}>{children}</ul>;
}
