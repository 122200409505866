import React from 'react';
import { useTranslation } from 'react-i18next';

import css from '../TjenesteRegistrationForm.module.scss';
import Spinner from 'Components/Spinner/Spinner';

type ImageUploadProps = {
  initialImage?: string;
  handleChange?: (file: File) => void;
  isLoading?: boolean;
};

export const ImageUpload = ({
  initialImage,
  handleChange,
  isLoading = false
}: ImageUploadProps) => {
  const { t } = useTranslation('translation');

  return (
    <div className={css.fileInput}>
      <label>
        {t('rt_bilder')}
        <p>{t('rt_bilde_veiledning')}</p>
      </label>
      <div className={css.dropArea}>
        {!isLoading && (
          <span>
            {t('rt_dra_bilder_hit')}
            <br />
            <button className={'inverted light'}>{t('rt_velg_bilder')}</button>
          </span>
        )}
        {!isLoading && (
          <input
            type={'file'}
            name={'image-upload'}
            accept={'image/png, image/jpeg'}
            aria-label={t('rt_bilder')}
            multiple={false}
            onChange={(event) => {
              if (
                handleChange &&
                event.target.files &&
                event.target.files.length > 0
              ) {
                handleChange(event.target.files[0]);
              }
            }}
          />
        )}
        {isLoading && (
          <div className={css.spinner}>
            <Spinner isActive={isLoading} />
          </div>
        )}
      </div>
      {initialImage && !isLoading && (
        <div className={css.preview} id={'preview'}>
          <div
            style={{
              backgroundImage: 'url(' + initialImage + ')'
            }}
          />
        </div>
      )}
    </div>
  );
};
