export const removeWhitespace = (value: string) => value.replace(/\s/g, '');
export const isBlank = (value: any | null | undefined) =>
  !value || value.toString().trim().length < 1;
export const getOrElse = (
  value: string | null | undefined,
  orElse: () => string
): string => {
  if (!value || isBlank(value)) {
    return orElse();
  }
  return value;
};
