import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { buildUrl } from 'shared/http-common';
import { TJENESTER_URL } from 'shared-constants';
import List from 'Components/UI/List/List';
import { CardHandlingLink } from 'Components/Card/Card';
import css from './TjenesteViewer.module.scss';
import Tag from 'Components/UI/Tag/Tag';
import { getOrElse, isBlank } from 'shared/string-utils';
import LinkList from 'Components/LinkList/LinkList';
import LinkItem from 'Components/LinkList/LinkItem';
import Grid from 'Components/UI/Grid/Grid';
import TjenesteCard from 'Components/TjenesteCard/TjenesteCard';
import TjenesteDescriptionAndTagsAndImage from 'Components/TjenesteDescriptionAndTagsAndImage/TjenesteDescriptionAndTagsAndImage';
import TabSelector, { Tab } from 'Components/TabSelector/TabSelector';
import LeverandoerListe from 'Components/LeverandoerListe/LeverandoerListe';
import { Tjeneste } from 'models';

export const tjenesteInformasjoner = ['beskrivelse', 'leverandoerer'] as const;
type TjenesteInfo = typeof tjenesteInformasjoner[number];
const isTjenesteInfo = (value: any): value is TjenesteInfo =>
  tjenesteInformasjoner.includes(value as TjenesteInfo);

interface TjenesteTab extends Tab {
  id: TjenesteInfo;
}

type TjenesteViewerProps = {
  tjeneste: Tjeneste;
};

export const TjenesteViewer = ({ tjeneste }: TjenesteViewerProps) => {
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<TjenesteInfo>('beskrivelse');

  useEffect(() => {
    const pathParts = pathname.split('/');
    const currentTab = pathParts[pathParts.length - 1];
    if (isTjenesteInfo(currentTab)) {
      setActiveTab(currentTab);
    } else {
      setActiveTab('beskrivelse');
    }
  }, [pathname]);

  const isGroup = () => tjeneste.isGroup;
  const isChild = () => !!tjeneste.parent;

  const tabs: TjenesteTab[] = [
    {
      id: 'beskrivelse',
      label: t('Beskrivelse'),
      selected: false
    },
    {
      id: 'leverandoerer',
      label: t('Leverandører'),
      selected: false
    }
  ];

  const handleSelect = (index: number) => {
    history.push(createUrl(tabs[index].id));
  };

  const createUrl = (tabName: TjenesteInfo) => {
    const pathParts = pathname.split('/');
    const currentTab = pathParts[pathParts.length - 1];
    if (isTjenesteInfo(currentTab)) {
      pathParts.pop();
    }
    return `${pathParts.join('/')}/${tabName}`;
  };

  const produsent = (): string => tjeneste.produsent.navn || '';
  const produsentId = (): string => tjeneste.produsent.id || '';

  const produsentTjensterUrl = () => {
    return `/sok?produsentId=${produsentId()}`;
  };

  const tjenesteParenturl = (): string => {
    return buildUrl([
      TJENESTER_URL,
      `${tjeneste.parent && tjeneste.parent.id}`
    ]);
  };

  const TjenesteCardHandling = () => (
    <>
      <List orientation={'horizontal'}>
        <CardHandlingLink
          url={tjenesteParenturl()}
          label={tjeneste.parent?.navn}
          text={tjeneste.parent?.navn}
          reverse={true}
        />
        <div className={css.TjenesteCard__label}>
          {(isGroup() || isChild()) && (
            <Tag text={t('gruppe')} variant={'primary'} />
          )}
        </div>
      </List>
    </>
  );

  const Beskrivelse = () => {
    return (
      <>
        <p>
          {getOrElse(tjeneste.beskrivelse, () => t('ts_beskrivelse_mangler'))}
        </p>
        {!isBlank(tjeneste.url) && (
          <LinkList>
            <h2>{t('ts_informasjon_tekst')}</h2>
            <LinkItem
              isExternalLink={true}
              link={tjeneste.url ?? ''}
              title={t('ts_tjeneste_lenke_tekst')}
            />
          </LinkList>
        )}
      </>
    );
  };

  const GroupedTjenester = () => {
    if (tjeneste.children?.length !== 0) {
      return (
        <>
          <h1>
            {t('ts_produktgruppe_tekst', {
              navn: tjeneste.navn
            })}
          </h1>
          <Grid key={tjeneste.id} columns={3}>
            {tjeneste.children?.map((children) => {
              return <TjenesteCard key={children.id} tjeneste={children} />;
            })}
          </Grid>
        </>
      );
    }
    return <></>;
  };

  return (
    <div className={css.TjenesteViewer}>
      <div className={css.Wrapper}>
        <header>
          {isChild() && <TjenesteCardHandling />}
          <List orientation={'horizontal'}>
            <h1>{tjeneste.navn}</h1>
            {isGroup() && <Tag text={t('gruppe')} variant={'primary'} />}
          </List>
          <span
            className={css.Produsent}
            onClick={() => history.push(produsentTjensterUrl())}
          >
            {produsent()}
          </span>
        </header>
        <section data-col-split={'8-4'}>
          <TjenesteDescriptionAndTagsAndImage tjeneste={tjeneste} />
        </section>
        <section>
          <TabSelector
            tabs={tabs}
            selected={tabs.findIndex((tab) => tab.id === activeTab)}
            onSelect={(index) => handleSelect(index)}
            className={css.TabSelector}
          />
          <div className={css.TabContent}>
            {activeTab === 'beskrivelse' && <Beskrivelse />}
            {activeTab === 'leverandoerer' && <LeverandoerListe />}
          </div>
        </section>
        <section className={css.TjenesteList}>
          {isGroup() && <GroupedTjenester />}
        </section>
      </div>
    </div>
  );
};
