import { Kategori, NewLeverandoer } from 'models';

export function exportToCSV(leverandorer: NewLeverandoer[]) {
  const getKategoriNames = (kategorier: Kategori[]) =>
    kategorier.map((kategori) => kategori.navn);

  const leverandoerData = [
    [
      'Navn',
      'Orgnr',
      // 'Beskrivelse',
      // 'URL',
      'Telefon (Stat)',
      //'Epost (Stat)',
      'Telefon (Kommune)',
      //'Epost (Kommune)',
      //'Annen kontakt',
      'Kategorier'
    ],
    ...leverandorer.map((leverandoer) => [
      leverandoer.navn ?? '-',
      leverandoer.orgnr ?? '-',
      //leverandoer.beskrivelse ?? '-',
      //leverandoer.url ?? '-',
      leverandoer.kontaktTelefonStat ?? '-',
      //leverandoer.kontaktEpostStat ?? '-',
      leverandoer.kontaktTelefonKommune ?? '-',
      //leverandoer.kontaktEpostKommune ?? '-',
      //leverandoer.kontaktOevrig ?? '-',
      getKategoriNames(leverandoer.kategorier as Kategori[]) ?? '-'
    ])
  ]
    .map((e) => e.join(','))
    .join('\n');

  const blob = new Blob([leverandoerData], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'leverandoerer.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
