import axios, { AxiosInstance } from 'axios';

import { CMS_API_BASE_URL } from 'shared-constants';

class DrupalError implements Error {
  public readonly name = 'DrupalError';

  constructor(public readonly code: number, public readonly message: string) {}
}

interface BasicAuth {
  username: string;
  password: string;
}

interface BearerAuth {
  token: string;
}

type Auth = BasicAuth | BearerAuth;

interface DrupalConfig {
  baseUrl: string;
  auth?: Auth;
}

const DrupalTypes = [
  'page',
  'agreement',
  'news',
  'process',
  'process_page',
  'landing_page'
] as const;

export type DrupalType = typeof DrupalTypes[number];

type DrupalTypeString = `node--${typeof DrupalTypes[number]}`;

type Link = {
  href: string;
};

interface Relationship {
  data: any | null;
  links: {
    self: Link;
    related?: Link;
  };
}

interface BaseNode {
  type: string;
  id: Uuid;
  attributes: {
    [key: string]: any;
  };
  relationships: {
    [key: string]: any;
  };
}

export interface ContentNode extends BaseNode {
  type: DrupalTypeString;
  id: Uuid;
  attributes: {
    created: Date;
    changed: Date;
    published_time: Date;
    title: string;
    field_intro_text: string | Body;
    body: Body;
    process: {
      process_id: string;
      current_depth: number;
    } | null;
    [key: string]: unknown;
  };
  relationships: {
    process_parent: Relationship;
    process_children: Relationship;
    [key: string]: Relationship;
  };

  [key: string]: unknown;
}

const TaxonomyTypes = ['agreement'] as const;

export type TaxonomyType = typeof TaxonomyTypes[number];

type TaxonomyTypeString = `taxonomy_term--${typeof TaxonomyTypes[number]}_type`;

export interface TaxonomyNode extends BaseNode {
  type: TaxonomyTypeString;
  id: Uuid;
  attributes: {
    name: string;
    [key: string]: unknown;
  };
}

const isTaxonomyNode = (val: BaseNode): val is TaxonomyNode =>
  TaxonomyTypes.includes(val.type.split('--')[1].split('_')[0] as TaxonomyType);

export interface DrupalResponse {
  data: ContentNode;
  meta: {
    [key: string]: unknown;
  };
  included?: BaseNode[];

  [key: string]: unknown;
}

export interface DrupalCollection {
  data: ContentNode[];
  meta: {
    count: string;
    [key: string]: unknown;
  };
  included?: BaseNode[];

  [key: string]: unknown;
}

export const NodeTypes = [
  'veiledning',
  'avtale',
  'nyheter',
  'process',
  'process-step',
  'landingsside'
] as const;
export type NodeType = typeof NodeTypes[number];

const mapToDrupalType: { [T in NodeType]: DrupalType } = {
  veiledning: 'page',
  avtale: 'agreement',
  nyheter: 'news',
  process: 'process',
  'process-step': 'process_page',
  landingsside: 'landing_page'
};

type UnsafeHtml = string;
export type Uuid = string;
export type DrupalInternalId = number;

interface Body {
  value: UnsafeHtml;
  format: 'gutenberg' | null;
  processed: UnsafeHtml;
}

type ProcessRef = {
  processId: string;
  depth: number;
};

export interface Node {
  id: DrupalInternalId;
  uuid: Uuid;
  type: NodeType;
  created: Date;
  changed: Date;
  published_time: Date;
  title: string;
  field_intro_text: string | Body;
  body: Body;
  steps: string[];
  process: ProcessRef | null;
  parentUuid: string | null;
  field_agreement_date?: string;
  field_agreement_status?: string;
  field_agreement_type?: Taxonomy | null;
  promote: boolean;
}

export interface Taxonomy {
  id: Uuid;
  name: string;
}

export interface Article extends Node {
  field_show_toc: boolean;
}

export interface Process extends Article {
  type: 'process' | 'process-step';
  process: ProcessRef;
}

export interface Agreement extends Node {
  type: 'avtale';
  field_agreement_date: string;
  field_agreement_status: string;
  field_agreement_type: Taxonomy | null;
}

export const isNumeric = (value: any): value is number =>
  value !== '' && (typeof value === 'number' || !isNaN(+value));

export const isInstanceOfBody = (value: any): value is Body =>
  (value as Body).processed !== undefined &&
  (value as Body).value !== undefined;

export const sortArticlesByPublishedTime = (node: Article[]): Article[] => {
  const valueOrInfinity = (val) =>
    val === null ? Infinity : new Date(val).getTime();

  const sorter = (a, b) => {
    return (
      b.promote - a.promote ||
      valueOrInfinity(b.published_time) - valueOrInfinity(a.published_time)
    );
  };
  return node.sort(sorter);
};

export class DrupalClient {
  readonly httpClient: AxiosInstance;

  constructor(private config: DrupalConfig) {
    this.httpClient = axios.create({
      baseURL: config.baseUrl.endsWith('/')
        ? `${config.baseUrl}node`
        : `${config.baseUrl}/node`,
      headers: this.requestHeaders(config)
    });
  }

  getAll<T extends Node>(type: NodeType, queryParams?: string[]): Promise<T[]> {
    return this.httpClient
      .get<DrupalCollection>(this.buildUrl(type, undefined, queryParams))
      .catch((r) =>
        Promise.reject(new DrupalError(r.response.status, r.response.message))
      )
      .then((response) => response.data)
      .then((drupalCollection) => {
        if (drupalCollection.data.length) {
          if (drupalCollection.included) {
            return drupalCollection.data.map((n) =>
              this.mapRichResponse<T>(n, drupalCollection.included || [], type)
            );
          }
          return drupalCollection.data.map((r) => this.mapResponse<T>(r, type));
        }
        return [];
      });
  }

  get<T extends Node>(
    type: NodeType,
    id: Uuid,
    queryParams?: string[]
  ): Promise<T> {
    return this.httpClient
      .get<DrupalResponse>(this.buildUrl(type, id, queryParams))
      .catch((r) =>
        Promise.reject(new DrupalError(r.response.status, r.response.message))
      )
      .then((r) => r.data)
      .then((drupalResponse) => {
        if (drupalResponse.included) {
          return this.mapRichResponse<T>(
            drupalResponse.data,
            drupalResponse.included,
            type
          );
        }
        return this.mapResponse<T>(drupalResponse.data, type);
      });
  }

  getByNid<T extends Node>(
    type: NodeType,
    id: DrupalInternalId,
    queryParams?: string[]
  ): Promise<T> {
    return this.httpClient
      .get<DrupalCollection>(
        this.buildUrl(type, id, [`filter[nid]=${id}`].concat(queryParams || []))
      )
      .catch((r) =>
        Promise.reject(new DrupalError(r.response.status, r.response.message))
      )
      .then((r) => {
        if (r.data.data.length) {
          return r.data;
        }
        throw new DrupalError(404, `No such node: ${id}`);
      })
      .then((drupalCollection) => {
        if (drupalCollection.included) {
          return this.mapRichResponse<T>(
            drupalCollection.data[0],
            drupalCollection.included,
            type
          );
        }
        return this.mapResponse<T>(drupalCollection.data[0], type);
      });
  }

  private buildUrl(
    type: NodeType,
    id?: Uuid | DrupalInternalId,
    queryParams?: string[]
  ): string {
    let url = `/${mapToDrupalType[type]}`;
    if (id && !isNumeric(id)) {
      url += `/${id}`;
    }
    if (queryParams && queryParams.length) {
      return `${url}?${queryParams.join('&')}`;
    }
    return url;
  }

  private requestHeaders(config: DrupalConfig): { [key: string]: any } {
    const headers = {
      'Content-Type': 'application/json'
    };
    if (config.auth) {
      headers['Authorization'] = this.toHttpAuthentication(config.auth);
    }
    return headers;
  }

  private toHttpAuthentication(auth: Auth): string {
    if (this.isBasicAuth(auth)) {
      const encoded = btoa(`${auth.username}:${auth.password}`);
      return `Basic ${encoded}`;
    }
    if (this.isBearerAuth(auth)) {
      return `Bearer ${auth.token}`;
    }
    throw new Error('Unsupported authentication scheme.');
  }

  private isBasicAuth(auth: Auth): auth is BasicAuth {
    const basicAuth = auth as BasicAuth;
    return basicAuth.password !== undefined && basicAuth.username !== undefined;
  }

  private isBearerAuth(auth: Auth): auth is BearerAuth {
    return (auth as BearerAuth).token !== undefined;
  }

  private mapResponse<T extends Node>(
    response: ContentNode,
    type: NodeType
  ): T {
    return {
      ...response.attributes,
      id: response.attributes.drupal_internal__nid,
      type: type,
      uuid: response.id,
      parentUuid: response.relationships.process_parent.data?.id || null,
      steps:
        response.relationships.process_children.data.map((i) => i.id) || [],
      process: response.attributes.process
        ? {
            processId: response.attributes.process.process_id,
            depth: response.attributes.process.current_depth
          }
        : null
    } as T;
  }

  private mapRichResponse<T extends Node>(
    node: ContentNode,
    included: BaseNode[],
    type: NodeType
  ): T {
    if (node.type === 'node--agreement') {
      return this.mapAgreement(included, node);
    } else {
      return this.mapResponse<T>(node, type);
    }
  }

  private mapAgreement<T extends Node>(
    included: BaseNode[],
    node: ContentNode
  ): T {
    const taxonomy = this.mapNestedTaxonomyNodes(included);
    return {
      ...this.mapResponse<T>(node, 'avtale'),
      field_agreement_type:
        taxonomy.find(
          (t) => t.id === node.relationships.field_agreement_type.data?.id
        ) ?? null
    };
  }

  private mapNestedTaxonomyNodes(nestedNodes: BaseNode[]): Taxonomy[] {
    return (
      nestedNodes
        ?.filter((n) => isTaxonomyNode(n))
        .map((n) => ({
          id: n.id,
          name: n.attributes.name
        })) || []
    );
  }
}

export const drupalClient = new DrupalClient({
  baseUrl: CMS_API_BASE_URL
});
