import { LEVERANDOERER_URL, OPPDRAGSGIVER_URL } from '../shared-constants';
import http, { buildUrl } from './http-common';
import { CreateOppdragsgiverRequest } from 'models/oppdragsgiver';
import { CreateNewLeverandoerRequest } from 'models';

export async function createOppdragsgiver(
  oppdragsgiver: CreateOppdragsgiverRequest,
  accessToken: string | undefined
) {
  try {
    const response = await http.post(
      buildUrl([OPPDRAGSGIVER_URL]),
      oppdragsgiver,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response.data.id;
  } catch (error) {
    console.log(error);
  }
}

export async function createLeverandoer(
  leverandoer: CreateNewLeverandoerRequest,
  accessToken: string | undefined
) {
  try {
    const response = await http.post(
      buildUrl([LEVERANDOERER_URL]),
      leverandoer,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    return response.data.id;
  } catch (error) {
    console.log(error);
  }
}
