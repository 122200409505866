import React from 'react';

import css from './Pagination.module.scss';

interface Props {
  label: string;
  children: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  onClick: () => void;
}

export default function PaginationButton(props: Props): React.ReactElement {
  const { children, disabled, label, selected, onClick } = props;

  const buttonClass = disabled ? css.disabled : selected ? css.selected : '';

  return (
    <button
      aria-label={label}
      className={`h-11 w-11 rounded-full ${buttonClass}`}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  );
}
