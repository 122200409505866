import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Step } from 'Components/Veileder/context';
import css from './Progress.module.scss';

type StepProps = {
  index: number;
  current: number;
  total: number;
  layoutWidth: number;
};

const ProgressStep = ({ index, current, total, layoutWidth }: StepProps) => {
  const [layout, setLayout] = useState<{
    left: string;
    width: string;
  }>({
    left: '0px',
    width: '0px'
  });
  const [classes, setClasses] = useState<string[]>([css.Step]);

  useEffect(() => {
    const stepWidth = layoutWidth / (total - 1);
    const xPosition = stepWidth * (index > 0 ? index - 1 : 0);
    setLayout({
      left: `${xPosition}px`,
      width: `${stepWidth}px`
    });
  }, [index, total, layoutWidth]);

  useEffect(() => {
    const _classes = [css.Step];
    if (current > index) {
      _classes.push(css.previous);
    }
    if (current === index) {
      _classes.push(css.current);
    }
    setClasses(_classes);
  }, [current, index]);

  return (
    <div className={classNames(classes)} style={layout}>
      <div className={css.dot}>{current === index && index + 1}</div>
    </div>
  );
};

type ProgressProps = {
  current?: Step;
  steps: Step[];
};

export const Progress = ({ current, steps }: ProgressProps) => {
  const progressRef = useRef<HTMLElement | null>(null);
  const [layoutWidth, setLayoutWidth] = useState<number>(0);
  const [stepElements, setStepElements] = useState<JSX.Element[]>([]);
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          setLayoutWidth(entry.contentRect.width);
        }
      }),
    []
  );

  useEffect(() => {
    const ref = progressRef.current;
    if (ref) {
      resizeObserver.observe(ref);
    }
    return () => {
      if (ref) {
        resizeObserver.unobserve(ref);
      }
      resizeObserver.disconnect();
    };
  }, [resizeObserver]);

  useEffect(() => {
    const getCurrentStepIndex = (): number => {
      if (current) {
        return steps.findIndex((s) => s.id === current?.id);
      }
      return -1;
    };
    const currentStepIndex = getCurrentStepIndex();
    setStepElements(
      new Array(steps.length).fill(0).map((_, i) => {
        return (
          <ProgressStep
            key={i}
            index={i}
            current={currentStepIndex}
            total={steps.length}
            layoutWidth={layoutWidth}
          />
        );
      })
    );
  }, [current, steps, layoutWidth]);

  return (
    <section ref={progressRef} className={css.Progress}>
      {stepElements}
    </section>
  );
};
