export interface Kategori {
  id: number;
  navn: string;
  parent?: Kategori | null;
  children: Kategori[];
}

export const initialKategori: Kategori = {
  id: 0,
  navn: '',
  children: []
};

export const findParentKategori = (
  hovedkategorier: Kategori[],
  kategoriId: number
): Kategori | undefined => {
  return hovedkategorier.find((k) => isParentKategori(k, kategoriId));
};

const isParentKategori = (parentCandidate: Kategori, childId: number) => {
  if (parentCandidate.children.length < 1) {
    return false;
  } else {
    return (
      parentCandidate.children.find((k) => k.id === childId) !== undefined ||
      parentCandidate.children.some((k) => isParentKategori(k, childId))
    );
  }
};
