import { useQuery } from 'react-query';

import unleashClient from 'shared/feature-toggle/Unleash';

const fetchFeatureToggles = async () => unleashClient.fetch();

export const useFeatureToggle = () => {
  const { data, isLoading } = useQuery('feature-toggles', fetchFeatureToggles, {
    notifyOnChangeProps: ['data'],
    staleTime: 10_000_000,
    refetchOnWindowFocus: false
  });

  const isInitialized = () => !isLoading;

  const isEnabled = (toggleName: string) => {
    if (data && data.toggles) {
      const toggle = data.toggles.find((t) => t.name === toggleName);
      return toggle !== undefined && toggle.enabled;
    }
    return false;
  };

  const allEnabled = (toggleNames: string[]) => {
    return toggleNames.every((toggleName) => isEnabled(toggleName));
  };

  return { isEnabled, allEnabled, isInitialized };
};
