import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import css from './VirksomhetListe.module.scss';
import { useVirksomheter } from 'shared/hooks/virksomhet-hooks';
import { ReactComponent as EditPencil } from '../images/edit-pencil.svg';
import { ReactComponent as ExportList } from '../images/export-list.svg';
import { Virksomhet, VirksomhetType } from 'models';

export default function VirksomhetListe(): ReactElement {
  const { t } = useTranslation('translation');
  const { data: virksomheter, isLoading } = useVirksomheter(); //TODO: Hent fra riktig endepunkt.
  const [filteredVirksomheter, setFilteredVirksomheter] = useState<
    Virksomhet[]
  >([]);

  useEffect(() => {
    if (!isLoading && !!virksomheter?.length) {
      setFilteredVirksomheter(virksomheter);
    }
  }, [isLoading, virksomheter]);

  const handleSearchChange = (e) => {
    const searchField = e.target.value;

    if (virksomheter) {
      const filterWithSearch = virksomheter?.filter((virksomhet) =>
        virksomhet.navn.toLowerCase().includes(searchField.toLowerCase())
      );
      setFilteredVirksomheter(filterWithSearch);
    }
  };

  const handleDownloadAsCSV = () => {
    //exportToCSV(filteredVirksomheter);
  };

  const getEncodedName = (name: string) => name.replace('/', '_');
  const getType = (type: VirksomhetType) =>
    type === VirksomhetType.LEVERANDOER ? t('leverandør') : t('virksomhet');

  return (
    <div className={css.RegistrerteLeverandorer}>
      <h1 className={css.RegistrerteLeverandorerTitle}>
        {t('registrerte_leverandorer')}
      </h1>

      <div className={css.RegistrerteLeverandorerFilter}>
        <input
          type="search"
          name={t('bc_sok')}
          placeholder={t('bc_sok') + ' ...'}
          onChange={handleSearchChange}
        />

        <div className={css.exportWrap} onClick={handleDownloadAsCSV}>
          <p>{t('eksport_list')}</p>
          <ExportList />
        </div>

        <div className={css.sort}>
          <label htmlFor={t('sorter')}>{t('sorter') + ':'}</label>
          <select name={t('sorter')} id={t('sorter')}>
            <option>{t('alfabetisk')}</option>
            <option>{t('nyeste')}</option>
            <option>{t('eldste')}</option>
            <option>{t('sist_endret_admin')}</option>
          </select>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>{t('navn')}</th>
            <th>{t('orgnr_short')}</th>
            <th>{t('type')}</th>
            <th>{t('nettside')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredVirksomheter.map((virksomhet) => {
            return (
              <tr key={virksomhet.id}>
                <td>{virksomhet.navn ? virksomhet.navn : 'Ikke oppgitt'}</td>
                <td>{virksomhet.orgnr ? virksomhet.orgnr : 'Ikke oppgitt'}</td>
                <td>
                  {virksomhet.type ? getType(virksomhet.type) : 'Ikke oppgitt'}
                </td>
                <td>{virksomhet.url ? virksomhet.url : 'Ikke oppgitt'}</td>
                <td>
                  <Link
                    to={`/admin/registrerte-leverandører/${getEncodedName(
                      virksomhet?.navn
                    )}?id=${virksomhet.id}&type=${virksomhet.type}`}
                  >
                    <EditPencil />
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
