import React from 'react';

import css from '../CmsNode.module.scss';
import { Article, isInstanceOfBody } from '../../shared/api/drupal';
import { sanitizeHtml } from '../../shared/sanitize-html';
import { Part } from './Part';

export const LeadParagraph = <T extends Article>({
  cmsNode
}: {
  cmsNode: T;
}) => {
  const getIntroText = (node: Article): string =>
    isInstanceOfBody(node.field_intro_text)
      ? node.field_intro_text.processed
      : node.field_intro_text;

  const sanitizeIntroText = (node: Article): string =>
    sanitizeHtml(getIntroText(node));
  return (
    <Part cmsNode={cmsNode} className={css.ingress}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeIntroText(cmsNode)
        }}
      />
    </Part>
  );
};
