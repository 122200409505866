import React, { ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import Toolbar, { ToolbarItem } from 'Components/Toolbar/Toolbar';
import { DFO, store } from 'store';
import css from './OversiktTjenesteSide.module.scss';
import {
  tjenesteQueryKeys,
  usePublishTjeneste,
  useTjenesteByVirksomhet,
  useUnpublishTjeneste
} from 'shared/hooks/tjeneste-hooks';
import Spinner from 'Components/Spinner/Spinner';
import LeverandoerTjenesteSide from '../LeverandoerTjenesteSide/LeverandoerTjenesteSide';
import ProdusentTjenesteSide from '../ProdusentTjenesteSide/ProdusentTjenesteSide';
import ConfirmDialog from 'Components/ConfirmDialog/ConfirmDialog';
import { useFeatureToggle } from '../shared/feature-toggle';
import { Features, FIRMAPROFIL_URL, TJENESTER_URL } from '../shared-constants';
import { useLeverandoererInfo } from 'shared/hooks/leverandoer-hooks';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import TjenesteListe from 'Components/TjenesteListe/TjenesteListe';
import { buildUrl } from 'shared/http-common';
import TjenesteDescriptionAndTagsAndImage from '../Components/TjenesteDescriptionAndTagsAndImage/TjenesteDescriptionAndTagsAndImage';
import { NotFound } from '../Components/NotFound';

export default function OversiktTjenesteSide(): ReactElement {
  const { isEnabled } = useFeatureToggle();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation('translation');
  const { activeVirksomhet } = useActiveVirksomhet();
  const [avpubliserRequested, setAvpubliserRequested] = useState(false);
  const {
    data: leverandoerer,
    isLoading: isLeverandoererLoading
  } = useLeverandoererInfo(id);
  const { unpublish, isLoading: isAvpubliser } = useUnpublishTjeneste();
  const { publish, isLoading: isPubliser } = usePublishTjeneste();
  const { data: tjeneste, isLoading, isError } = useTjenesteByVirksomhet(
    activeVirksomhet?.id || '',
    id
  );

  const queryClient = useQueryClient();

  const isProdusentAvTjenesten = (): boolean => {
    if (tjeneste === undefined || activeVirksomhet === undefined) {
      return false;
    }
    return tjeneste.produsent.id === activeVirksomhet.id;
  };

  const publiserTjeneste = () => {
    if (!isPubliser) {
      publish(activeVirksomhet?.id || '', id, new Date(), {
        onSuccess: () => {
          queryClient.invalidateQueries('tjenester');
        },
        onError: (error) => {
          store.dispatch(DFO.error(error, true));
        }
      });
    }
  };

  const avPubliserTjeneste = () => {
    if (!isAvpubliser) {
      unpublish(activeVirksomhet?.id || '', id, {
        onSuccess: () => {
          queryClient.invalidateQueries(tjenesteQueryKeys.byId(id));
        },
        onError: (error) => {
          store.dispatch(DFO.error(error, true));
        }
      });
    }
  };

  const toolbarItems: ToolbarItem[] = [];

  if (tjeneste?.parent && tjeneste.parent?.id) {
    toolbarItems.push({
      label: tjeneste.parent.navn,
      link: buildUrl([FIRMAPROFIL_URL, TJENESTER_URL, tjeneste.parent.id]),
      symbol: 'arrow-left'
    });
  } else {
    toolbarItems.push({
      label: t('ot_firmaprofil'),
      link: '/firmaprofil',
      symbol: 'arrow-left'
    });
  }

  toolbarItems.push({
    label: t('ot_forhandsvis'),
    link: '/firmaprofil/tjenester/' + id + '/forhåndsvisning',
    symbol: 'eye'
  });

  if (isProdusentAvTjenesten() && isEnabled(Features.TjenesteregisterSkjema)) {
    if (tjeneste?.publisert === null) {
      toolbarItems.push({
        label: t('ot_publiser'),
        symbol: 'rocket',
        action: tjeneste?.klarTilPublisering ? () => publiserTjeneste() : null
      });
    }

    if (tjeneste?.publisert !== null) {
      toolbarItems.push({
        label: t('ot_avpubliser'),
        symbol: 'pause-circle',
        action: () =>
          hasLeverandoerer()
            ? setAvpubliserRequested(true)
            : avPubliserTjeneste()
      });
    }
  }

  const showAvpubliserButton = () =>
    isProdusentAvTjenesten() &&
    tjeneste?.publisert !== null &&
    !isAvpubliser &&
    avpubliserRequested;

  const hasLeverandoerer = (): boolean =>
    !!leverandoerer && leverandoerer.length > 0;

  const Leverandorer = () =>
    leverandoerer
      ?.map((l) => {
        return l.virksomhet.navn;
      })
      .join(', ');

  if (isLoading || isLeverandoererLoading) {
    return <Spinner isActive={isLoading || isLeverandoererLoading} />;
  }

  if (isError) {
    return <NotFound />;
  }

  if (tjeneste) {
    return (
      <div className={css.OversiktTjenesteSide}>
        <header>
          <h1>{tjeneste?.navn}</h1>
          {!isProdusentAvTjenesten() && <p>{tjeneste?.produsent.navn}</p>}
          {showAvpubliserButton() && (
            <ConfirmDialog
              onConfirm={() => {
                avPubliserTjeneste();
                setAvpubliserRequested(false);
              }}
              onCancel={() => {
                setAvpubliserRequested(false);
              }}
            >
              <section>
                <p>
                  {t('ot_avpubliser_eksisterende_relasjon', {
                    count: leverandoerer?.length,
                    leverandoerer: Leverandorer()
                  })}
                </p>
                <p>{t('ot_bekreft_avpubliser')}</p>
              </section>
            </ConfirmDialog>
          )}
        </header>
        <Toolbar toolbarItems={toolbarItems} />
        <section data-col-split={'7-5'}>
          <TjenesteDescriptionAndTagsAndImage tjeneste={tjeneste} />
        </section>
        {tjeneste &&
          tjeneste.isGroup &&
          tjeneste.children?.length !== 0 &&
          tjeneste?.produsent.id && (
            <section>
              <h2>{t('ot_tjenester_i_gruppen')}</h2>
              <TjenesteListe
                produsentId={tjeneste.produsent.id}
                parentId={tjeneste.id}
                includeMeta={false}
                pathParts={['firmaprofil', 'tjenester']}
              />
            </section>
          )}
        {isEnabled(Features.TjenesteregisterSkjema) && (
          <section data-col-split={'7-5'}>
            <section>
              <h2>{t('ot_legg_til_mer_informasjon')}</h2>
              {isProdusentAvTjenesten() ? (
                <ProdusentTjenesteSide tjeneste={tjeneste} />
              ) : (
                <LeverandoerTjenesteSide />
              )}
            </section>
          </section>
        )}
      </div>
    );
  }

  return <></>;
}
