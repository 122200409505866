import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { MutateOptions } from 'react-query/types/core/types';

import {
  LeverandoerInfo,
  NewLeverandoer,
  UpdateLeverandoerData,
  VirksomhetType
} from 'models';
import http, { buildUrl } from '../http-common';
import { LEVERANDOERER_URL, TJENESTER_URL } from 'shared-constants';
import { DFO, store } from 'store';
import {
  authorizationHeaderFromToken,
  useAuthentication
} from 'shared/authentication';
import { useVirksomheter } from './virksomhet-hooks';

export const leverandoerQueryKeys = {
  root: () => ['leverandoerer'],
  byTjenesteId: (id?: string) => [...leverandoerQueryKeys.root(), id],
  byLeverandoerId: (tjenesteId: string, leverandoerId?: string) => [
    ...leverandoerQueryKeys.byTjenesteId(tjenesteId),
    leverandoerId
  ]
};

function useLeverandoerAllInfo(
  leverandoerId: string | null,
  type: string | null
) {
  async function getLeverandoerInfo() {
    return http
      .get(buildUrl([LEVERANDOERER_URL, leverandoerId ?? '']))
      .then((response: AxiosResponse<NewLeverandoer>) => response.data);
  }

  return useQuery<NewLeverandoer>(
    leverandoerQueryKeys.byLeverandoerId(leverandoerId ?? ''),
    getLeverandoerInfo,
    {
      onError: (error) => {
        store.dispatch(DFO.error(error, true));
      },
      enabled: !!leverandoerId && type === VirksomhetType.LEVERANDOER
    }
  );
}

function useGetAllLeverandoer() {
  async function getLeverandoerInfo() {
    return http
      .get(buildUrl([LEVERANDOERER_URL]))
      .then((response: AxiosResponse<[NewLeverandoer]>) => response.data);
  }

  return useQuery<NewLeverandoer[]>(
    leverandoerQueryKeys.byLeverandoerId(''),
    getLeverandoerInfo,
    {
      onError: (error) => {
        store.dispatch(DFO.error(error, true));
      }
    }
  );
}

function useLeverandoerInfo(tjenesteId: string, leverandoerId?: string) {
  async function getLeverandoerInfo() {
    return http
      .get(
        buildUrl([
          TJENESTER_URL,
          tjenesteId,
          LEVERANDOERER_URL,
          leverandoerId || ''
        ])
      )
      .then((response: AxiosResponse<LeverandoerInfo>) => response.data);
  }

  return useQuery<LeverandoerInfo>(
    leverandoerQueryKeys.byLeverandoerId(tjenesteId, leverandoerId),
    getLeverandoerInfo,
    {
      onError: (error) => {
        store.dispatch(DFO.error(error, true));
      },
      enabled: !!leverandoerId
    }
  );
}

function useLeverandoererInfo(tjenesteId: string) {
  async function getLeverandoererInfo() {
    return http
      .get(buildUrl([TJENESTER_URL, tjenesteId, LEVERANDOERER_URL]))
      .then((response: AxiosResponse<LeverandoerInfo[]>) => response.data);
  }

  return useQuery<LeverandoerInfo[]>(
    leverandoerQueryKeys.byTjenesteId(tjenesteId),
    getLeverandoererInfo,
    {
      onError: (error) => {
        store.dispatch(DFO.error(error, true));
      }
    }
  );
}

type CreateLeverandoerPayload = {
  tjenesteId: string;
  leverandoerId: string;
};

function useCreateLeverandoer() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(createLeverandoer);

  async function createLeverandoer(params: CreateLeverandoerPayload) {
    return http
      .post(
        buildUrl([TJENESTER_URL, params.tjenesteId, LEVERANDOERER_URL]),
        { virksomhetId: params.leverandoerId },
        { headers: authorizationHeaderFromToken(context.token) }
      )
      .then((response: AxiosResponse<LeverandoerInfo>) => response.data);
  }

  function create(
    tjenesteId: string,
    leverandoerId: string,
    options?: MutateOptions<LeverandoerInfo, unknown, CreateLeverandoerPayload>
  ) {
    return mutate({ tjenesteId, leverandoerId }, options);
  }

  return { create, isLoading };
}

type UpdateLeverandoerInfoPayload = {
  tjenesteId: string;
  leverandoerId: string;
  data: { beskrivelse?: string; url?: string };
};

function useUpdateLeverandoerInfo() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(putLeverandoerInfo);

  async function putLeverandoerInfo(params: UpdateLeverandoerInfoPayload) {
    return http
      .patch(
        buildUrl([
          TJENESTER_URL,
          params.tjenesteId,
          LEVERANDOERER_URL,
          params.leverandoerId
        ]),
        params.data,
        { headers: authorizationHeaderFromToken(context.token) }
      )
      .then((result: AxiosResponse<LeverandoerInfo>) => result.data);
  }

  function update(
    tjenesteId: string,
    leverandoerId: string,
    data: { beskrivelse?: string; url?: string },
    options?: MutateOptions<
      LeverandoerInfo,
      unknown,
      UpdateLeverandoerInfoPayload
    >
  ) {
    mutate({ tjenesteId, leverandoerId, data }, options);
  }

  return { update, isLoading };
}

async function patchPartialLeverandoer(payload) {
  return http
    .patch(
      buildUrl([
        TJENESTER_URL,
        payload.tjenesteId,
        LEVERANDOERER_URL,
        payload.virksomhetId
      ]),
      payload.leverandoerInfo
    )
    .then((result: AxiosResponse<LeverandoerInfo>) => result.data);
}

type UpdateLeverandoer = {
  token: string | undefined;
  id: string;
  data: UpdateLeverandoerData;
};

function useUpdateLeverandoer() {
  const { context } = useAuthentication();
  const { mutate, isLoading } = useMutation(putLeverandoerInfo);
  const { refetch } = useGetAllLeverandoer();
  const { refetch: refetchVirksomheter } = useVirksomheter();

  async function putLeverandoerInfo(params: UpdateLeverandoer) {
    return http
      .patch(buildUrl([LEVERANDOERER_URL, params.id]), params.data, {
        headers: authorizationHeaderFromToken(context.token)
      })
      .then((result: AxiosResponse<LeverandoerInfo>) => result.data)
      .finally(() => {
        refetch();
        refetchVirksomheter();
      });
  }

  function update(
    token: string | undefined,
    id: string,
    data: UpdateLeverandoerData
  ) {
    mutate({ token, id, data });
  }

  return { update, isLoading };
}

export {
  useLeverandoerInfo,
  useLeverandoerAllInfo,
  useLeverandoererInfo,
  useUpdateLeverandoerInfo,
  useUpdateLeverandoer,
  patchPartialLeverandoer,
  useCreateLeverandoer,
  useGetAllLeverandoer
};
