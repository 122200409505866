import React, { useLayoutEffect, useRef } from 'react';

import { Article } from 'shared/api/drupal';
import { sanitizeHtml } from 'shared/sanitize-html';
import css from 'CmsNode/CmsNode.module.scss';
import { Part } from './Part';

type BodyProps<T extends Article> = {
  cmsNode: T;
  setHeaderRefs?: (refs: Element[]) => void;
};

export const Body = <T extends Article>({
  cmsNode,
  setHeaderRefs
}: BodyProps<T>) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const sanitizeBodyText = (): string => sanitizeHtml(cmsNode.body.processed);

  useLayoutEffect(() => {
    if (setHeaderRefs) {
      const getHeaderElements = () => {
        if (bodyRef.current) {
          return Array.from(
            bodyRef.current.querySelectorAll('main > h1, main > h2').values()
          );
        }
        return [];
      };

      const getLinkListElements = () => {
        if (bodyRef.current) {
          return Array.from(
            bodyRef.current
              .querySelectorAll('main div[class=list__title]')
              .values()
          );
        }
        return [];
      };

      if (cmsNode) {
        // The title of a link list (the Gutenberg block "manuell liste") from Drupal appears as a H2, but it's actually
        // a div styled to look like H2. We need to add these elements to the list of headers.
        setHeaderRefs(getHeaderElements().concat(getLinkListElements()));
      } else {
        setHeaderRefs([]);
      }
    }
  }, [cmsNode, setHeaderRefs]);

  return (
    <Part cmsNode={cmsNode} className={css.main}>
      <div
        ref={bodyRef}
        dangerouslySetInnerHTML={{
          __html: sanitizeBodyText()
        }}
      />
    </Part>
  );
};
