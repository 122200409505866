import React from 'react';
import { render } from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { initializer } from 'init';
import App from './App';
import './i18n';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import AuthenticationProvider from './shared/authentication/AuthenticationProvider';
import { ActiveVirksomhetProvider } from './store/VirksomhetContext';

initializer.init();

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

render(
  <ActiveVirksomhetProvider>
    <QueryClientProvider client={queryClient}>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ActiveVirksomhetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
