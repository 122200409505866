import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tjeneste, TjenesteSource } from 'models';
import css from './TjenesteListe.module.scss';
import Spinner from '../Spinner/Spinner';
import { useTjenesterByVirksomhet } from 'shared/hooks/tjeneste-hooks';
import List from '../UI/List/List';
import Icon from 'Components/Icon/Icon';
import { buildUrl } from 'shared/http-common';
import Tag from '../UI/Tag/Tag';

interface Props {
  produsentId: string;
  parentId?: string | null;
  includeMeta?: boolean;
  pathParts?: string[];
}

export default function TjenesteListe({
  produsentId,
  parentId,
  includeMeta = true,
  pathParts = ['tjenester']
}: Props): ReactElement {
  const { t } = useTranslation('translation');
  const { data: tjenester, isLoading } = useTjenesterByVirksomhet(produsentId);

  const url = (tjeneste: Tjeneste): string => {
    return buildUrl([...pathParts, `${tjeneste.id}`]);
  };

  const TjenesteRows = () => {
    if (tjenester && tjenester.length > 0) {
      let filtrerteTjenester: Tjeneste[] = [];

      if (parentId) {
        filtrerteTjenester = tjenester.filter(
          (tjeneste) => tjeneste?.parent?.id === parentId
        );
      } else {
        filtrerteTjenester = tjenester.filter((tjeneste) => !tjeneste?.parent);
      }
      if (filtrerteTjenester && filtrerteTjenester.length > 0) {
        return (
          <>
            {filtrerteTjenester.map((tjeneste) => (
              <TjenesteRow key={tjeneste.id} tjeneste={tjeneste} />
            ))}
          </>
        );
      }
    }
    return <div>{t('ingen_tjenester_registrert')}</div>;
  };

  const TjenesteRow = ({ tjeneste }: { tjeneste: Tjeneste }) => (
    <Link to={url(tjeneste)} className={css.Row}>
      <List orientation={'horizontal'} alignment={'center'} spacing={'between'}>
        <List orientation={'horizontal'} alignment={'start'} gap={'lg'}>
          <h2>{tjeneste.navn}</h2>
          {includeMeta && <TjenesteMeta tjeneste={tjeneste} />}
        </List>
        <List orientation={'horizontal'} alignment={'end'} gap={'lg'}>
          <span>
            {tjeneste.klarTilPublisering
              ? t('vis_tjeneste')
              : t('fullfoer_utkast')}
          </span>
          {tjeneste.klarTilPublisering ? (
            <Icon symbol={'arrow-right'} />
          ) : (
            <Icon symbol={'pencil'} />
          )}
        </List>
      </List>
    </Link>
  );

  const TjenesteMeta = ({ tjeneste }: { tjeneste: Tjeneste }) => (
    <List orientation={'horizontal'} alignment={'start'} gap={'lg'}>
      {tjeneste.virksomhetTjenesteRelasjon &&
        tjeneste.virksomhetTjenesteRelasjon.map((relasjon) => (
          <Tag
            key={relasjon}
            text={t(`relasjon_${relasjon}`)}
            variant={'transparent'}
          />
        ))}
      {tjeneste.isGroup && <Tag text={t('gruppe')} variant={'transparent'} />}
      {tjeneste.source === TjenesteSource.Import && (
        <Tag text={t('source_import')} variant={'transparent'} />
      )}
      {tjeneste.publisert === null && (
        <Tag text={t('utkast')} variant={'transparent'} />
      )}
    </List>
  );

  return (
    <List orientation={'vertical'} gap={'none'} className={css.TjenesteListe}>
      {isLoading ? <Spinner isActive={isLoading} /> : <TjenesteRows />}
    </List>
  );
}
