import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { VeilederContextProvider } from 'Components/Veileder/context';
import { avtaleStatuser } from 'AvtaleListe/AvtaleListe';
import CmsNode from 'CmsNode/CmsNode';
import CmsNodes from 'CmsNodes/CmsNodes';
import { NotFound } from 'Components/NotFound';
import { Veileder } from 'Components/Veileder';
import Forside from 'Forside/Forside';
import LeverandoerSide from 'LeverandoerSide/LeverandoerSide';
import LeverandoerTjenesteSide from 'LeverandoerTjenesteSide/LeverandoerTjenesteSide';
import OversiktTjenesteSide from 'OversiktTjenesteSide/OversiktTjenesteSide';
import RedigerTjenesteSide from 'RedigerTjenesteSide/RedigerTjenesteSide';
import { DrupalArticleIds, Features } from 'shared-constants';
import ToggledRoute from 'shared/feature-toggle/ToggledRoute';
import SokeSide from 'SokeSide/SokeSide';
import { SearchContextProvider } from 'store';
import { tjenesteInformasjoner } from 'Components/TjenesteViewer';
import TjenesteSide from 'TjenesteSide/TjenesteSide';
import Firmaprofil from './Firmaprofil/Firmaprofil';
import ImporterTjenesterSide from './ImporterTjenesterSide/ImporterTjenesterSide';
import ImporterTjenesterStatusSide from './ImporterTjenesterSide/ImporterTjenesterStatusSide';
import { ProtectedRoute } from 'shared/authentication/ProtectedRoute';
import RegistrerTjenesteSide from './RegistrerTjenesteSide/RegistrerTjenesteSide';
import PreviewTjenesteSide from './PreviewTjenesteSide/PreviewTjenesteSide';
import MinSide from 'MinSide/MinSide';
import Dashboard from 'Dashboard/Dashboard';
import LeverandoerOversikt from 'LeverandoerOversikt/LeverandoerOversikt';
import VirksomhetListe from 'VirksomhetListe/VirksomhetListe';
import RegistrerTypeVirksomhet from 'RegistrerVirksomhetType/RegistrerVirksomhetType';
import MinProfil from 'MinProfil/MinProfil';
import RegistrerVirksomhetInfo from 'RegistrerVirksomhetInfo/RegistrerVirksomhetInfo';
import VirksomhetProfilSide from 'VirksomhetProfilSide/VirksomhetProfilSide';

const avtalerPaths: string[] = ['/avtaler'];
for (const status of avtaleStatuser) {
  avtalerPaths.push('/avtaler/' + status);
}

const tjenesteSidePaths: string[] = ['/tjenester/:id'];
const tjenestePreviewPaths: string[] = [
  '/firmaprofil/tjenester/:id/forhåndsvisning'
];
for (const type of tjenesteInformasjoner) {
  tjenesteSidePaths.push('/tjenester/:id/' + type);
  tjenestePreviewPaths.push(
    `/firmaprofil/tjenester/:id/forhåndsvisning/${type}`
  );
}

const Routes = () => (
  <Switch>
    <Route exact path={'/login'} component={Forside} />
    <Route exact path={'/om-markedsplassen'}>
      <CmsNode type={'veiledning'} id={DrupalArticleIds.OmMarkedsplassen} />
    </Route>
    <Route exact path={'/veiledning'}>
      <CmsNode type={'veiledning'} id={DrupalArticleIds.VeiledningForside} />
    </Route>
    <Route exact path={'/veiledning/veiledning-etter-schrems-ii'}>
      <CmsNode
        type={'landingsside'}
        id={DrupalArticleIds.VeiledningForBrukAvSkyEtterSchremsII}
      />
    </Route>
    <Route
      exact
      path={
        '/veiledning/veiledning-etter-schrems-ii/personvernrisiko-for-etterretning'
      }
    >
      <CmsNode type={'veiledning'} id={DrupalArticleIds.PersonvernRisiko} />
    </Route>
    <Route
      exact
      path={
        '/veiledning/veiledning-etter-schrems-ii/personvernrisiko-for-etterretning/:id'
      }
    >
      <CmsNode type={'veiledning'} />
    </Route>
    <Route exact path={'/veiledning/veiledning-etter-schrems-ii/:id'}>
      <CmsNode type={'veiledning'} />
    </Route>
    <ToggledRoute
      toggleName={Features.Veileder}
      exact
      path={'/veiledning/schrems/veileder'}
    >
      <VeilederContextProvider>
        <Veileder />
      </VeilederContextProvider>
    </ToggledRoute>
    <Route exact path={'/veiledning/skyreisen'}>
      <CmsNode type={'process'} id={DrupalArticleIds.Skyreisen} />
    </Route>
    <Route path={'/veiledning/skyreisen/:id+'}>
      <CmsNode type={'process-step'} />
    </Route>
    <Route exact path={'/nyheter'}>
      <CmsNodes type={'nyheter'} />
    </Route>
    <Route path={'/nyheter/:id+'}>
      <CmsNode type={'nyheter'} />
    </Route>
    <Route exact path={'/veiledning/:id'}>
      <CmsNode type={'veiledning'} />
    </Route>
    <Route exact path={'/tilslutning-til-avtalene'}>
      <CmsNode type={'veiledning'} id={DrupalArticleIds.TilslutningAvtalene} />
    </Route>
    <Route exact path={avtalerPaths}>
      <CmsNodes type={'avtale'} />
    </Route>
    <Route exact path={'/avtaler/:id'}>
      <CmsNode type={'avtale'} />
    </Route>
    <ToggledRoute
      toggleName={Features.LoggInn}
      exact
      path={[
        '/registrer',
        '/min-side/rediger',
        '/admin/registrerte-leverandører/:navn'
      ]}
    >
      <ProtectedRoute component={RegistrerVirksomhetInfo} />
    </ToggledRoute>

    <ToggledRoute toggleName={Features.LoggInn} exact path={'/type-aktor'}>
      <ProtectedRoute component={RegistrerTypeVirksomhet} />
    </ToggledRoute>
    <ToggledRoute toggleName={Features.LoggInn} exact path={['/', '/admin']}>
      <ProtectedRoute component={Dashboard} />
    </ToggledRoute>
    <ToggledRoute toggleName={Features.LoggInn} exact path={'/min-side'}>
      <ProtectedRoute component={MinSide} />
    </ToggledRoute>

    <ToggledRoute
      exact
      toggleName={Features.LoggInn}
      path={'/leverandøroversikt'}
    >
      <SearchContextProvider>
        <LeverandoerOversikt />
      </SearchContextProvider>
    </ToggledRoute>

    <ToggledRoute
      exact
      path={['/leverandøroversikt/:navn', '/min-side/preview']}
      toggleName={Features.LoggInn}
    >
      <ProtectedRoute component={VirksomhetProfilSide} />
    </ToggledRoute>

    <ToggledRoute
      toggleName={Features.LoggInn}
      exact
      path={'/admin/registrerte-leverandører'}
    >
      <SearchContextProvider>
        <ProtectedRoute component={VirksomhetListe} />
      </SearchContextProvider>
    </ToggledRoute>

    <ToggledRoute
      exact
      path={'/min-side/min-profil'}
      toggleName={Features.LoggInn}
    >
      <ProtectedRoute component={MinProfil} />
    </ToggledRoute>

    <ToggledRoute
      toggleNames={[Features.Tjenesteregister, Features.TjenesteregisterSkjema]}
      exact
      path={'/firmaprofil/tjenester/registrer'}
    >
      <ProtectedRoute component={RegistrerTjenesteSide} />
    </ToggledRoute>
    <ToggledRoute toggleName={Features.LoggInn} exact path={'/firmaprofil/'}>
      <ProtectedRoute component={Firmaprofil} />
    </ToggledRoute>
    <ToggledRoute
      toggleNames={[Features.Tjenesteregister, Features.ExcelImport]}
      exact
      path={'/firmaprofil/tjenester/import'}
    >
      <ProtectedRoute component={ImporterTjenesterSide} />
    </ToggledRoute>
    <ToggledRoute
      toggleNames={[Features.Tjenesteregister, Features.ExcelImport]}
      exact
      path={'/firmaprofil/tjenester/import/status'}
    >
      <ProtectedRoute component={ImporterTjenesterStatusSide} />
    </ToggledRoute>
    <ToggledRoute
      path={['/firmaprofil/tjenester/', '/tjenester/', '/sok']}
      toggleName={Features.Tjenesteregister}
    >
      <ToggledRoute
        toggleName={Features.Tjenesteregister}
        exact
        path={'/firmaprofil/tjenester/:id'}
      >
        <ProtectedRoute component={OversiktTjenesteSide} />
      </ToggledRoute>
      <ToggledRoute
        toggleName={Features.Tjenesteregister}
        exact
        path={tjenestePreviewPaths}
      >
        <ProtectedRoute component={PreviewTjenesteSide} />
      </ToggledRoute>
      <ToggledRoute
        toggleName={Features.Tjenesteregister}
        exact
        path={'/firmaprofil/tjenester/:id/selger'}
      >
        <ProtectedRoute component={LeverandoerTjenesteSide} />
      </ToggledRoute>
      <ToggledRoute
        toggleNames={[
          Features.Tjenesteregister,
          Features.TjenesteregisterSkjema
        ]}
        exact
        path={'/firmaprofil/tjenester/:id/rediger'}
      >
        <ProtectedRoute component={RedigerTjenesteSide} />
      </ToggledRoute>
      <ToggledRoute
        toggleName={Features.Tjenesteregister}
        exact
        path={tjenesteSidePaths}
      >
        <TjenesteSide />
      </ToggledRoute>
      <ToggledRoute
        toggleName={Features.Tjenesteregister}
        exact
        path={'/tjenester/:tjenesteId/leverandoerer/:leverandoerId'}
      >
        <LeverandoerSide />
      </ToggledRoute>
      <ToggledRoute toggleName={Features.Tjenesteregister} path={'/sok'}>
        <SearchContextProvider>
          <SokeSide />
        </SearchContextProvider>
      </ToggledRoute>
    </ToggledRoute>
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
