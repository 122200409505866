import React, { ReactElement, useEffect, useReducer, useState } from 'react';

import {
  findParentKategori,
  Kategori,
  TjenesteRequest,
  TjenesteUpdate
} from 'models';
import css from './TjenesteRegistrationForm.module.scss';
import { HovedkategoriSelector } from './controls/HovedkategoriSelector';
import { ImageUpload } from './controls/ImageUpload';
import { KategoriSelector } from './controls/KategoriSelector';
import { Text, TextArea } from './controls/FormField';
import { ResaleToggle } from './controls/ResaleToggle';

type FormProps = {
  initialTjeneste: Readonly<TjenesteRequest | TjenesteUpdate>;
  hovedkategorier: Kategori[];
  handleChange?: (tjeneste: Readonly<TjenesteRequest>) => void;
  currentImage?: string;
  handleImageChange?: (image: File) => void;
  isUploadingImage?: boolean;
};

export default function TjenesteRegistrationForm({
  initialTjeneste,
  hovedkategorier,
  handleChange,
  currentImage,
  handleImageChange,
  isUploadingImage = false
}: FormProps): ReactElement {
  const [hovedkategori, setHovedkategori] = useState<Kategori | undefined>();
  const tjenesteReducer = (
    tjeneste: TjenesteRequest,
    update: Partial<TjenesteRequest>
  ): TjenesteRequest => ({ ...tjeneste, ...update });
  const [tjeneste, dispatch] = useReducer(tjenesteReducer, initialTjeneste);

  useEffect(() => {
    dispatch(initialTjeneste);
  }, [initialTjeneste]);

  const _handleChange = (update: Partial<TjenesteRequest>) => {
    dispatch(update);
    if (handleChange) {
      handleChange({ ...tjeneste, ...update });
    }
  };

  const isTjenesteUpdate = (value: TjenesteRequest): value is TjenesteUpdate =>
    value['id'] !== undefined;

  useEffect(() => {
    if (initialTjeneste.kategoriId) {
      setHovedkategori(
        findParentKategori(hovedkategorier, initialTjeneste.kategoriId)
      );
    }
  }, [initialTjeneste.kategoriId, hovedkategorier]);

  return (
    <div className={css.TjenesteRegistrationForm}>
      <div className={css.Wrapper}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Text
            labelKey={'rts_navn'}
            placeholderKey={'rts_navn_placeholder'}
            fieldName={'navn'}
            initialValue={tjeneste.navn ?? undefined}
            handleBlur={_handleChange}
          />
          {!isTjenesteUpdate(initialTjeneste) && (
            <HovedkategoriSelector
              kategorier={hovedkategorier || []}
              handleChange={(kategoriId) =>
                setHovedkategori(
                  hovedkategorier.find((k) => k.id === kategoriId)
                )
              }
            />
          )}
          {hovedkategori && (
            <KategoriSelector
              selectedKategoriId={tjeneste.kategoriId}
              handleChange={_handleChange}
              kategorier={hovedkategori?.children ?? []}
            />
          )}
          {tjeneste.kategoriId && (
            <>
              <TextArea
                labelKey={'kort_beskrivelse'}
                placeholderKey={'rts_beskrivelse_placeholder'}
                hintKey={'rts_beskrivelse_veiledning'}
                fieldName={'kortBeskrivelse'}
                initialValue={tjeneste.kortBeskrivelse ?? undefined}
                maxLength={300}
                handleBlur={_handleChange}
              />
              <Text
                labelKey={'lenke'}
                placeholderKey={'rts_lenke_placeholder'}
                hintKey={'rts_lenke_veiledning'}
                fieldName={'url'}
                initialValue={tjeneste.url ?? undefined}
                handleBlur={_handleChange}
              />
              {isTjenesteUpdate(initialTjeneste) && (
                <ImageUpload
                  initialImage={currentImage}
                  handleChange={handleImageChange}
                  isLoading={isUploadingImage}
                />
              )}
              <ResaleToggle
                handleChange={_handleChange}
                value={tjeneste.resaleEnabled}
              />
            </>
          )}
        </form>
      </div>
    </div>
  );
}
