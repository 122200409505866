import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import css from './MinSide.module.scss';
import { ReactComponent as EditSVG } from '../images/edit.svg';
import { ReactComponent as PreviewSVG } from '../images/preview.svg';
import { ReactComponent as InfoNumber } from '../images/infoNumber.svg';
import { ReactComponent as InfoEmail } from '../images/infoEmail.svg';
import { useLogin } from 'shared/authentication';
import { useGetAllLeverandoer } from 'shared/hooks/leverandoer-hooks';
import { useGetAllOppdragsgivere } from 'shared/hooks/oppdragsgiver-hooks';
import { VirksomhetType } from 'models';
import { getLink } from 'shared/get-link';

export default function MinSide(): ReactElement {
  const { t } = useTranslation('translation');
  const { user } = useLogin();

  const [virksomhet, setVirksomhet] = useState<any>();
  const [initials, setInitials] = useState('');

  const { data: leverandorer } = useGetAllLeverandoer();
  const { data: oppdragsgivere } = useGetAllOppdragsgivere();

  useEffect(() => {
    window.scrollTo(0, 0);
    getInitials();
    if (user && user.virksomhet) {
      getVirksomhet(user.virksomhet.orgnr.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, leverandorer, oppdragsgivere]);

  const getVirksomhet = (orgnr: string) => {
    if (leverandorer && oppdragsgivere) {
      const leverandoer = leverandorer?.find((item) => item.orgnr === orgnr);
      const oppdragsgiver = oppdragsgivere?.find(
        (item) => item.orgnr === orgnr
      );
      setVirksomhet(leverandoer ?? oppdragsgiver);
    }
  };

  const getInitials = () => {
    const parts = user?.name.split(' ');
    let initials = '';
    if (parts) {
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== '') {
          initials += parts[i][0];
        }
      }
    }
    setInitials(initials);
  };

  return (
    <>
      <h1 className={css.minSideTitle}>{t('min_side_tittel')}</h1>
      {user && (
        <div className={css.minSide}>
          {virksomhet && (
            <div>
              <div className={css.companyProfile_ButtonWrapper}>
                <h4 className={css.subtitle}>{t('nav_min_virksomhet')}</h4>

                <Link
                  to={`/min-side/rediger?id=${virksomhet?.id}&type=${virksomhet?.type}`}
                >
                  <p className={css.buttonTitle}>{t('rediger')}</p>
                  <EditSVG />
                </Link>
                <Link
                  to={`/min-side/preview?id=${virksomhet?.id}&type=${virksomhet?.type}`}
                >
                  <p className={css.buttonTitle}>{t('preview')}</p>
                  <PreviewSVG />
                </Link>
              </div>

              <section>
                <h2 className={css.companyName}>{virksomhet?.navn}</h2>
                <br />
                <p>{virksomhet?.beskrivelse}</p>
              </section>

              {virksomhet?.type === VirksomhetType.LEVERANDOER && (
                <>
                  <section>
                    <h4 className={css.subtitle}>{t('kategorier')}</h4>
                    <ul className={css.kategori_list}>
                      {virksomhet?.kategorier.map((kategori) => (
                        <li key={kategori.id} className={css.kategori_chip}>
                          {kategori.navn}
                        </li>
                      ))}
                    </ul>
                  </section>
                  <section>
                    <h4 className={css.subtitle}>
                      {t('statlig_kontakt_info_tittel')}
                    </h4>
                    <div className={css.contactInfoWrap}>
                      <InfoNumber />
                      <p> {virksomhet?.kontaktTelefonStat}</p>
                    </div>
                    <div className={css.contactInfoWrap}>
                      <InfoEmail />
                      <p> {virksomhet?.kontaktEpostStat}</p>
                    </div>
                  </section>
                  <section>
                    <h4 className={css.subtitle}>
                      {t('kommunalt_kontakt_info_tittel')}
                    </h4>
                    <div className={css.contactInfoWrap}>
                      <InfoNumber />
                      <p> {virksomhet?.kontaktTelefonKommune}</p>
                    </div>
                    <div className={css.contactInfoWrap}>
                      <InfoEmail />
                      <p>{virksomhet?.kontaktEpostKommune}</p>
                    </div>
                  </section>
                </>
              )}

              {virksomhet?.type === VirksomhetType.OPPDRAGSGIVER && (
                <section>
                  <h4 className={css.subtitle}>{t('hovedkontakt')}</h4>
                  <div className={css.contactInfoWrap}>
                    <InfoNumber />
                    <p> {virksomhet?.kontaktTelefon}</p>
                  </div>
                  <div className={css.contactInfoWrap}>
                    <InfoEmail />
                    <p>{virksomhet?.kontaktEpost}</p>
                  </div>
                </section>
              )}

              {virksomhet?.url && (
                <section>
                  <h4 className={css.subtitle}>{t('nettside')}</h4>
                  <a
                    target="_blank"
                    href={getLink(virksomhet.url)}
                    rel="noreferrer"
                  >
                    {virksomhet.url}
                  </a>
                </section>
              )}
              {virksomhet?.kontaktOevrig && (
                <section>
                  <h4 className={css.subtitle}>{t('ovrig_kontakt_info')}</h4>
                  {virksomhet.kontaktOevrig}
                </section>
              )}
            </div>
          )}

          <div>
            <div className={css.minProfil_ButtonWrapper}>
              <h4 className={css.subtitle}>{t('min_profil')}</h4>

              <Link to={'/min-side/min-profil'}>
                <p className={css.buttonTitle}>{t('rediger')}</p>
                <EditSVG />
              </Link>
            </div>

            <div className={css.nameWrap}>
              <p className={css.initials}>{initials}</p>
              <h4>{user?.name}</h4>
            </div>
            <p>{user?.email}</p>
          </div>
        </div>
      )}
    </>
  );
}
