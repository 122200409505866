import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import css from './ImporterTjenesterSide.module.scss';
import Icon from 'Components/Icon/Icon';
import {
  DrupalArticleIds,
  EXCEL_MAL_URL,
  FIRMAPROFIL_URL,
  TJENESTER_IMPORT_STATUS_URL,
  TJENESTER_IMPORT_URL,
  TJENESTER_URL,
  VIRKSOMHETER_URL
} from 'shared-constants';
import Toolbar from 'Components/Toolbar/Toolbar';
import http, { buildUrl } from 'shared/http-common';
import { useActiveVirksomhet } from 'store/VirksomhetContext';
import {
  authorizationHeaderFromToken,
  useAuthentication
} from 'shared/authentication';
import Spinner from 'Components/Spinner/Spinner';

interface ErrorObject {
  code: number;
  message: string;
}

export default function ImporterTjenesterSide(): ReactElement {
  const { context } = useAuthentication();
  const { t } = useTranslation('translation');
  const history = useHistory();
  const { activeVirksomhet } = useActiveVirksomhet();
  const [importFile, setImportFile] = useState<File | undefined>(undefined);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<ErrorObject | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  const acceptedFileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const importUrl = activeVirksomhet
    ? buildUrl([
        VIRKSOMHETER_URL,
        `${activeVirksomhet.id}`,
        TJENESTER_URL,
        TJENESTER_IMPORT_URL
      ])
    : '';

  const resetImport = useCallback(() => {
    if (isMounted) {
      setImportFile(undefined);
      setIsUploading(false);
      setUploadError(null);
    }
  }, [isMounted]);

  const importTjenester = useCallback(() => {
    if (activeVirksomhet?.id && importFile) {
      const formData = new FormData();
      formData.append('file', importFile);
      formData.append('fileName', importFile.name);

      http
        .post(importUrl, formData, {
          headers: authorizationHeaderFromToken(context.token)
        })
        .then((response) => {
          if (response.status === 201) {
            resetImport();
            history.push(
              buildUrl([
                FIRMAPROFIL_URL,
                TJENESTER_URL,
                TJENESTER_IMPORT_URL,
                TJENESTER_IMPORT_STATUS_URL
              ])
            );
          }
        })
        .catch((error) => {
          resetImport();
          setUploadError(error.response.data);
        });
    }
  }, [
    activeVirksomhet,
    importFile,
    importUrl,
    history,
    context.token,
    resetImport
  ]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    if (importFile && acceptedFileType !== importFile?.type) {
      setImportFile(undefined);
    }
  }, [importFile, setImportFile]);

  useEffect(() => {
    if (importFile && !isUploading && acceptedFileType === importFile?.type) {
      setIsUploading(true);
      setUploadError(null);
      importTjenester();
    }
  }, [importFile, isUploading, importTjenester]);

  const dragOverHandler = (e) => {
    e.preventDefault();
  };

  const dropHandler = (e): void => {
    e.preventDefault();

    if (e.dataTransfer.items && !!e.dataTransfer.items[0]) {
      setImportFile(e.dataTransfer.items[0].getAsFile());
    }
  };

  const InnerDropZone = () => {
    return (
      <div className={css.DropZoneInner}>
        {importFile ? (
          <>
            <p className={css.FileName}>
              {t('import_filnavn_er_valgt', {
                filnavn: importFile.name
              })}
            </p>
            <p>- {t('import_gaa_videre_eller_velg_annen_fil')} -</p>
          </>
        ) : (
          <>
            <p>{t('import_dra_fil_hit')}</p>
            <p>- {t('eller')} -</p>
          </>
        )}
        <label className={css.InputFile}>
          {t('velg_fil')}
          <input
            type={'file'}
            id={'excelFile'}
            name={'file'}
            accept={acceptedFileType}
            aria-required={true}
            multiple={false}
            onChange={(e) => {
              if (e.target.files && !!e.target.files[0]) {
                setImportFile(e.target.files[0]);
                importTjenester();
              }
            }}
          />
        </label>
      </div>
    );
  };

  const ImportInProgress = () => {
    return (
      <div className={css.InProgress}>
        <Spinner isActive={true} />
        <p>{t('import_importerer')}</p>
      </div>
    );
  };

  return (
    <div className={css.ImporterTjenesterSide}>
      <header>
        <h1>{t('import_importer_tjenester_og_tjenestegrupper')}</h1>
      </header>
      <Toolbar
        toolbarItems={[
          {
            label: t('ot_firmaprofil'),
            link: '/firmaprofil',
            symbol: 'arrow-left'
          }
        ]}
      />
      <main>
        <section>
          <h2>{t('import_importer_fra_regneark')}</h2>
          <ol>
            <li>{t('import_importer_fra_regneark_tekst_1')}</li>
            <li>{t('import_importer_fra_regneark_tekst_2')}</li>
            <li>{t('import_importer_fra_regneark_tekst_3')}</li>
          </ol>
          <p>
            <em>{t('merk').toUpperCase()}: </em>
            {t('import_importer_fra_regneark_merk')}
          </p>
        </section>
        <section>
          <p className={css.VeiledningLink}>
            <Link
              to={'/veiledning/' + DrupalArticleIds.ImporterTjenester}
              target={'_blank'}
            >
              {t('import_se_brukerveiledning')}
            </Link>
          </p>
        </section>
        <section className={css.DownloadTemplateWrapper}>
          <Link
            to={EXCEL_MAL_URL}
            className={css.DownloadTemplate}
            target={'_blank'}
          >
            {t('import_last_ned_mal')}
            <Icon symbol={'download'} tabIndex={-1} />
          </Link>
        </section>
        <section>
          <h3>{t('import_last_opp_utfylt_mal')}</h3>
        </section>
        {uploadError && (
          <section className={css.UploadError}>
            <h3>{t('import_feilet')}</h3>
            <p>{uploadError.message}</p>
          </section>
        )}
        <section>
          <label
            htmlFor={'excelFile'}
            className={css.DropZone}
            onDrop={(e) => dropHandler(e)}
            onDragOver={(e) => dragOverHandler(e)}
          >
            {isUploading ? <ImportInProgress /> : <InnerDropZone />}
          </label>
          <p className={css.Hint}>{t('import_last_opp_hint')}</p>
        </section>
      </main>
    </div>
  );
}
