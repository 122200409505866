import React from 'react';

import css from '../CmsNode.module.scss';
import { Article } from '../../shared/api/drupal';

export const Title = <T extends Article>({
  cmsNode,
  altAvtaleType
}: {
  cmsNode: T;
  altAvtaleType: string;
}) => {
  const AvtaleType = () => (
    <p className={css.agreementType}>
      {cmsNode?.field_agreement_type?.name || altAvtaleType}
    </p>
  );

  return (
    <header className={css.header}>
      <h1>{cmsNode.title}</h1>
      {cmsNode.type === 'avtale' && <AvtaleType />}
    </header>
  );
};
