import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useFeatureToggle } from 'shared/feature-toggle';

interface ToggledRouteProps {
  toggleName?: string;
  toggleNames?: string[];
  children: React.ReactNode;
  [key: string]: any;
}

const ToggledRoute = ({
  toggleName,
  toggleNames,
  children,
  ...props
}: ToggledRouteProps) => {
  const { isEnabled, allEnabled } = useFeatureToggle();

  if (toggleNames && toggleNames.length > 0) {
    return (
      <Route {...props}>
        {allEnabled(toggleNames) ? children : <Redirect to="/" />}
      </Route>
    );
  }

  if (toggleName) {
    return (
      <Route {...props}>
        {isEnabled(toggleName) ? children : <Redirect to="/" />}
      </Route>
    );
  }

  return (
    <Route {...props}>
      <Redirect to="/" />
    </Route>
  );
};

export default ToggledRoute;
