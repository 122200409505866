import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import css from './VirksomhetProfilSide.module.scss';
import { ReactComponent as Home } from '../images/home.svg';
import { ReactComponent as InfoNumber } from '../images/infoNumber.svg';
import { ReactComponent as InfoEmail } from '../images/infoEmail.svg';
import { useLeverandoerAllInfo } from 'shared/hooks/leverandoer-hooks';
import { useOppdragsgiver } from 'shared/hooks/oppdragsgiver-hooks';
import { VirksomhetType } from 'models/VirksomhetType';
import { NewLeverandoer, Oppdragsgiver } from 'models';
import { getLink } from 'shared/get-link';

export default function VirksomhetProfilSide() {
  const { t } = useTranslation('translation');
  const { search } = useLocation(); //TODO: Bruke location state om du er Admin.
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const id = searchParams.get('id');
  const type = searchParams.get('type');
  const isLeverandoer = type === VirksomhetType.LEVERANDOER;
  const { data: leverandoerInfo } = useLeverandoerAllInfo(id, type);
  const { data: oppdragsgiverInfo } = useOppdragsgiver(id, type);
  const [virksomhet, setVirksomhet] = useState<
    NewLeverandoer | Oppdragsgiver | undefined
  >(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setVirksomhet(leverandoerInfo ?? oppdragsgiverInfo);
  }, [leverandoerInfo, oppdragsgiverInfo]);

  return (
    <div className={css.LeverandoerProfilSide}>
      <header>
        <h1>{virksomhet?.navn}</h1>
      </header>

      <main className={css.container}>
        {/* <section className={css.profilSide_description}>
          <h3>{t('beskrivelse')}</h3>
          <br />
          <p>{virksomhet?.beskrivelse}</p>
        </section> */}

        {/* {isLeverandoer && (
          <div className={css.kategori_container}>
            {(virksomhet as NewLeverandoer)?.kategorier.map((kategori) => (
              <p key={kategori.id} className={css.profilSide_kategori}>
                {kategori.navn}
              </p>
            ))}
          </div>
        )} */}

        {/* {virksomhet?.url && (
          <div className={css.profilSide_link}>
            <Home />
            <a target="_blank" href={getLink(virksomhet?.url)} rel="noreferrer">
              {virksomhet?.url}
            </a>
          </div>
        )} */}
      </main>

      <div className={css.profilSide_contactWrapper}>
        <div className={css.profilSide_contactBox}>
          <h4>{t('statlig_kontakt_info_tittel')}</h4>
          <div className={css.profilSide_contactInfoWrap}>
            <InfoNumber />
            <p>
              {isLeverandoer
                ? (virksomhet as NewLeverandoer)?.kontaktTelefonStat
                : (virksomhet as Oppdragsgiver)?.kontaktTelefon}
            </p>
          </div>
          <div className={css.profilSide_contactInfoWrap}>
            <InfoEmail />
            <p>
              {isLeverandoer
                ? (virksomhet as NewLeverandoer)?.kontaktEpostStat
                : (virksomhet as Oppdragsgiver)?.kontaktEpost}
            </p>
          </div>
        </div>
        {isLeverandoer && (
          <div className={css.profilSide_contactBox}>
            <h4>{t('statlig_kontakt_info_tittel')}</h4>
            <div className={css.profilSide_contactInfoWrap}>
              <InfoNumber />
              <p> {(virksomhet as NewLeverandoer)?.kontaktTelefonKommune}</p>
            </div>
            <div className={css.profilSide_contactInfoWrap}>
              <InfoEmail />
              <p>{(virksomhet as NewLeverandoer)?.kontaktEpostKommune}</p>
            </div>
          </div>
        )}
      </div>

      {/* <div className={css.profilSide_otherInfo}>
        <h3>{t('ovrig_kontakt_info')}</h3>
        <p>{virksomhet?.kontaktOevrig}</p>
      </div> */}
    </div>
  );
}
