import React from 'react';

import { Header } from 'Components/Veileder/Components/Header';
import { Progress } from 'Components/Veileder/Components/Progress';
import { Question } from 'Components/Veileder/Components/Question';
import { Report } from 'Components/Veileder/Components/Report';
import { restart, useVeilederContext } from 'Components/Veileder/context';
import css from './Veileder.module.scss';

export const Veileder = () => {
  const { veilederState, dispatch } = useVeilederContext();
  const isIntroStep = () =>
    veilederState.current?.id === veilederState.intro.id;

  return (
    <div className={css.Veileder}>
      <Header isIntroStep={isIntroStep()} onRestart={() => restart(dispatch)} />
      {!isIntroStep() && veilederState.current && (
        <Progress current={veilederState.current} steps={veilederState.steps} />
      )}
      {veilederState.current ? <Question /> : <Report />}
    </div>
  );
};
