import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'Components/Icon/Icon';
import css from './Header.module.scss';

type HeaderProps = {
  isIntroStep: boolean;
  onRestart: () => void;
};

export const Header = ({ isIntroStep, onRestart }: HeaderProps) => {
  const { t } = useTranslation('veileder');

  return (
    <header className={css.Header}>
      {isIntroStep ? (
        <h1>{t('title')}</h1>
      ) : (
        <nav>
          <button onClick={onRestart}>
            <Icon symbol={'arrow-left'} />
            {t('nav_back')}
          </button>
          <h1>{t('title')}</h1>
        </nav>
      )}
    </header>
  );
};
