import React, { createContext, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { sanitizeHtml } from 'shared/sanitize-html';

type SetPageTitle = (title: string) => void;
type SetPageDescription = (description: string) => void;
export const PageHeadContext = createContext<
  | {
      pageTitle: string;
      setPageTitle: SetPageTitle;
      pageDescription: string;
      setPageDescription: SetPageDescription;
    }
  | undefined
>(undefined);

export const PageHeadProvider = ({ children }: { children }) => {
  const pageTitleSuffix = process.env['REACT_APP_NAME']
    ? process.env['REACT_APP_NAME']
    : '';
  const [pageTitle, setPageTitle] = useState<string>('');
  const [pageDescription, setPageDescription] = useState<string>('');

  const value = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription
  };
  return (
    <PageHeadContext.Provider value={value}>
      <Helmet
        titleTemplate={`%s \u2013 ${pageTitleSuffix}`}
        defaultTitle={pageTitleSuffix}
      >
        {!!pageTitle && <title>{pageTitle}</title>}
        <meta
          property="og:title"
          content={
            pageTitle
              ? `${pageTitle} \u2013 ${pageTitleSuffix}`
              : pageTitleSuffix
          }
        />

        <meta
          name="description"
          content={
            pageDescription
              ? sanitizeHtml(pageDescription, true)
              : pageTitleSuffix
          }
        />
        {!!pageDescription && (
          <meta
            property="og:description"
            content={sanitizeHtml(pageDescription, true)}
          />
        )}
      </Helmet>
      {children}
    </PageHeadContext.Provider>
  );
};

export const usePageHeadContext = () => {
  const context = useContext(PageHeadContext);
  if (context === undefined) {
    throw new Error(
      'usePageHeadContext must be used within a PageHeadProvider'
    );
  }
  return context;
};
