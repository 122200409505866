import React from 'react';
import { useTranslation } from 'react-i18next';

export interface TimeProps {
  title: string;
  date: Date;
}

export function Time(props: TimeProps) {
  const { t } = useTranslation('translation');
  return (
    <time dateTime={props.date.toString()}>
      <span>{props.title} </span>
      <span>
        {t('dato_lang', {
          dato: new Date(props.date)
        })}
      </span>
    </time>
  );
}
