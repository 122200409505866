import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Kategori } from 'models';
import css from './KategoriSelector.module.scss';

type KategoriSelectorProps = {
  textKey?: string;
  selectedKategoriId?: number;
  kategorier: Kategori[];
  handleChange?: (kategoriId?: number) => void;
  handleBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
};

export const KategoriSelector = ({
  textKey = 'rt_velg_kategori',
  selectedKategoriId,
  kategorier,
  handleChange,
  handleBlur
}: KategoriSelectorProps) => {
  const { t } = useTranslation('translation');
  const [selectedKategori, setSelectedKategori] = useState<
    Kategori | undefined
  >(undefined);

  useEffect(() => {
    if (selectedKategoriId) {
      const kategori = kategorier.find((k) => k.id === selectedKategoriId);
      if (kategori) {
        setSelectedKategori(kategori);
      } else {
        setSelectedKategori(
          kategorier
            .filter((k) => k.children.length > 0)
            .find((k) => k.children.find((c) => c.id === selectedKategoriId))
        );
      }
    }
  }, [selectedKategoriId, kategorier]);

  const _handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const kategoriId = +event.target.value;
    setSelectedKategori(kategorier.find((k) => k.id === kategoriId));
    if (handleChange) {
      handleChange(kategoriId);
    }
  };

  const _handleBlur = (event: FocusEvent<HTMLSelectElement>) => {
    if (handleBlur) {
      handleBlur(event);
    }
  };

  const Options = () => (
    <>
      {[<option key={'blank'} value={'-1'} />].concat(
        kategorier.map((k) => (
          <option key={k.id} value={k.id}>
            {t(`kat_${k.id}`)}
          </option>
        ))
      )}
    </>
  );

  const hasChildren = () =>
    selectedKategoriId &&
    selectedKategori &&
    selectedKategori.children.length > 0;

  const Children = () => (
    <KategoriSelector
      textKey={'rt_velg_underkategori'}
      selectedKategoriId={selectedKategoriId}
      kategorier={selectedKategori?.children || []}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
  );

  const getSelectedValue = () => {
    if (selectedKategori) {
      return selectedKategori.id;
    }
    return selectedKategoriId;
  };

  const Hint = () => {
    const hint = t(`${textKey}_hint`, '');
    return hint.length > 0 ? <p>{hint}</p> : null;
  };

  return (
    <>
      <label>
        {t(textKey)}
        <select
          className={css.KategoriSelector}
          name={'kategorier'}
          value={getSelectedValue()}
          onChange={_handleChange}
          onBlur={_handleBlur}
        >
          <Options />
        </select>
      </label>
      <Hint />
      {hasChildren() && <Children />}
    </>
  );
};
