import { useEffect, useState } from 'react';
import { useIsFetching, useQuery, useQueryClient } from 'react-query';

import { Article, drupalClient, isNumeric, NodeType } from 'shared/api/drupal';

const fetchNode = async <T extends Article>(type: NodeType, id?: string) => {
  const includeRelationships =
    type === 'avtale' ? ['field_agreement_type'] : [];
  if (id) {
    if (isNumeric(id)) {
      return await drupalClient.getByNid<T>(
        type,
        id,
        includeRelationships.map((c) => `include=${c}`)
      );
    } else {
      return await drupalClient.get<T>(
        type,
        id,
        includeRelationships.map((c) => `include=${c}`)
      );
    }
  } else {
    return await drupalClient.getAll<T>(
      type,
      includeRelationships.map((c) => `include=${c}`)
    );
  }
};

const cmsKeys = {
  root: () => ['cms'],
  byType: (type: NodeType) => [...cmsKeys.root(), type],
  byId: (type: NodeType, id?: string) => [...cmsKeys.byType(type), id]
};

const staleTime = 5 * 60 * 1000; // 5 minutes staletime

export const useCmsByType = <T extends Article>(type: NodeType) => {
  const { data, isLoading, isError } = useQuery<T[]>(
    cmsKeys.byType(type),
    () => (fetchNode(type) as unknown) as T[],
    { staleTime }
  );

  return { cmsNodes: data, isLoading, isError };
};

export const useCmsById = <T extends Article>(type: NodeType, id?: string) => {
  const enabled = !!id;
  const { data, isLoading, isError } = useQuery<T>(
    cmsKeys.byId(type, id),
    () => (fetchNode(type, id) as unknown) as T,
    { staleTime, enabled }
  );
  return { cmsNode: data, isLoading, isError };
};

export const useCmsCache = <T extends Article>() => {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching(cmsKeys.root());
  const [cache, setCache] = useState<T[]>([]);

  useEffect(() => {
    if (isFetching === 0) {
      const queryData = queryClient
        .getQueriesData<T>(cmsKeys.root())
        .reduce((acc, current) => {
          const article = current[1];
          if (article) {
            acc.push(article);
          }
          return acc;
        }, [] as T[]);
      setCache(queryData);
    }
  }, [isFetching, queryClient]);

  return { cache };
};
