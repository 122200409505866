import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';

import keycloakInstance from '../../keycloak';
import { useCreateVirksomhet } from '../hooks/virksomhet-hooks';
import { removeWhitespace } from '../string-utils';
import {
  CheckExternalAvailability,
  ResourceStatus
} from 'Components/CheckExternalAvailability';
import {
  AuthenticationContext as AuthContextModel,
  KeycloakProfile,
  Roles
} from './model';
import { useFeatureToggle } from 'shared/feature-toggle';
import { Features } from '../../shared-constants';

type AuthenticationComponentProps = {
  setContext: (context: AuthContextModel) => void;
  doLogin: boolean;
  doLogout: boolean;
  children: JSX.Element;
};

const KeycloakAuthentication = ({
  setContext,
  doLogin = false,
  doLogout = false,
  children
}: AuthenticationComponentProps) => {
  const { keycloak, initialized } = useKeycloak();
  const { create } = useCreateVirksomhet();

  const loadUserInfo = useCallback(() => {
    if (keycloak.idTokenParsed && keycloak.tokenParsed) {
      const idToken = keycloak.idTokenParsed as KeycloakProfile;
      setContext({
        user: {
          virksomhet: {
            navn: idToken.company?.name || '',
            orgnr: +removeWhitespace(idToken.company?.orgNumber || '')
          },
          name: idToken.name,
          email: idToken.email,
          roles: keycloak.tokenParsed['roles']
        },
        token: keycloak.token
      });
    }
  }, [
    setContext,
    keycloak.token,
    keycloak.tokenParsed,
    keycloak.idTokenParsed
  ]);

  const loadUser = useCallback(() => {
    if (keycloak.authenticated) {
      loadUserInfo();
    }
  }, [keycloak.authenticated, loadUserInfo]);

  const login = useCallback(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login().then(() => {
          loadUser();
        });
      } else {
        loadUser();
      }
    }
  }, [keycloak, initialized, loadUser]);

  const logout = useCallback(() => {
    if (keycloak.authenticated) {
      const redirectUri = process.env.REACT_APP_KEYCLOAK_POST_LOGOUT_URI;
      keycloak.logout({ redirectUri }).then(() => keycloak.clearToken());
    }
  }, [keycloak]);

  useEffect(() => {
    if (doLogin) {
      login();
    } else {
      loadUser();
    }
  }, [doLogin, login, loadUser]);

  useEffect(() => {
    if (doLogout) {
      logout();
    }
  }, [doLogout, logout]);

  useEffect(() => {
    if (keycloak.idTokenParsed) {
      const virksomhet = parseVirksomhet(
        keycloak.idTokenParsed as KeycloakProfile
      );
      if (virksomhet && keycloak.token) {
        create(virksomhet, keycloak.token);
      }
    }
  }, [keycloak.token, keycloak.idTokenParsed, create]);

  const parseVirksomhet = (profile: KeycloakProfile) => {
    return {
      navn: profile.company?.name || '',
      orgnr: +removeWhitespace(profile.company?.orgNumber || '')
    };
  };
  return children;
};

type AuthenticationContextProps = {
  isEnabled: () => boolean;
  context: AuthContextModel;
  isAuthenticated: () => boolean;
  isAuthorized: () => boolean;
  isAdministrator: () => boolean;
  logout: () => void;
  login: () => void;
};

const AuthenticationContext = createContext<
  AuthenticationContextProps | undefined
>(undefined);

export default function AuthenticationProvider({
  children
}: {
  children: JSX.Element;
}) {
  const { isEnabled } = useFeatureToggle();
  const [context, setContext] = useState<AuthContextModel>({});
  const [doLogout, setDoLogout] = useState(false);
  const [doLogin, setDoLogin] = useState(false);
  const [authServerStatus, setAuthServerStatus] = useState<
    ResourceStatus | undefined
  >();
  const value = {
    isEnabled: () => isEnabled(Features.LoggInn) && authServerStatus === 'up',
    context,
    isAuthenticated: () => context.token !== undefined,
    isAuthorized: () =>
      (context.user?.roles &&
        context.user.roles.some((r) => Roles.includes(r))) ||
      false,
    isAdministrator: () =>
      (context.user?.roles && context.user.roles.includes('mp_admin')) || false,
    login: () => setDoLogin(true),
    logout: () => setDoLogout(true)
  };
  const resourceUrl = `/api/users/status`;

  if (!isEnabled(Features.LoggInn)) {
    return (
      <AuthenticationContext.Provider value={value}>
        {children}
      </AuthenticationContext.Provider>
    );
  }

  return (
    <AuthenticationContext.Provider value={value}>
      <CheckExternalAvailability
        onStatusChange={(status) => setAuthServerStatus(status)}
        resourceUrl={resourceUrl}
        availableComponent={
          <ReactKeycloakProvider
            authClient={keycloakInstance}
            initOptions={{
              onLoad: 'check-sso',
              checkLoginIframe: false,
              pkceMethod: 'S256'
            }}
            onTokens={(tokens) =>
              setContext({ ...context, token: tokens.token })
            }
          >
            <KeycloakAuthentication
              setContext={setContext}
              doLogin={doLogin}
              doLogout={doLogout}
            >
              {children}
            </KeycloakAuthentication>
          </ReactKeycloakProvider>
        }
        unavailableComponent={children}
      />
    </AuthenticationContext.Provider>
  );
}

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      'useAuthentication must be used within AuthenticationProvider.'
    );
  }
  return context;
};

export const useLogin = () => {
  const {
    context,
    isAuthenticated,
    isAuthorized,
    isAdministrator,
    login
  } = useAuthentication();

  useEffect(() => {
    login();
  }, [login]);

  return { user: context.user, isAuthenticated, isAuthorized, isAdministrator };
};

export const authorizationHeaderFromToken = (token: string | undefined) => {
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};
