import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import {
  CreateNewLeverandoerRequest,
  Kategori,
  UpdateLeverandoerData,
  VirksomhetType
} from 'models';
import css from './RegistrerVirksomhetInfo.module.scss';
import { removeWhitespace } from '../shared/string-utils';
import { useKategorier } from 'shared/hooks/kategori-hooks';
import { useLogin } from 'shared/authentication';
import {
  CreateOppdragsgiverRequest,
  Oppdragsgiver
} from 'models/oppdragsgiver';
import { createLeverandoer, createOppdragsgiver } from 'shared/create';
import {
  useLeverandoerAllInfo,
  useUpdateLeverandoer
} from 'shared/hooks/leverandoer-hooks';
import {
  useOppdragsgiver,
  useUpdateOppdragsgiver
} from 'shared/hooks/oppdragsgiver-hooks';

function RegistrerVirksomhetInfo(): ReactElement {
  const { data } = useKategorier();
  const history = useHistory();
  const { t } = useTranslation('translation');
  const { keycloak } = useKeycloak();
  const { user } = useLogin();

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const id = searchParams.get('id');
  const type = searchParams.get('type');

  const { data: leverandoer } = useLeverandoerAllInfo(id, type);
  const { data: oppdragsgiver } = useOppdragsgiver(id, type);
  const { update: updateLeverandoer } = useUpdateLeverandoer();
  const { update: updateOppdragsgiver } = useUpdateOppdragsgiver();

  const { pathname } = useLocation();
  const isEditPage = pathname?.includes('rediger');
  const isAdminPage = pathname?.includes('admin');

  const isLeverandoer = type === VirksomhetType.LEVERANDOER;

  const [virksomhet, setVirksomhet] = useState<any>({});

  const orgNr = isAdminPage
    ? virksomhet?.orgnr ?? ''
    : user?.virksomhet?.orgnr?.toString() ?? '';

  const navn = isAdminPage
    ? virksomhet?.navn ?? ''
    : user?.virksomhet?.navn ?? '';

  const [selectedCategories, setSelectedCategories] = useState<Kategori[]>([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);

  const [formData, setFormData] = useState({
    detail: virksomhet?.beskrivelse ?? '',
    url: virksomhet?.url ?? '',
    stateNum: virksomhet?.kontaktTelefonStat ?? '',
    stateEmail: virksomhet?.kontaktEpostStat ?? '',
    munNum: virksomhet?.kontaktTelefonKommune ?? '',
    munEmail: virksomhet?.kontaktEpostKommune ?? '',
    otherInfo: virksomhet?.kontaktOevrig ?? '',
    kategorier: virksomhet?.kategorier ?? []
  });
  // const [file, setFile] = useState('');
  // const [showErrorFile, setShowErrorFile] = useState<string>();

  useEffect(() => {
    setVirksomhet(leverandoer ?? oppdragsgiver);
    setFormData((prevFormData) => ({
      ...prevFormData,
      detail: virksomhet?.beskrivelse ?? '',
      url: virksomhet?.url ?? '',
      stateNum: virksomhet?.kontaktTelefonStat ?? '',
      stateEmail: virksomhet?.kontaktEpostStat ?? '',
      munNum: virksomhet?.kontaktTelefonKommune ?? '',
      munEmail: virksomhet?.kontaktEpostKommune ?? '',
      otherInfo: virksomhet?.kontaktOevrig ?? '',
      kategorier: virksomhet?.kategorier ?? []
    }));

    if (virksomhet?.kategorier) {
      setSelectedCategories(virksomhet.kategorier);
      setSelectedCategoryIds(
        virksomhet.kategorier.map((kategori) => kategori.id)
      );
    }
  }, [virksomhet, leverandoer, oppdragsgiver]);

  const removeCategory = (categoryId: number) => {
    const updatedCategories = selectedCategories.filter(
      (kategori) => kategori.id !== categoryId
    );
    setSelectedCategories(updatedCategories);

    const updatedCategoryIds = selectedCategoryIds.filter(
      (id) => id !== categoryId
    );
    setSelectedCategoryIds(updatedCategoryIds);
  };

  const Options = () => {
    const filteredCategories = data
      .filter((kategoriData) => kategoriData.id === 47)
      .flatMap((kategoriData) => kategoriData.children);

    return (
      <>
        <option key={'0'}>{t('kategori_placeholder')}</option>
        {filteredCategories.map((kategori) => (
          <option key={kategori.id} id={kategori.id.toString()}>
            {kategori.navn}
          </option>
        ))}
      </>
    );
  };

  const registerVirksomhet = async () => {
    const leverandoerFields = {
      navn: user?.virksomhet?.navn,
      orgnr: removeWhitespace(orgNr),
      beskrivelse: formData.detail,
      url: formData.url,
      kontaktOevrig: formData.otherInfo,
      kontaktTelefonStat: formData.stateNum,
      kontaktEpostStat: formData.stateEmail,
      kontaktTelefonKommune: formData.munNum,
      kontaktEpostKommune: formData.munEmail,
      kategorier: selectedCategoryIds
    } as CreateNewLeverandoerRequest;

    const oppdragsgiverFields = {
      navn: user?.virksomhet?.navn,
      orgnr: removeWhitespace(orgNr),
      beskrivelse: formData.detail,
      url: formData.url,
      kontaktOevrig: formData.otherInfo,
      kontaktTelefon: formData.stateNum,
      kontaktEpost: formData.stateEmail
    } as CreateOppdragsgiverRequest;

    isLeverandoer
      ? await createLeverandoer(leverandoerFields, keycloak.token)
      : await createOppdragsgiver(oppdragsgiverFields, keycloak.token);

    history.push('/min-side');
  };

  const updateVirksomhet = async () => {
    const updatedLeverandoerFields: UpdateLeverandoerData = {
      beskrivelse: formData.detail,
      url: formData.url,
      kontaktOevrig: formData.otherInfo,
      kontaktTelefonStat: formData.stateNum,
      kontaktEpostStat: formData.stateEmail,
      kontaktTelefonKommune: formData.munNum,
      kontaktEpostKommune: formData.munEmail,
      kategorier: selectedCategoryIds
    };
    const updatedOppdragsgiverFields: Oppdragsgiver = {
      id: virksomhet?.id,
      navn: virksomhet?.navn,
      orgnr: removeWhitespace(orgNr),
      type: VirksomhetType.OPPDRAGSGIVER,
      beskrivelse: formData.detail,
      sistEndretDato: new Date().toISOString(),
      url: formData.url,
      kontaktOevrig: formData.otherInfo,
      kontaktTelefon: formData.stateNum,
      kontaktEpost: formData.stateEmail
    };

    isLeverandoer
      ? updateLeverandoer(
          keycloak.token,
          virksomhet.id,
          updatedLeverandoerFields
        )
      : updateOppdragsgiver(keycloak.token, updatedOppdragsgiverFields);

    isAdminPage
      ? history.push('/admin/registrerte-leverandører')
      : history.push('/min-side');
  };

  // const handleFileUpload = (e) => {
  //   const getLogo = e.target.files[0];

  //   if (getLogo.size > 10000) {
  //     const logo = URL.createObjectURL(getLogo);
  //     setFile(logo);
  //     setShowErrorFile('');

  //     //TODO: Senere lagre til databasen og ikke sessions
  //     window.sessionStorage.setItem('logo', logo);
  //   } else {
  //     window.alert(t('kriterier_logo'));
  //     setFile('');
  //     setShowErrorFile(t('kriterier_logo'));
  //   }
  // };

  const isEnabled = (): boolean => {
    return !!user?.virksomhet?.navn; //&& !showError; // && !showErrorFile; //TODO && !isLoading; : Når hooks er klart , kan vi også bruke isLoading.
  };

  const kategoriChange = (e) => {
    const index = e.target.selectedIndex;
    const optionElementId = e.target.childNodes[index]?.id;

    const selectedCategory = data
      .filter((kategoriData) => kategoriData.id === 47)
      .flatMap((kategoriData) => kategoriData.children)
      .find((kategori) => kategori.id === +optionElementId);

    if (
      selectedCategory &&
      !selectedCategories.some(
        (kategori) => kategori.id === selectedCategory.id
      )
    ) {
      setSelectedCategories((prevCategories) => [
        ...prevCategories,
        selectedCategory
      ]);
      setSelectedCategoryIds((prevIds) => [...prevIds, +optionElementId]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const getPageTitle = () => {
    if (isEditPage || isAdminPage) {
      return t('rediger');
    } else if (isLeverandoer) {
      return t('ny_leverandor');
    } else {
      return t('ny_virksomhet');
    }
  };

  return (
    <div className={css.RegistrerVirksomhetInfo}>
      <h1>{getPageTitle()}</h1>
      {user && (
        <form>
          <label>
            {t('orgnr')}
            <input
              type={'number'}
              placeholder={t('orgnr_placeholder')}
              name={t('orgnr')}
              value={orgNr}
              disabled
              autoFocus={true}
              aria-label={t('orgnr')}
            />
          </label>
          <label>
            {t('navn')}
            <input
              type="text"
              value={navn}
              name={t('navn')}
              aria-label={t('navn')}
              disabled
            />
          </label>
          {isLeverandoer && (
            <label>
              {t('kategori')}
              <select
                name={t('kategori')}
                aria-label={t('kategori')}
                onChange={kategoriChange}
              >
                <Options />
              </select>
              <div className={css.kategori_container}>
                {selectedCategories.map((kategori) => (
                  <p
                    className={css.kategori}
                    key={kategori.id}
                    onClick={() => removeCategory(kategori.id)}
                  >
                    {kategori.navn}
                  </p>
                ))}
              </div>
            </label>
          )}
          <label>
            {t('beskrivelse')}
            <textarea
              name="detail"
              aria-label={t('beskrivelse')}
              value={formData.detail}
              onChange={handleInputChange}
            />
            <span>{t('beskrivelse_placeholder')}</span>
          </label>
          <label>
            {t('lenke')}
            <input
              type="text"
              placeholder={t('lenke_placeholder')}
              name="url"
              aria-label={t('lenke')}
              value={formData.url}
              onChange={handleInputChange}
            />
          </label>
          <h3>
            {isLeverandoer ? t('statlig_kontakt_tittel') : t('hovedkontakt')}
          </h3>
          <br />
          <label>
            {t('telefon')}
            <input
              type="tel"
              placeholder={t('telefon_placeholder')}
              name="stateNum"
              aria-label={t('telefon')}
              value={formData.stateNum}
              onChange={handleInputChange}
            />
          </label>
          <label>
            {t('epost')}
            <input
              type="email"
              placeholder={t('epost_placeholder')}
              name="stateEmail"
              aria-label={t('epost')}
              value={formData.stateEmail}
              onChange={handleInputChange}
            />
          </label>
          {isLeverandoer && (
            <>
              <h3>{t('kommunale_kontakt_tittel')}</h3>
              <br />
              <label>
                {t('telefon')}
                <input
                  type="tel"
                  placeholder={t('telefon_placeholder')}
                  name="munNum"
                  aria-label={t('telefon')}
                  value={formData.munNum}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                {t('epost')}
                <input
                  type="email"
                  placeholder={t('epost_placeholder')}
                  name="munEmail"
                  aria-label={t('epost')}
                  value={formData.munEmail}
                  onChange={handleInputChange}
                />
              </label>
            </>
          )}
          <label>
            {t('ovrig_kontakt_info')}
            <textarea
              placeholder={t('ovrig_kontakt_info_placeholder')}
              name="otherInfo"
              aria-label={t('ovrig_kontakt_info')}
              value={formData.otherInfo}
              onChange={handleInputChange}
            />
          </label>

          {/* <label>
          {t('last_opp_logo')} - {t('kriterier_logo')}
          <input
            type="file"
            name={t('logo')}
            aria-label={t('logo')}
            accept="image/*"
            onChange={handleFileUpload}
          />
        </label>
        <img src={file} /> */}
          {/* {showErrorFile && <span>{showErrorFile}</span>} */}

          {isEditPage || isAdminPage ? (
            <button
              type={'button'}
              disabled={!isEnabled()}
              onClick={() => updateVirksomhet()}
              aria-label={t('save_new_changes')}
            >
              {t('save_new_changes')}
            </button>
          ) : (
            <button
              type={'button'}
              disabled={!isEnabled()}
              onClick={() => registerVirksomhet()}
              aria-label={t('registrer')}
            >
              {t('registrer')}
            </button>
          )}
        </form>
      )}
    </div>
  );
}

export default RegistrerVirksomhetInfo;
