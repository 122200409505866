import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AvtaleListe from 'AvtaleListe/AvtaleListe';
import Card from 'Components/Card/Card';
import { NotFound } from 'Components/NotFound';
import Spinner from 'Components/Spinner/Spinner';
import { Agreement, NodeType } from 'shared/api/drupal';
import { buildUrl } from 'shared/http-common';
import { useCmsByType } from 'store/CmsQuery';
import { usePageHeadContext } from 'store/PageHeadContext';
import css from './CmsNodes.module.scss';
import NyheterSide from '../Components/NyheterSide/NyheterSide';
import LinkItem from '../Components/LinkList/LinkItem';
import LinkList from '../Components/LinkList/LinkList';

interface CmsNodeProps {
  type: NodeType;
}

export default function CmsNodes(props: CmsNodeProps): ReactElement {
  const { type } = props;
  const { t } = useTranslation('translation');
  const { cmsNodes, isLoading, isError } = useCmsByType(type);
  const { setPageTitle, setPageDescription } = usePageHeadContext();

  const isAvtale = type === 'avtale';
  const isVeiledning = type === 'veiledning';
  const isNyhet = type === 'nyheter';

  useEffect(() => {
    setPageTitle(t(`${type}_overskrift`));
    setPageDescription(t(`${type}_ingress`));
  }, [setPageTitle, setPageDescription, t, type]);

  const cmsNodeUrl = (cmsNode): string => {
    return buildUrl([type, cmsNode.id]);
  };

  const nodeList = () => {
    if (cmsNodes) {
      return cmsNodes.map((cmsNode) => {
        return (
          <LinkList key={cmsNode.id}>
            <LinkItem
              isExternalLink={false}
              link={cmsNodeUrl(cmsNode)}
              title={cmsNode.title}
            />
          </LinkList>
        );
      });
    }
  };

  if (isError) {
    return <NotFound />;
  }

  if (cmsNodes && cmsNodes.length) {
    return (
      <div className={css.CmsNodes}>
        <header>
          <h1>{t(`${type}_overskrift`)}</h1>
        </header>

        <main data-cy={'cms-content'}>
          <section className={css.ingress}>
            <p>{t(`${type}_ingress`)}</p>
          </section>

          <Spinner isActive={isLoading && !cmsNodes.length} />
          {isAvtale && (
            <AvtaleListe
              avtaler={cmsNodes as Agreement[]}
              isLoading={isLoading}
            />
          )}

          {isNyhet && (
            <section className={css.Nyheter}>
              <NyheterSide nyheter={cmsNodes} isLoading={isLoading} />
            </section>
          )}

          {!isAvtale && !isNyhet && <section>{nodeList()}</section>}
        </main>
        <aside>
          {isVeiledning && (
            <figure>
              <img
                src={'/images/spoersmaalstegn.svg'}
                alt={t('spoersmaalstegn_illustrasjon')}
              />
            </figure>
          )}

          {isAvtale && (
            <Card>
              <p>{t('avtale_informasjon_om_deltagelse_ingress')}</p>
              <p>
                <Link to={'/tilslutning-til-avtalene'}>
                  {t('avtale_informasjon_om_deltagelse_lenke')}
                </Link>
              </p>
            </Card>
          )}
        </aside>
      </div>
    );
  } else {
    return <></>;
  }
}
