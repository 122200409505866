import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import css from './LeverandoerOversikt.module.scss';
import LeverandoerResults from './LeverandoerResults/LeverandoerResults';
import { useGetAllLeverandoer } from 'shared/hooks/leverandoer-hooks';
import { NewLeverandoer } from 'models';
import LeverandoerKategorier from './LeverandoerKategorier/LeverandoerKategorier';

export default function LeverandoerOversikt(): ReactElement {
  const { t } = useTranslation('translation');

  const { data: leverandoerInfo, isLoading } = useGetAllLeverandoer();
  const [leverandoerer, setLeverandoerer] = useState<NewLeverandoer[]>([]);
  const [originalLeverandoerer, setOriginalLeverandoerer] = useState<
    NewLeverandoer[]
  >([]);
  const [searchItem, setSearchItem] = useState('');
  const [kategorier, setKategorier] = useState<string[]>([]);

  useEffect(() => {
    if (!isLoading && leverandoerInfo) {
      setLeverandoerer(leverandoerInfo);
      setOriginalLeverandoerer(leverandoerInfo);
    }
  }, [isLoading, leverandoerInfo]);

  useEffect(() => {
    const filteredItems = originalLeverandoerer.filter((leverandoer) => {
      const leverandoerKategorier = leverandoer.kategorier.map(
        (kategori) => kategori.navn
      );
      const isSearchMatch =
        searchItem === '' ||
        leverandoer.navn.toLowerCase().includes(searchItem.toLowerCase());
      const isKategorierMatch =
        kategorier.length === 0 ||
        leverandoerKategorier.some((k) => kategorier.includes(k));

      return isSearchMatch && isKategorierMatch;
    });

    setLeverandoerer(filteredItems);
  }, [searchItem, kategorier, originalLeverandoerer]);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    const filteredItems = originalLeverandoerer.filter((leverandoer) =>
      leverandoer.navn.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setLeverandoerer(filteredItems);
  };

  return (
    <div className={css.container}>
      <h1 className={css.title}>{t('leverandor_oversikt')}</h1>
      <input
        type="search"
        name={t('bc_sok')}
        id={t('bc_sok')}
        value={searchItem}
        placeholder={t('bc_sok') + ' ...'}
        onChange={handleInputChange}
      />
      <div className={css.kategori_wrapper}>
        {kategorier.map((kategori) => (
          <p key={kategori} className={css.kategori}>
            {kategori}
          </p>
        ))}
      </div>
      <div className={css.leverandoer_oversikt}>
        <LeverandoerKategorier setKategorier={setKategorier} />
        <LeverandoerResults leverandoer={leverandoerer} />
      </div>
    </div>
  );
}
