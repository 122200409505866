import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';

import css from './Dashboard.module.scss';
import { ReactComponent as ArrowRight } from '../images/arrow-right-primary.svg';
import { useAuthentication } from 'shared/authentication';
import { useGetAllLeverandoer } from 'shared/hooks/leverandoer-hooks';
import { useGetAllOppdragsgivere } from 'shared/hooks/oppdragsgiver-hooks';

export default function Dashboard(): ReactElement {
  const { t } = useTranslation('translation');
  const { pathname } = useLocation();
  const isAdminPage = pathname.includes('admin');
  const history = useHistory();
  const { context } = useAuthentication();
  const { data: leverandorer } = useGetAllLeverandoer();
  const { data: oppdragsgivere } = useGetAllOppdragsgivere();

  useEffect(() => {
    const orgNr = context.user?.virksomhet?.orgnr.toString();
    if (!isAdminPage && orgNr && leverandorer && oppdragsgivere) {
      const leverandoer = leverandorer?.find((item) => item.orgnr === orgNr);
      const oppdragsgiver = oppdragsgivere?.find(
        (item) => item.orgnr === orgNr
      );

      if (!leverandoer && !oppdragsgiver) {
        history.replace('/type-aktor');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAdminPage,
    leverandorer,
    oppdragsgivere,
    context?.user?.virksomhet?.orgnr
  ]);

  return (
    <div className={css.Dashboard}>
      <h1 className={css.DashboardTitle}>
        {isAdminPage ? t('admin_dashboard') : t('dashboard')}
      </h1>

      <div className={css.cardWrapper}>
        {isAdminPage ? (
          <>
            <Link to="/admin/registrerte-leverandører" className={css.card}>
              <h4 className={css.cardTitle}>{t('registrerte_virksomheter')}</h4>
              <ArrowRight />
            </Link>
            <Link to="/admin/registrerte-leverandører" className={css.card}>
              <h4 className={css.cardTitle}>{t('registrerte_leverandorer')}</h4>
              <ArrowRight />
            </Link>
            <Link to="#" className={css.card_disabled}>
              <h4 className={css.card_disabled_title}>
                {t('godkjenn_nye_leverandorer')}
              </h4>
              <ArrowRight />
            </Link>
            <Link to="#" className={css.card_disabled}>
              <h4 className={css.card_disabled_title}>
                {t('godkjenn_endringer_fra_leverandorer')}
              </h4>
              <ArrowRight className={css.lastSVG} />
            </Link>
          </>
        ) : (
          <>
            <Link to="/min-side" className={css.card}>
              <h4 className={css.cardTitle}>{t('min_side_tittel')}</h4>
              <ArrowRight />
            </Link>
            <Link to="/leverandøroversikt" className={css.card}>
              <h4 className={css.cardTitle}>{t('Leverandøroversikt')}</h4>
              <ArrowRight />
            </Link>
            {/* <Link to="/sok?childrenOf=47" className={css.card}>
              <h4 className={css.cardTitle}>{t('tjeneste_oversikt')}</h4>
              <ArrowRight />
            </Link> */}
          </>
        )}
      </div>
    </div>
  );
}
