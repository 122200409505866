import React, { ReactElement } from 'react';
import classNames from 'classnames';

import css from './Tag.module.scss';

type TagVariant =
  | 'success'
  | 'neutral'
  | 'warning'
  | 'error'
  | 'primary'
  | 'primaryLight'
  | 'transparent';

export interface TagProps {
  variant?: TagVariant;
  className?: string;
  text?: string;
}

export default function Tag({
  variant = 'neutral',
  className,
  text = ''
}: TagProps): ReactElement {
  return (
    <span
      className={classNames([css.Tag, className])}
      data-tag-variant={variant}
    >
      {text}
    </span>
  );
}
