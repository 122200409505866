import React from 'react';

import css from './LinkSection.module.scss';
import Icon from '../Icon/Icon';

export interface LinkSectionProps {
  title: string;
  url: string;
}

export default function LinkSection(props: LinkSectionProps) {
  return (
    <section className={css.LinkContainer}>
      <h2>{props.title}</h2>
      <a href={props.url} className={css.Link}>
        <span className={css.LinkText}>
          {props.url.replace(/https?:\/\//, '')}
        </span>
        <Icon symbol={'enter-right'} tabIndex={-1} />
      </a>
    </section>
  );
}
