import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import Spinner from 'Components/Spinner/Spinner';
import { useTjeneste } from 'shared/hooks/tjeneste-hooks';
import { NotFound } from 'Components/NotFound';
import { TjenesteViewer } from 'Components/TjenesteViewer';

export default function TjenesteSide(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const { data: tjeneste, isLoading, isError } = useTjeneste(id);

  if (isError) {
    return <NotFound />;
  }

  if (isLoading || !tjeneste) {
    return <Spinner isActive={isLoading} />;
  }

  return <TjenesteViewer tjeneste={tjeneste} />;
}
